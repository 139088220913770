import React from "react";
import { format, parseISO } from "date-fns";
import { numberFormat } from "features/functions";

export default class BlockedProductsItem extends React.Component {
	handleClick = () => {
		const {item} = this.props;
		this.props.onClick(item);
	}
	render = () => {
		const { item, index } = this.props;
		return (
			<tr onClick={this.handleClick}>
				<td>{index + 1}</td>
				<td>{item.Codigo}</td>
				<td>{item.Marca}</td>
				<td>{item.Producto}</td>
				<td>{item.Medida}</td>
				<td>{item.Almacen}</td>
				<td>{item.Inventario > 0 ? numberFormat(item.Inventario) : "-"}</td>
				<td>{format(parseISO(item.Fecha), "dd/MMM/yyyy hh:mm aaaa")}</td>
				<td className="left">{item.Usuario}</td>
				<td className="left">{item.Motivo}</td>
			</tr>
		);
	};
}
