import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addMonths } from "date-fns";
import {
	getContactMessage,
	getContactMessages,
	updateContactMessage,
} from "services/messagesService";

const initialState = {
	page: 0,
	rows: 50,
	status: 0,
	from: addMonths(new Date(), -3),
	until: new Date(),
	category: 0,
	type: 0,
	managerId: null,
	isLoading: false,
	isOpening: false,
	isEditing: false,
	isSaving: false,
	list: null,
	total: 0,
	message: null,
	errorMsg: null,
};

export const fetchContactMessages = createAsyncThunk(
	"fetchContactMessages",
	async (_, { getState }) => {
		const state = getState();
		const { page, rows, status, from, until, category, type, managerId } =
			state.contactMessages;
		const body = {
			page,
			rows,
			status,
			category,
			type,
			from: from.toISOString(),
			until: until.toISOString(),
			managerId,
		};
		const response = await getContactMessages(body);
		return response;
	}
);

export const fetchContactMessage = createAsyncThunk(
	"fetchContactMessage",
	async (id) => {
		const response = await getContactMessage(id);
		return response;
	}
);

export const fetchUpdateContactMessage = createAsyncThunk(
	"fetchUpdateContactMessage",
	async (data, { rejectWithValue, dispatch }) => {
		const { id } = data;
		try {
			const response = await updateContactMessage(id, data);
			dispatch(fetchContactMessage(id));
			dispatch(fetchContactMessages());
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

const contactMessages = createSlice({
	name: "contactMessages",
	initialState,
	reducers: {
		setContactMessagesPage(state, { payload }) {
			state.page = payload;
		},
		setContactMessagesRows(state, action) {
			state.rows = action.payload.rows;
		},
		setContactMessagesStatus(state, { payload }) {
			state.status = payload ?? null;
			state.page = 0;
		},
		setContactMessagesFrom(state, { payload }) {
			state.from = payload ?? null;
			state.page = 0;
		},
		setContactMessagesUntil(state, { payload }) {
			state.until = payload ?? null;
			state.page = 0;
		},
		setContactMessagesType(state, { payload }) {
			state.type = payload.type;
			state.page = 0;
		},
		setContactMessagesCategory(state, { payload }) {
			state.category = payload.category;
			state.page = 0;
		},
		setContactMessagesManagerId(state, { payload }) {
			state.managerId = payload.managerId;
			state.page = 0;
		},
		setContactMessagesMessage(state, { payload }) {
			state.message = payload.message ?? null;
			state.isEditing = payload.message != null;
		},
		setContactMessagesFilters(state, { payload }) {
			state.page = payload.page ?? 0;
			state.rows = payload.rows ?? 50;
			state.status = payload.status ?? 0;
			state.from = payload.from ?? null;
			state.until = payload.until ?? null;
			state.category = payload.category ?? 0;
			state.type = payload.type ?? 0;
			state.managerId = payload.managerId ?? null;
		},
		setContactMessagesEditMessage(state, { payload }) {
			state.isEditing = payload.isEditing ?? false;
		},
		setContactMessageErrorMsg(state, { payload }) {
			state.errorMsg = payload.errorMsg;
		},
		clearContactMessages: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// Contact Message List
			.addCase(fetchContactMessages.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchContactMessages.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.messages ?? [];
				state.total = payload.data.total ?? 0;
			})
			.addCase(fetchContactMessages.rejected, (state) => {
				state.isLoading = false;
				state.list = [];
			})
			// Contact Message
			.addCase(fetchContactMessage.pending, (state) => {
				state.isOpening = true;
				state.message = null;
			})
			.addCase(fetchContactMessage.fulfilled, (state, { payload }) => {
				state.isOpening = false;
				state.message = payload.data ?? null;
			})
			.addCase(fetchContactMessage.rejected, (state) => {
				state.isOpening = false;
			})
			// Update Contact Message
			.addCase(fetchUpdateContactMessage.pending, (state) => {
				state.isSaving = true;
				state.errorMsg = null;
			})
			.addCase(fetchUpdateContactMessage.fulfilled, (state) => {
				state.isSaving = false;
				state.isEditing = false;
			})
			.addCase(fetchUpdateContactMessage.rejected, (state, { payload }) => {
				state.isSaving = false;
				state.errorMsg = {
					error: payload.data ?? "Error al actualizar el mensaje.",
				};
			});
	},
});

export const {
	setContactMessagesPage,
	setContactMessagesRows,
	setContactMessagesStatus,
	setContactMessagesFrom,
	setContactMessagesUntil,
	setContactMessagesType,
	setContactMessagesCategory,
	setContactMessagesManagerId,
	setContactMessagesMessage,
	setContactMessagesFilters,
	setContactMessagesEditMessage,
	setContactMessageErrorMsg,
	clearContactMessages,
} = contactMessages.actions;

export default contactMessages.reducer;
