import React from "react";
import { MAIN_TABS } from "features/constants";
import { numberFormat } from "features/functions";
import ContiPartnerItem from "./ContiPartnerItem";
import Loader from "components/loader/Loader";
import ContiPartnerCouponItem from "./ContiPartnerCouponItem";

export default class ContiPartners extends React.Component {
	handleClear = () => {
		this.props.onClear();
	}
	handleAddClick = () => {
		this.props.onAddPartner();
	}
	handleAddCouponClick = () => {
		this.props.onAddCoupon();
	}
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderPartnersTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>#</th>
					<th>
						Alias<span className="icon-sort-inverse"></span>
					</th>
					<th>Nombre</th>
					<th>Incremento</th>
					<th>Última Modificación</th>
					<th>Estatus</th>
					<th>Creado por</th>
					<th>Fecha</th>
					<th>Propietario</th>
				</tr>
			</thead>
		);
	};
	renderPartnersList = () => {
		const { isLoading, list } = this.props;
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderPartnersTableHeaders()}
						<tbody>
							{list.map((el, i) => {
								return (
									<ContiPartnerItem
										key={i}
										index={i}
										item={el}
										onClick={this.props.onClick}
									/>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="9">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderPartnersTableHeaders()}
					<tbody>
						<tr>
							<td colSpan="9">No hay ContiPartners.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="9">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	renderCouponTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>#</th>
					<th>
						Cupón<span className="icon-sort-inverse"></span>
					</th>
					<th>Capacidad</th>
					<th>Período</th>
					<th>Última Modificación</th>
					<th>Estatus</th>
					<th>Creado por</th>
					<th>Fecha</th>
				</tr>
			</thead>
		);
	};
	renderCouponList = () => {
		const { isLoading, couponList } = this.props;
		if (!isLoading && couponList != null) {
			if (couponList.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderCouponTableHeaders()}
						<tbody>
							{couponList.map((el, i) => {
								return (
									<ContiPartnerCouponItem
										key={i}
										index={i}
										item={el}
										onClick={this.props.onChangeCoupon}
									/>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="8">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderCouponTableHeaders()}
					<tbody>
						<tr>
							<td colSpan="8">Aún no hay cupones para este ContiPartner.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="8">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	renderPartners = () => {
		const { list } = this.props;
		return (
			<section className="sales">
				<div>
					<h1>ContiPartners</h1>
				</div>
				<div className="order-filters">
					<div className="controls">
						<div>
							<div>A continuiación se muestra la lista de Partners registrados.</div>
							<div style={{ verticalAlign: "bottom" }}>
								<div className="button main" onClick={this.handleAddClick}>
									Nuevo Partner
								</div>
							</div>
						</div>
					</div>
				</div>{" "}
				<div className="orders-table">
					<div className="orders-table-title">
						<div>
							<span>{numberFormat(list.length, 0)}</span>
							{list.length === 1 ? " Partner" : " Partners"}
						</div>
					</div>
					<div className="orders-table-content">
						{this.renderPartnersList()}
					</div>
				</div>
			</section>
		);
	};
	renderSelected = () => {
		const { selectedItem, couponList } = this.props;
		return (
			<section className="sales">
				<div>
					<h1>ContiPartners</h1>
				</div>
				<div className="order-filters">
					<div className="controls">
						<div>
							<div>
								<strong>{selectedItem.Alias}</strong><br />
								{selectedItem.Nombre}<br />
							</div>
							<div style={{ verticalAlign: "bottom" }}>
								<div className="button" onClick={this.handleClear}>
									Regresar
								</div>
								<div className="button main" onClick={this.handleAddCouponClick}>
									Nuevo Cupón
								</div>
							</div>
						</div>
					</div>
				</div>{" "}
				<div className="orders-table">
					<div className="orders-table-title">
						<div>
							<span>{numberFormat(couponList.length, 0)}</span>
							{couponList.length === 1 ? " Cupón" : " Cupones"}
						</div>
					</div>
					<div className="orders-table-content">{this.renderCouponList()}</div>
				</div>
			</section>
		);
	};
	render = () => {
		const { isLoading, selectedItem, activeTabItem } = this.props;
		if (isLoading) {
			return <div>{this.renderLoader()}</div>;
		}
		if (
			activeTabItem != null &&
			activeTabItem.tab === MAIN_TABS.ContiPartners
		) {
			if (selectedItem === null) {
				return this.renderPartners();
			}
			return this.renderSelected();
		}
		return null;
	};
}
