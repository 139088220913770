import Loader from "components/loader/Loader";
import { format, parseISO } from "date-fns";
import es from "date-fns/locale/es";
import {
	CONTACT_MESSAGE_CATEGORY as cmc,
	CONTACT_MESSAGE_STATUS as cms,
	CONTACT_MESSAGE_TYPE as cmt,
} from "features/constants";
import React from "react";
import ContactMessageForm from "../messages/ContactMessageForm";

export default class SupportMessageDetails extends React.Component {
	handleClose = () => {
		const { onClose } = this.props;
		onClose();
	};
	handleEdit = () => {
		const { onEdit } = this.props;
		onEdit(true);
	};
	handleCancelEdit = () => {
		const { onEdit } = this.props;
		onEdit(false);
	};
	renderActionHeaders = () => {
		return (
			<thead>
				<tr>
					<th>
						Fecha<span className="icon-sort"></span>
					</th>
					<th>Acción</th>
					<th>Atendido por</th>
					<th>Categoría</th>
					<th>Estatus</th>
				</tr>
			</thead>
		);
	};
	renderActions = () => {
		const { message, isLoading } = this.props;
		if (!isLoading && message != null && message.actions.length > 0) {
			return (
				<div className="orders-table">
					<div>
						<h2>Acciones</h2>
					</div>
					<div className="orders-table-content">
						<table cellPadding="0" cellSpacing="0" border="0">
							{this.renderActionHeaders()}
							<tbody>
								{message.actions.map((el, i) => {
									return (
										<tr key={i}>
											<td>
												{format(
													parseISO(el.createdAt),
													"dd/MMM/yyyy hh:mm aaaa"
												)}
											</td>
											<td
												style={{
													whiteSpace: "normal",
													textAlign: "left",
													maxWidth: "500px",
												}}
											>
												{el.action}
											</td>
											<td>{el.createdBy}</td>
											<td>{cmc.properties[el.category].name}</td>
											<td>{cms.properties[el.status].name}</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="5">&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
		return null;
	};

	render() {
		const {
			message,
			isLoading,
			isEditing,
			isSaving,
			onSave,
			onSetErrorMsg,
			errorMsg,
		} = this.props;
		if (isLoading) {
			return (
				<div>
					<Loader msg={"..."} />
				</div>
			);
		}
		return (
			<div className="order">
				<div className="order-actions">
					<div className="button" onClick={this.handleClose}>
						Regresar
					</div>
					{(message.status === cms.Pending ||
						message.status === cms.WaitingReply) && (
						<div className="button main" onClick={this.handleEdit}>
							Atender
						</div>
					)}
				</div>
				<div className="order-info">
					<div className="order-number">
						<div>DETALLES DEL MENSAJE</div>
						<div>&nbsp;</div>
					</div>
					<div className="order-data">
						<div>
							<div>Fecha y Hora</div>
							<div>
								{format(parseISO(message.date), "dd/MMM/yyyy hh:mm aaaa", {
									locale: es,
								})}
							</div>
							<div>Estatus</div>
							<div>{cms.properties[message.status].name}</div>
						</div>
						<div>
							<div>Nombre</div>
							<div>{message.name}</div>
							<div>Categoría</div>
							<div>{cmc.properties[message.category].name}</div>
						</div>
						<div>
							<div>Correo</div>
							<div>{message.email}</div>
							{message.closeDate != null ? (
								<>
									<div>Cerrado el</div>
									<div>
										{format(
											parseISO(message.closeDate),
											"dd/MMM/yyyy hh:mm aaaa",
											{
												locale: es,
											}
										)}
									</div>
								</>
							) : null}
						</div>
						<div>
							<div>Tipo</div>
							<div>{cmt.properties[message.type].name}</div>
							{message.closedBy !== "" ? (
								<>
									<div>Cerrado por</div>
									<div>{message.closedBy}</div>
								</>
							) : null}
						</div>
					</div>
				</div>
				<div>
					<div>
						<h2>Mensaje</h2>
					</div>
					<div>{message.message}</div>
				</div>
				{this.renderActions()}
				{isEditing && (
					<ContactMessageForm
						message={message}
						onCancel={this.handleCancelEdit}
						onSave={onSave}
						onSetErrorMsg={onSetErrorMsg}
						errorMsg={errorMsg}
						isSaving={isSaving}
					/>
				)}
			</div>
		);
	}
}
