import React from "react";
import { format, parseISO } from "date-fns";
import { currencyFormat, numberFormat } from "features/functions";

export default class SearchProductsItem extends React.Component {
	handleBlock = () => {
		const {item} = this.props;
		this.props.onBlock(item);
	}
	handleAddStockSap = () => {
		const {item} = this.props;
		this.props.onAddStock({...item, Almacen: 1});
	}
	handleAddStockGdl = () => {
		const {item} = this.props;
		this.props.onAddStock({...item, Almacen: 3});
	}
	renderSapStatus = () => {
		const {item} = this.props;
		if (item.Bloqueado) {
			return <td className="red">Bloqueado</td>
		}
		if (item.Activo) {
			return <td>Activo</td>
		}
		return <td className="disabled">Inactivo</td>
	}
	renderGdlStatus = () => {
		const {item} = this.props;
		if (item.BloqueadoEmpleados) {
			return <td className="red">Bloqueado</td>
		}
		if (item.ActivoEmpleados) {
			return <td>Activo</td>
		}
		return <td className="disabled">Inactivo</td>
	}
	render = () => {
		const { item, index } = this.props;
		if (item.Mkt == null) {
		return (
			<tr>
				<td>{index + 1}</td>
				<td>{item.Codigo}</td>
				<td colSpan="13" className="left">Código no encontrado.</td>
			</tr>
		);
		}
		const sapAction = item.Sap > 0 ? this.handleBlock : this.handleAddStockSap;
		const gdlAction = item.Empleados > 0 ? this.handleBlock : this.handleAddStockGdl;
		return (
			<tr>
				<td>{index + 1}</td>
				<td onClick={this.handleBlock}>{item.Codigo}</td>
				<td onClick={this.handleBlock}>{item.Marca}</td>
				<td onClick={this.handleBlock}>{item.Mkt}</td>
				<td onClick={this.handleBlock}>{item.Producto}</td>
				<td onClick={this.handleBlock}>{item.Medida}</td>
				<td className="right">{item.Precio > 0 ? currencyFormat(item.Precio) : "-"}</td>
				<td className="right">{item.PrecioEmpleados > 0 ? currencyFormat(item.PrecioEmpleados): "-"}</td>
				<td onClick={sapAction}>{item.Sap > 0 ? numberFormat(item.Sap): "-"}</td>
				{this.renderSapStatus()}
				<td onClick={gdlAction}>{item.Empleados > 0 ? numberFormat(item.Empleados): "-"}</td>
				{this.renderGdlStatus()}
				<td>{format(parseISO(item.Alta), "dd/MMM/yyyy")}</td>
				<td>{format(parseISO(item.AltaMkt), "dd/MMM/yyyy")}</td>
			</tr>
		);
	};
}
