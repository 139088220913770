import React from "react";
import Loader from "../../loader/Loader";
import SearchProductsItem from "./SearchProductsItem";
import { MAIN_TABS } from "features/constants";

export default class BlockedProducts extends React.Component {
	constructor(props) {
		super(props);
		this.state = { products: "" };
	}
	handleProductChange = (e) => {
		const value = e.target.value.replace(/[^0-9,]/g, "");
		this.setState({ products: value });
	};
	handleProductSearch = () => {
		this.props.onSearch(this.state.products);
	};
	handleProductKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleProductSearch();
		}
	};
	handleClear = () => {
		this.props.onClear();
		this.setState({ products: "" });
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>#</th>
					<th>
						Código<span className="icon-sort-inverse"></span>
					</th>
					<th>Marca</th>
					<th>Mkt</th>
					<th>Producto</th>
					<th>Medida</th>
					<th>Precio</th>
					<th>Precio Empleados</th>
					<th>MXTX</th>
					<th>Estatus</th>
					<th>MXCO</th>
					<th>Estatus MXCO</th>
					<th>Fecha Alta</th>
					<th>Alta Mkt</th>
				</tr>
			</thead>
		);
	};
	renderProducts = () => {
		const { isLoading, list } = this.props;
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderTableHeaders()}
						<tbody>
							{list.map((el, i) => {
								return (
									<SearchProductsItem
										key={i}
										index={i}
										item={el}
										onBlock={this.props.onBlock}
										onAddStock={this.props.onAddStock}
										source="search"
									/>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="14">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderTableHeaders()}
					<tbody>
						<tr>
							<td colSpan="14">No hay datos que mostrar.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="14">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	render = () => {
		const { isLoading, activeTabItem } = this.props;
		if (isLoading) {
			return <div>{this.renderLoader()}</div>;
		}
		if (
			activeTabItem != null &&
			activeTabItem.tab === MAIN_TABS.ProductSearch
		) {
			return (
				<section className="sales">
					<div>
						<h1>Productos</h1>
					</div>
					<div className="order-filters">
						<div className="controls">
							<div>
								<div className="left">
									<label>Códigos a buscar: </label>
									<input
										type="text"
										placeholder="Códigos separados por coma"
										style={{ minWidth: "100px", textAlign: "left" }}
										value={this.state.products}
										onChange={this.handleProductChange}
										onKeyPress={this.handleProductKeyPress}
									></input>
								</div>
								<div style={{ verticalAlign: "bottom" }}>
									<div className="button" onClick={this.handleClear}>
										Limpiar
									</div>
									<div
										className="button main"
										onClick={this.handleProductSearch}
									>
										Buscar
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="orders-table">
						<div className="orders-table-title">
							<div>Resultados:</div>
						</div>
						<div className="orders-table-content">{this.renderProducts()}</div>
					</div>
				</section>
			);
		}
		return null;
	};
}
