import React from "react";
import Header from "./header/HeaderContainer";
import TabsBar from "./tabs/TabsBarContainer";
import Footer from "./footer/Footer";
import BlockedProducts from "./products/BlockedProductsContainer";
import BlockedProductModal from "./products/BlockedProductModalContainer";
import CreditOrders from "./credit/CreditOrdersContainer";
import DealerOrders from "./dealer/DealerOrdersContainer";
import DealerDelivery from "./dealer/DealerDeliveryContainer";
import DealerLocationOrders from "./dealer/DealerLocationOrdersContainer";
import HrOrders from "./hr/HrOrdersContainer";
import HrDelivery from "./hr/HrDeliveryContainer";
import Inventory from "./inventory/InventoryContainer";
import SalesOrders from "./sales/SalesOrdersContainer";
import SalesOrder from "./sales/SalesOrderContainer";
import SearchProducts from "./products/SearchProductsContainer";
import Software from "./software/SoftwareContainer";
import AddBlockedProductModal from "./products/AddBlockedProductModalContainer";
import StockAddModal from "./products/StockAddModalContainer";
import ContiPartners from "./contipartners/ContiPartnersContainer";
import AddPartnerCouponModal from "./contipartners/AddPartnerCouponContainer";
import AddPartnerModal from "./contipartners/AddPartnerModalContainer";
import SupportOrders from "./support/SupportOrdersContainer";
import ContactMessages from "./messages/ContactMessagesContainer";
import SupportMessages from "./support/SupportMessagesContainer";
import Users from "./users/UsersContainer";

export default class Manager extends React.Component {
	render = () => {
		const { authenticated } = this.props;
		if (authenticated) {
			return (
				<div>
					<Header />
					<TabsBar />
					<div id="content">
						<CreditOrders />
						<DealerOrders />
						<DealerDelivery />
						<DealerLocationOrders />
						<HrOrders />
						<HrDelivery />
						<SalesOrders />
						<SalesOrder />
						<Users />
						<ContactMessages />
						<SupportOrders />
						<SupportMessages />
						<Inventory />
						<SearchProducts />
						<BlockedProducts />
						<ContiPartners />
						<Software />
					</div>
					<Footer />
					<BlockedProductModal />
					<AddBlockedProductModal />
					<StockAddModal />
					<AddPartnerModal />
					<AddPartnerCouponModal />
				</div>
			);
		}
		return null;
	};
}
