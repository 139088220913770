import { connect } from "react-redux";
import {
	setBlockedProductAdd,
	toggleBlockedProductModalAdd,
} from "features/products/blockedProductsSlice";
import {
	setStockAddSelectedItem,
	toggleStockAddModal,
} from "features/products/stockAdd";
import Inventory from "./Inventory";

const mapStateToProps = (state) => {
	const { isLoading, list } = state.inventory;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onBlock: (item) => {
			dispatch(
				setBlockedProductAdd({ selectedAdd: item, source: "inventory" })
			);
			dispatch(toggleBlockedProductModalAdd({ isOpenAdd: false }));
		},
		onAddStock: (item) => {
			dispatch(
				setStockAddSelectedItem({ selectedItem: item, source: "inventory" })
			);
			dispatch(toggleStockAddModal({ isOpen: false }));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
