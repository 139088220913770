import React from "react";
import { MAIN_TABS } from "features/constants";

export default class SupportOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = { orderField: "" };
	}
	handleOrderChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null) {
			this.props.onClearError();
		}
		const value = e.target.value.replace(/[^0-9]/g, "");
		this.setState({ orderField: value });
	};
	handleOrderSearch = () => {
		this.props.onOpenOrder(this.state.orderField);
		this.setState({ orderField: "" });
	};
	handleOrderKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleOrderSearch();
		}
	};
	renderError = () => {
		const { errorMsg } = this.props;
		if (errorMsg != null) {
			return <div>{errorMsg}</div>;
		}
		return null;
	};
	renderSearch = () => {
		return (
			<div className="order-filters">
				<div className="controls">
					<div>
						<div>{this.renderError()}</div>
						<div>
							<div className="left">
								<label>Buscar pedido: </label>
								<input
									type="text"
									placeholder="Número"
									style={{ minWidth: "100px", textAlign: "left" }}
									value={this.state.orderField}
									onChange={this.handleOrderChange}
									onKeyPress={this.handleOrderKeyPress}
								></input>
							</div>
							<div>
								<div className="button main" onClick={this.handleOrderSearch}>
									Buscar
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	render = () => {
		const { activeTabItem } = this.props;
		if (
			activeTabItem != null &&
			activeTabItem.tab === MAIN_TABS.ContactCenter
		) {
			return (
				<section className="sales">
					<div>
						<h1>Buscador de Pedidos</h1>
					</div>
					{this.renderSearch()}
				</section>
			);
		}
		return null;
	};
}
