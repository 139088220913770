import React from "react";
import { format, parseISO } from "date-fns";

export default class SoftwareItem extends React.Component {
	render = () => {
		const { item, index } = this.props;
		return (
			<tr>
				<td>{index + 1}</td>
				<td>{item.Sitio}</td>
				<td>{item.Version}</td>
				<td>{format(parseISO(item.Fecha), "dd/MMM/yyyy hh:mm aaaa")}</td>
				<td>{item.Usuario}</td>
			</tr>
		);
	};
}
