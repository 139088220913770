import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { subDays, parseISO, format } from "date-fns";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { fetchAddOrderTab } from "features/tabs/tabsSlice";

const salesOrders = createSlice({
	name: "salesOrders",
	initialState: {
		page: 0,
		rows: 50,
		orderStatus: 0,
		valid: 0,
		fromDate: subDays(new Date(), 6).toISOString(),
		untilDate: new Date().toISOString(),
		payment: 0,
		ship: 0,
		userType: 0,
		employee: null,
		locationId: null,
		email: null,
		stateId: null,
		dealerLocationId: 0,
		contiPartnerId: 0,
		isLoading: false,
		isLoadingOrder: false,
		list: null,
		order: null,
	},
	reducers: {
		setPage(state, action) {
			state.page = action.payload.page;
		},
		setRows(state, action) {
			state.rows = action.payload.rows;
		},
		setOrderStatus(state, action) {
			state.orderStatus = action.payload.orderStatus;
			if (action.payload.orderStatus !== 0) state.valid = 0;
			state.page = 0;
		},
		setValid(state, action) {
			state.valid = action.payload.valid;
			if (action.payload.valid !== 0) state.orderStatus = 0;
			state.page = 0;
		},
		setFromDate(state, action) {
			state.fromDate = action.payload.fromDate;
			state.page = 0;
		},
		setUntilDate(state, action) {
			state.untilDate = action.payload.untilDate;
			state.page = 0;
		},
		setPayment(state, action) {
			state.payment = action.payload.payment;
			state.page = 0;
		},
		setShip(state, action) {
			state.ship = action.payload.ship;
			state.page = 0;
		},
		setUserType(state, action) {
			state.userType = action.payload.userType;
			if (action.payload.userType === 1) {
				state.employee = null;
				state.locationId = null;
			}
			state.page = 0;
		},
		setEmployee(state, action) {
			state.employee = action.payload.employee;
			if (action.payload.employee !== null) state.userType = 0;
			state.page = 0;
		},
		setLocationId(state, action) {
			state.locationId = action.payload.locationId;
			if (action.payload.locationId !== null) state.userType = 0;
			state.page = 0;
		},
		setContiPartnerId(state, action) {
			state.contiPartnerId = action.payload.contiPartnerId;
			if (action.payload.contiPartnerId !== null) state.userType = 0;
			state.page = 0;
		},
		setEmail(state, action) {
			state.email = action.payload.email;
			state.page = 0;
		},
		setStateId(state, action) {
			state.stateId = action.payload.stateId;
		},
		setDealerLocationId(state, action) {
			state.dealerLocationId = action.payload.dealerLocationId;
		},
		requestSalesOrders(state) {
			state.isLoading = true;
		},
		receiveSalesOrders(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		requestSalesOrder(state) {
			state.isLoadingOrder = true;
		},
		receiveSalesOrder(state, action) {
			state.isLoadingOrder = false;
			state.order = action.payload.order;
		},
		clearSalesOrdersFilters(state) {
			state.page = 0;
			state.rows = 50;
			state.orderStatus = 0;
			state.valid = 0;
			state.fromDate = subDays(new Date(), 6).toISOString();
			state.untilDate = new Date().toISOString();
			state.payment = 0;
			state.ship = 0;
			state.userType = 0;
			state.employee = null;
			state.locationId = null;
			state.email = null;
			state.stateId = null;
			state.dealerLocationId = 0;
			state.contiPartnerId = 0;
		},
		clearSalesOrders(state) {
			state.page = 0;
			state.rows = 50;
			state.orderStatus = 0;
			state.valid = 0;
			state.fromDate = subDays(new Date(), 6).toISOString();
			state.untilDate = new Date().toISOString();
			state.payment = 0;
			state.ship = 0;
			state.userType = 0;
			state.employee = null;
			state.locationId = null;
			state.email = null;
			state.stateId = null;
			state.dealerLocationId = 0;
			state.contiPartnerId = 0;
			state.isLoading = false;
			state.isLoadingOrder = false;
			state.list = null;
			state.order = null;
		},
	},
});

export const {
	setPage,
	setRows,
	setOrderStatus,
	setValid,
	setFromDate,
	setUntilDate,
	setPayment,
	setShip,
	setUserType,
	setEmployee,
	setLocationId,
	setEmail,
	setStateId,
	setDealerLocationId,
	setContiPartnerId,
	requestSalesOrders,
	receiveSalesOrders,
	requestSalesOrder,
	receiveSalesOrder,
	clearSalesOrdersFilters,
	clearSalesOrders,
} = salesOrders.actions;

export const fetchSalesOrders = () => async (dispatch, getState) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const state = getState();
		const s = state.salesOrders;
		const body = JSON.stringify({
			Pagina: s.page,
			Filas: s.rows,
			Estatus: s.orderStatus,
			Desde: format(parseISO(s.fromDate), "yyyy-MM-dd"),
			Hasta: format(parseISO(s.untilDate), "yyyy-MM-dd"),
			MetodoPago: s.payment,
			MetodoEnvio: s.ship,
			Validos: s.valid,
			TipoUsuario: s.userType,
			NumEmpleado: s.employee,
			IdLocalidad: s.locationId,
			Email: s.email,
			Estado: s.stateId,
			IdSucursal: s.dealerLocationId,
			IdContiPartner: s.contiPartnerId,
		});
		const options = {
			method: "POST",
			headers: headers,
			body: body,
		};
		dispatch(requestSalesOrders());
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/Pedidos", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				dispatch(receiveSalesOrders({ list: json }));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export const fetchSalesOrder = (order, search = false) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "GET",
			headers: headers,
		};
		dispatch(requestSalesOrder());
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/Pedidos/" + order, options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				dispatch(receiveSalesOrder({ order: json }));
				if (search) {
					dispatch(fetchAddOrderTab(json.Pedido));
				}
			})
			.catch((error) => {
				dispatch(receiveSalesOrder({ order: null }));
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};

export default salesOrders.reducer;
