import React from "react";
import { format, parseISO } from "date-fns";

export default class DealerOrdersItem extends React.Component {
	render = () => {
		const { item } = this.props;
		return (
			<tr>
				<td>{item.Pedido}</td>
				<td>
					{format(parseISO(item.Fecha),"dd/MMM/yyyy hh:mm aaaa")}
				</td>
				<td>{item.Tipo}</td>
				<td>{item.Estatus}</td>
				<td>{item.MetodoEnvio}</td>
				<td>{item.Sucursal}</td>
				<td>{item.Correo}</td>
				<td>{item.Nombre}</td>
				<td>{item.Ciudad}</td>
				<td>{item.Estado}</td>
			</tr>
		);
	};
}
