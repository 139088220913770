import React from "react";
import { currencyFormat, numberFormat } from "../../../features/functions";

export default class InventoryItem extends React.Component {
	handleBlock = () => {
		const {item} = this.props;
		this.props.onBlock(item);
	}
	handleAddStockSap = () => {
		const {item} = this.props;
		this.props.onAddStock({...item, Almacen: 1});
	}
	handleAddStockGdl = () => {
		const {item} = this.props;
		this.props.onAddStock({...item, Almacen: 3});
	}
	renderSapStatus = () => {
		const {item} = this.props;
		if (item.Blocked) {
			return <td className="red">Bloqueado</td>
		}
		if (item.Active) {
			return <td>Activo</td>
		}
		return <td className="disabled">-</td>
	}
	renderGdlStatus = () => {
		const {item} = this.props;
		if (item.EmployeeBlocked) {
			return <td className="red">Bloqueado</td>
		}
		if (item.EmployeeActive) {
			return <td>Activo</td>
		}
		return <td className="disabled">-</td>
	}
	render = () => {
		const { item, index } = this.props;
		const sapAction = item.Mxtx > 0 ? this.handleBlock : this.handleAddStockSap;
		const gdlAction = item.Mxco > 0 ? this.handleBlock : this.handleAddStockGdl;
		return (
			<tr>
				<td>{index + 1}</td>
				<td onClick={this.handleBlock}>{item.Brand}</td>
				<td onClick={this.handleBlock}>{item.Mkt}</td>
				<td onClick={this.handleBlock}>{item.Product}</td>
				<td onClick={this.handleBlock}>{item.Code}</td>
				<td onClick={this.handleBlock}>{item.Size}</td>
				<td onClick={this.handleBlock}>{item.Type}</td>
				<td className="right">{item.Price > 0 ? currencyFormat(item.Price) : "-"}</td>
				<td className="right">{item.EmployeePrice > 0 ? currencyFormat(item.EmployeePrice) : "-"}</td>
				<td onClick={sapAction} className="right">{item.Mxtx > 0 ? numberFormat(item.Mxtx) : "-"}</td>
				{this.renderSapStatus()}
				<td onClick={gdlAction} className="right">{item.Mxco > 0 ? numberFormat(item.Mxco) : "-"}</td>
				{this.renderGdlStatus()}
				<td>{item.Width}</td>
				<td>{item.Ratio}</td>
				<td>{item.Radial}</td>
				<td>{item.Rim}</td>
				<td>{item.Load}</td>
				<td>{item.Speed}</td>
				<td>{item.Ssr}</td>
			</tr>
		);
	};
}
