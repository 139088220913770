import React from "react";
import ReactDOM from "react-dom";
import { configureStore } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { Provider } from "react-redux";
import { setDefaultLocale } from "react-datepicker";
import Modal from "react-modal";
import thunk from "redux-thunk";
import rootReducer from "./features";

import "./fonts.css";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import App from "./components/AppContainer";
import * as serviceWorker from "./serviceWorker";

import es from "date-fns/locale/es";
setDefaultLocale(es);

const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
	devTools: process.env.NODE_ENV !== "production",
});
sessionService.initSessionService(store);
Modal.setAppElement("#root");

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Provider>,
	document.getElementById("root")
);
serviceWorker.unregister();
