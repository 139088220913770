import React from "react";
import Dropdown from "react-dropdown";
import { RECEIVE_PRODUCT_STATUS } from "../../../features/constants";

const getProducts = (item) => {
	let array = [];
	if (item != null) {
		for (let i = 0; i < item.Detalles.length; i++) {
			array.push(0);
		}
	}
	return array;
};
const getReceiveProductOptions = (item) => {
	const status = RECEIVE_PRODUCT_STATUS;
	const properties = RECEIVE_PRODUCT_STATUS.properties;
	let options = [
		{ value: properties[status.Ok].value, label: properties[status.Ok].name },
		{
			value: properties[status.Wrong].value,
			label: properties[status.Wrong].name,
		},
		{
			value: properties[status.Damaged].value,
			label: properties[status.Damaged].name,
		},
	];
	if (item.Detalles.length > 1) {
		options.push({
			value: properties[status.Missing].value,
			label: properties[status.Missing].name,
		});
	}
	return options;
};
class DealerLocationOrdersItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: getProducts(this.props.item),
		};
		this.checkRef = React.createRef();
	}
	handleCheckChange = (e) => {
		//this.props.onClick(this.props.item.Pedido, e.target.checked);
	};
	handleSelect = (index, selectedValue) => {
		let newSelected = this.state.selected;
		newSelected[index] = selectedValue;
		this.setState({ selected: newSelected });
		this.props.onChange(this.props.item.Pedido, index, selectedValue);
	};
	handleClick = () => {
		const isChecked = !this.checkRef.current.checked;
		this.checkRef.current.checked = isChecked;
		this.props.onClick(this.props.item.Pedido, isChecked);
	};
	renderDropdown = (index, options, el) => {
		const { isChecked } = this.props;
		const dropdownProps =
			el.status != null
				? { selectedValue: el.status, qty: el.Cantidad }
				: { selectedValue: 0, qty: el.Cantidad };
		if (isChecked) {
			return (
				<DealerLocationOrderDropdown
					options={options}
					index={index}
					onChange={this.handleSelect}
					{...dropdownProps}
				/>
			);
		}
		return "";
	};
	render = () => {
		const { item, isChecked } = this.props;
		const cellProps = { onClick: this.handleClick };
		if (item != null) {
			const itemProps = isChecked === true ? { className: "selected" } : {};
			const checkboxName = "order-" + item.Pedido;
			const rows = item.Detalles.length;
			const options = getReceiveProductOptions(this.props.item);
			return (
				<tbody {...itemProps}>
					{item.Detalles.map((el, i) => {
						const dropdownCellProps = isChecked === true ? {} : cellProps;
						if (rows === 1) {
							return (
								<tr key={i}>
									<td {...cellProps}>
										<div className="check-switch">
											<input
												type="checkbox"
												id={checkboxName}
												name={checkboxName}
												value={item.Pedido}
												checked={!!isChecked}
												onChange={this.handleCheckChange}
												ref={this.checkRef}
											/>
											<label htmlFor={checkboxName}>
												<span className="icon-switch"></span>
											</label>
										</div>
									</td>
									<td {...cellProps}>{item.Pedido}</td>
									<td {...cellProps}>{el.Codigo}</td>
									<td {...cellProps}>{el.Cantidad}</td>
									<td {...dropdownCellProps}>
										{this.renderDropdown(i, options, el)}
									</td>
									<td {...cellProps}>{el.Marca + " " + el.Producto}</td>
									<td {...cellProps}>{el.Medida}</td>
									<td {...cellProps}>{item.Sucursal}</td>
									<td {...cellProps}>{item.Tipo}</td>
									<td {...cellProps}>{item.NombreCompleto}</td>
									<td {...cellProps}>{item.Telefono}</td>
									<td {...cellProps}>{item.Correo}</td>
								</tr>
							);
						} else {
							if (i === 0) {
								return (
									<tr key={item.Pedido + "-" + i}>
										<td rowSpan={rows} {...cellProps}>
											<div className="check-switch">
												<input
													type="checkbox"
													id={checkboxName}
													name={checkboxName}
													value={item.Pedido}
													checked={!!isChecked}
													onChange={this.handleCheckChange}
													ref={this.checkRef}
												/>
												<label htmlFor={checkboxName}>
													<span className="icon-switch"></span>
												</label>
											</div>
										</td>
										<td rowSpan={rows} {...cellProps}>
											{item.Pedido}
										</td>
										<td {...cellProps}>{el.Codigo}</td>
										<td {...cellProps}>{el.Cantidad}</td>
										<td {...dropdownCellProps}>
											{this.renderDropdown(i, options, el)}
										</td>
										<td {...cellProps}>{el.Marca + " " + el.Producto}</td>
										<td {...cellProps}>{el.Medida}</td>
										<td rowSpan={rows} {...cellProps}>
											{item.Sucursal}
										</td>
										<td rowSpan={rows} {...cellProps}>
											{item.NombreCompleto}
										</td>
										<td rowSpan={rows} {...cellProps}>
											{item.Telefono}
										</td>
										<td rowSpan={rows} {...cellProps}>
											{item.Correo}
										</td>
									</tr>
								);
							} else {
								return (
									<tr className="sub" key={item.Pedido + "-" + i}>
										<td {...cellProps}>{el.Codigo}</td>
										<td {...cellProps}>{el.Cantidad}</td>
										<td {...dropdownCellProps}>
											{this.renderDropdown(i, options, el)}
										</td>
										<td {...cellProps}>{el.Marca + " " + el.Producto}</td>
										<td {...cellProps}>{el.Medida}</td>
									</tr>
								);
							}
						}
					})}
				</tbody>
			);
		}
		return null;
	};
}
class DealerLocationOrderDropdown extends React.Component {
	handleChange = (option) => {
		const { index } = this.props;
		this.props.onChange(index, option.value);
	};
	render = () => {
		const { options, selectedValue, qty } = this.props;
		const status = RECEIVE_PRODUCT_STATUS;
		const properties = status.properties;
		let newOptions = [...options];
		if (qty > 1) {
			newOptions.push({
				value: properties[status.Incomplete].value,
				label: properties[status.Incomplete].name,
			});
		}
		const defaultOption =
			selectedValue != null
				? newOptions.filter((opc) => opc.value === selectedValue)[0]
				: newOptions.filter((opc) => opc.value === 0)[0];
		return (
			<Dropdown
				options={newOptions}
				value={defaultOption}
				onChange={this.handleChange}
				placeholder={defaultOption.label}
			/>
		);
	};
}
export default DealerLocationOrdersItem;
