import { connect } from "react-redux";
import Header from "./Header";

const mapStateToProps = (state) => {
	const { authenticated, user } = state.session;
	return {
		authenticated,
		user,
	};
};

export default connect(mapStateToProps, null)(Header);
