import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import qs from "qs";
import { BASE_API_URL } from "../constants";
import { fetchAccount } from "../account/accountSlice";
import { clearDealersOrders } from "../dealer/dealerOrdersSlice";
import { clearDealerOrderDelivery } from "features/dealer/dealerOrderDeliverySlice";
import { clearDealerLocationOrders } from "features/dealer/dealerLocationOrdersSlice";
import { clearDealersLocations } from "features/dealer/dealerLocationsSlice";
import { clearHrOrders } from "features/hr/hrOrdersSlice";
import { clearTabs } from "features/tabs/tabsSlice";
import { clearCreditOrders } from "features/credit/creditOrdersSlice";
import { clearSalesOrders } from "features/sales/salesOrdersSlice";
import { clearHrOrderDelivery } from "features/hr/hrOrderDeliverySlice";
import { clearSoftware } from "features/software/softwareSlice";
import { clearBlockedProducts} from "features/products/blockedProductsSlice";
import { clearSearchProducts } from "features/products/searchProductsSlice";
import { clearContiParntersData } from "features/contipartners/contiPartnersSlice";
import { clearPartnerList } from "features/contipartners/contiPartnerListSlice";
import { clearSupportData } from "features/support/supportSlice";
import { clearContactMessages } from "features/messages/contactMessagesSlice";
import { clearUsers } from "features/users/usersSlice";

const loginSlice = createSlice({
	name: "login",
	initialState: {
		isLoading: false,
		errorMsg: null,
		resetForm: false,
	},
	reducers: {
		requestLoginData(state, action) {
			state.isLoading = true;
		},
		receiveLoginData(state, action) {
			state.isLoading = false;
		},
		setErrorMessage(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		setLoginFormReset(state, action) {
			state.resetForm = action.payload.resetForm;
		},
	},
});

export const {
	requestLoginData,
	receiveLoginData,
	setErrorMessage,
	setLoginFormReset,
} = loginSlice.actions;

export const fetchLogin = (user, password) => async (dispatch) => {
	let headers = {
		"Content-Type": "application/x-www-form-urlencoded",
	};
	let options = {
		method: "POST",
		headers: headers,
		body: qs.stringify({
			username: user,
			password: password,
			grant_type: "password",
		}),
	};
	dispatch(requestLoginData());
	return fetch(BASE_API_URL + "/Manager/Token", options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return Promise.reject("El usuario o la contraseña son incorrectos.");
			}
		})
		.then((json) => {
			sessionService
				.saveSession({ loginData: json })
				.then(() => dispatch(fetchAccount()))
				.catch((error) => console.log("error", error));
			dispatch(receiveLoginData());
			dispatch(setLoginFormReset({ resetForm: true }));
		})
		.catch((error) => {
			dispatch(receiveLoginData());
			dispatch(setErrorMessage({ errorMsg: { error: error } }));
		});
};
export const runUnauthorizedActions = () => (dispatch) => {
	sessionService.deleteSession();
	sessionService.deleteUser();
	dispatch(clearTabs());
	dispatch(clearDealersOrders());
	dispatch(clearDealerOrderDelivery());
	dispatch(clearDealerLocationOrders());
	dispatch(clearDealersLocations());
	dispatch(clearHrOrders());
	dispatch(clearHrOrderDelivery());
	dispatch(clearCreditOrders());
	dispatch(clearSalesOrders());
	dispatch(clearSoftware());
	dispatch(clearBlockedProducts());
	dispatch(clearSearchProducts());
	dispatch(clearContiParntersData());
	dispatch(clearPartnerList());
	dispatch(clearSupportData());
	dispatch(clearContactMessages());
	dispatch(clearUsers());
	//dispatch(setAlert({title: 'Aviso', description: 'Su sesión ha caducado, para continuar con su compra debe volver a iniciar sesión.', details: null}))
	//dispatch(toggleShowAlert(theState.alert.isOpen))
};

export default loginSlice.reducer;
