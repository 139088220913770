import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	getSupportMessage,
	getSupportMessages,
	updateSupportMessage,
} from "services/supportServices";

const initialState = {
	page: 0,
	rows: 50,
	isLoading: false,
	isOpening: false,
	isEditing: false,
	isSaving: false,
	list: null,
	total: 0,
	message: null,
	errorMsg: null,
};

export const fetchSupportMessages = createAsyncThunk(
	"fetchSupportMessages",
	async (_, { getState }) => {
		const state = getState();
		const { page, rows } = state.supportMessages;
		const body = {
			page,
			rows,
		};
		const response = await getSupportMessages(body);
		return response;
	}
);

export const fetchSupportMessage = createAsyncThunk(
	"fetchSupportMessage",
	async (id) => {
		const response = await getSupportMessage(id);
		return response;
	}
);

export const fetchUpdateSupportMessage = createAsyncThunk(
	"fetchUpdateSupportMessage",
	async (data, { rejectWithValue, dispatch }) => {
		const { id } = data;
		try {
			const response = await updateSupportMessage(id, data);
			dispatch(fetchSupportMessage(id));
			dispatch(fetchSupportMessages());
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

const supportMessages = createSlice({
	name: "supportMessages",
	initialState,
	reducers: {
		setSupportMessagesMessage: (state, { payload }) => {
			state.message = payload.message ?? null;
			state.isEditing = payload.message != null;
		},
		setSupportMessagesEditMessage: (state, { payload }) => {
			state.isEditing = payload.isEditing ?? false;
		},
		setSupportMessageErrorMsg: (state, { payload }) => {
			state.errorMsg = payload.errorMsg;
		},
		clearSupportMessagesData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// Support Message List
			.addCase(fetchSupportMessages.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchSupportMessages.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.messages ?? [];
				state.total = payload.data.total ?? 0;
			})
			.addCase(fetchSupportMessages.rejected, (state) => {
				state.isLoading = false;
				state.list = [];
			})
			// Support Message
			.addCase(fetchSupportMessage.pending, (state) => {
				state.isOpening = true;
				state.message = null;
			})
			.addCase(fetchSupportMessage.fulfilled, (state, { payload }) => {
				state.isOpening = false;
				state.message = payload.data ?? null;
			})
			.addCase(fetchSupportMessage.rejected, (state) => {
				state.isOpening = false;
			})
			// Update Support Message
			.addCase(fetchUpdateSupportMessage.pending, (state) => {
				state.isSaving = true;
				state.errorMsg = null;
			})
			.addCase(fetchUpdateSupportMessage.fulfilled, (state) => {
				state.isSaving = false;
				state.isEditing = false;
			})
			.addCase(fetchUpdateSupportMessage.rejected, (state, { payload }) => {
				state.isSaving = false;
				state.errorMsg = {
					error: payload.data ?? "Error al actualizar el mensaje.",
				};
			});
	},
});

export const {
	setSupportMessagesMessage,
	setSupportMessagesEditMessage,
	setSupportMessageErrorMsg,
	clearSupportMessagesData,
} = supportMessages.actions;

export default supportMessages.reducer;
