import React from "react";
import Modal from "react-modal";
import Loader from "components/loader/Loader";
import { WAREHOUSE } from "features/constants";
import { numberFormat } from "features/functions";

export default class AddBlockedProductModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { qty: 0 };
	}
	handleClose = () => {
		this.setState({ qty: 0 });
		this.props.onClose();
	};
	handleAddStock = () => {
		const { selectedItem, source } = this.props;
		const { qty } = this.state;
		if (source === "inventory") {
			this.props.onAddStock({
				code: selectedItem.Code,
				warehouse: selectedItem.Almacen,
				qty,
				source,
			});
		} else {
			this.props.onAddStock({
				code: selectedItem.Codigo,
				warehouse: selectedItem.Almacen,
				qty,
				source,
			});
		}
		this.setState({ qty: 0 });
	};
	handleQtyChange = (e) => {
		const qty = Number(e.target.value.replace(/[^0-9]/g, ""));
		this.setState({ qty: qty });
	};
	handleQtyFocus = (e) => {
		e.target.select();
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderQty = () => {
		return (
			<div>
				<div style={{ padding: "5px 0" }}>
					<p>
						Máximo inventario permitido: <strong>8 piezas</strong>
					</p>
					<label>Cantidad: </label>
					<input
						type="text"
						placeholder="Cantidad"
						style={{
							textAlign: "center",
							maxWidth: "60px",
							minWidth: "60px",
							backgroundColor: "orange",
							fontSize: "1.3em",
							fontStyle: "normal",
						}}
						value={this.state.qty}
						onChange={this.handleQtyChange}
						onFocus={this.handleQtyFocus}
					></input>
				</div>
			</div>
		);
	};
	renderSubmit = () => {
		const { qty } = this.state;
		if (qty > 0 && qty <= 8) {
			return (
				<div>
					<div className="button" onClick={this.handleClose}>
						Cancelar
					</div>
					<div className="button main" onClick={this.handleAddStock}>
						Guardar
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className="button" onClick={this.handleClose}>
					Cancelar
				</div>
				<div className="button disabled">Guardar</div>
			</div>
		);
	};
	renderProduct = () => {
		const { selectedItem, source } = this.props;
		if (source === "inventory") {
			return (
				<h3>
					{selectedItem.Brand}
					<br />
					<strong>{selectedItem.Product}</strong>
					<br />
					<strong>
						<span>{selectedItem.Size}</span>
					</strong>
					<br />
					{selectedItem.Code}
				</h3>
			);
		} else {
			return (
				<h3>
					{selectedItem.Marca}
					<br />
					<strong>{selectedItem.Producto}</strong>
					<br />
					<strong>
						<span>{selectedItem.Medida}</span>
					</strong>
					<br />
					{selectedItem.Codigo}
				</h3>
			);
		}
	};
	renderItemInventory = () => {
		const { isLoading, selectedItem, source } = this.props;
		const stock =
			selectedItem.Almacen === WAREHOUSE.MXTX
				? selectedItem.Mxtx
				: selectedItem.Mxco;
		if (!isLoading && selectedItem != null && source === "inventory") {
			return (
				<div>
					{this.renderProduct()}
					<p>
						<strong>Inventarios actuales</strong>
						<br />
						<span>{WAREHOUSE.properties[selectedItem.Almacen].name}</span>
						<br />
						<strong>{stock > 0 ? numberFormat(stock) : "-"}</strong>
					</p>
					<div className="form-a full">{this.renderQty()}</div>
					{this.renderSubmit()}
				</div>
			);
		}
	};
	renderItemSearch = () => {
		const { isLoading, selectedItem, source } = this.props;
		const stock =
			selectedItem.Almacen === WAREHOUSE.MXTX
				? selectedItem.Sap
				: selectedItem.Empleados;
		if (!isLoading && selectedItem != null && source === "search") {
			return (
				<div>
					{this.renderProduct()}
					<p>
						<strong>Inventarios actuales</strong>
						<br />
						<span>{WAREHOUSE.properties[selectedItem.Almacen].name}</span>
						<br />
						<strong>{stock > 0 ? numberFormat(stock) : "-"}</strong>
					</p>
					<div className="form-a full">{this.renderQty()}</div>
					{this.renderSubmit()}
				</div>
			);
		}
	};
	render = () => {
		const customStyles = {
			content: {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)",
				minWidth: "550px",
				minHeight: "350px",
			},
			overlay: {
				backgroundColor: "rgb(0,0,0,.7)",
				zIndex: "2",
			},
		};
		const { isOpen } = this.props;
		if (isOpen) {
			return (
				<div>
					<Modal
						isOpen={isOpen}
						onRequestClose={this.handleClose}
						style={customStyles}
						contentLabel="Stock Producto"
					>
						<div className="conti-modal">
							<h2>Carga de Inventario</h2>
							{this.renderItemSearch()}
							{this.renderItemInventory()}
							{this.renderLoader()}
						</div>
					</Modal>
				</div>
			);
		}
		return null;
	};
}
