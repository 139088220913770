import React from "react";
import Loader from "../../loader/Loader";
import HrOrdersItem from "./HrOrdersItem";
import { MAIN_TABS, RECEIVE_PRODUCT_STATUS } from "features/constants";

const calcTotals = (list) => {
	if (list == null || list.length === 0) {
		return {
			orders: 0,
			tires: 0,
			orderString: "Pedidos",
			tireString: "Llantas",
		};
	} else {
		const tires = list.reduce((a, b) => a + b["Unidades"], 0);
		const orders = list.length;
		const orderString = orders === 1 ? "Pedido" : "Pedidos";
		const tireString = tires === 1 ? "Llanta" : "Llantas";
		return {
			orders: orders,
			tires: tires,
			orderString: orderString,
			tireString: tireString,
		};
	}
};
const calcSelected = (list) => {
	if (list == null || list.length === 0) {
		return {
			orders: 0,
			tires: 0,
			orderString: "Pedidos",
			tireString: "Llantas",
		};
	} else {
		const filteredList = list.filter((item) => item.isChecked === true);
		const tires = filteredList.reduce((a, b) => a + b["Unidades"], 0);
		const orders = filteredList.length;
		const orderString = orders === 1 ? "Pedido" : "Pedidos";
		const tireString = tires === 1 ? "Llanta" : "Llantas";
		return {
			orders: orders,
			tires: tires,
			orderString: orderString,
			tireString: tireString,
		};
	}
};
const resetStatus = (list) => {
	let newList = [];
	for (let i = 0; i < list.length; i++){
		newList.push({...list[i], status: RECEIVE_PRODUCT_STATUS.Ok})
	}
	return newList;
}
const allChecked = (list) => {
	let checkedArray = [];
	if (list != null && list.length > 0) {
		for (let i = 0; i < list.length; i++) {
			const newDetails = resetStatus(list[i].Detalles);
			checkedArray.push({ ...list[i], isChecked: true, Detalles: newDetails });
		}
	}
	return checkedArray;
};
const allUnChecked = (list) => {
	let checkedArray = [];
	if (list != null && list.length > 0) {
		for (let i = 0; i < list.length; i++) {
			const newDetails = resetStatus(list[i].Detalles);
			checkedArray.push({ ...list[i], isChecked: false, Detalles: newDetails });
		}
	}
	return checkedArray;
};
const arrayFindByProp = (array, prop, value) => {
	for (let i = 0; i < array.length; i++) {
		if (array[i][prop] === value) {
			return i;
		}
	}
	return -1;
};
export default class HrOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			list: allUnChecked(this.props.list),
		};
		this.checkAll = React.createRef();
	}
	// Validar si cambian los datos
	static getDerivedStateFromProps(props, state) {
		if (props.list != null && props.list.length !== state.list.length) {
			return {
				list: allUnChecked(props.list),
			};
		}
		return null;
	}
	handleCheckChange = (order, isChecked) => {
		let newList = this.state.list;
		const index = arrayFindByProp(newList, "Pedido", order);
		const newDetails = resetStatus(newList[index].Detalles);
		newList[index] = { ...newList[index], isChecked: isChecked, Detalles: newDetails};
		this.setState({ list: newList });
		const checkedList = newList.filter((item) => item.isChecked === true);
		if (checkedList.length === newList.length) {
			this.checkAll.current.checked = true;
		} else {
			this.checkAll.current.checked = false;
		}
	};
	handleItemStatusChange = (order, itemIndex, status) => {
		let newList = this.state.list;
		const index = arrayFindByProp(newList, "Pedido", order);
		let newDetails = [...newList[index].Detalles];
		newDetails[itemIndex] = { ...newDetails[itemIndex], status: status };
		newList[index] = { ...newList[index], Detalles: newDetails };
		this.setState({ list: newList });
	};
	handleCheckAll = (e) => {
		if (e.target.checked) {
			this.setState({ list: allChecked(this.state.list) });
		} else {
			this.setState({ list: allUnChecked(this.state.list) });
		}
	};
	handleDownloadClick = () => {
		const selected = this.state.list.filter((i) => i.isChecked === true);
		this.props.onReceive(selected);
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>
						<div className="check-switch-black">
							<input
								type="checkbox"
								id="select-all"
								name="select-all"
								value="on"
								onChange={this.handleCheckAll}
								ref={this.checkAll}
							/>
							<label htmlFor="select-all">
								<span className="icon-switch"></span>
							</label>
						</div>
					</th>
					<th>
						Pedido<span className="icon-sort-inverse"></span>
					</th>
					<th>Código</th>
					<th>Cant.</th>
					<th>Estatus</th>
					<th>Llanta</th>
					<th>Medida</th>
					<th>Localidad</th>
					<th>Empleado</th>
					<th>Nombre</th>
					<th>Correo</th>
				</tr>
			</thead>
		);
	};
	renderOrders = () => {
		const { isLoading, isLoadingReceive, list } = this.state;
		if (!isLoading && !isLoadingReceive && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderTableHeaders()}
						{list.map((el, i) => {
							return (
								<HrOrdersItem
									key={i}
									item={el}
									isChecked={el.isChecked}
									onClick={this.handleCheckChange}
									onChange={this.handleItemStatusChange}
								/>
							);
						})}
						<tfoot>
							<tr>
								<td colSpan="11">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderTableHeaders()}
					<tbody>
						<tr>
							<td colSpan="11">No se encontraron pedidos.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="11">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	renderLoader = () => {
		const { isLoading, isLoadingReceive } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando datos..." />;
		}
		if (isLoadingReceive) {
			return <Loader msg="Preparando descarga..." />;
		}
		return null;
	};
	renderDownload = () => {
		const { isLoading, isLoadingReceive, list } = this.props;
		const stateList = this.state.list;
		const selected =
			stateList != null
				? stateList.filter((item) => item.isChecked === true)
				: [];
		if (!isLoading && !isLoadingReceive && list != null && list.length > 0) {
			if (selected.length > 0) {
				return (
					<div className="form-download">
						<div>
							<div className="button main" onClick={this.handleDownloadClick}>
								Recibir y Descargar Evidencia
							</div>
						</div>
					</div>
				);
			}
			return (
				<div className="form-download">
					<div>
						<div className="button main disabled">
							Recibir y Descargar Evidencia
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	renderOrdersSummary = () => {
		const { isLoading, isLoadingReceive, list } = this.props;
		const orderCount = calcTotals(list);
		if (!isLoading && !isLoadingReceive) {
			return (
				<div className="orders-table-summary">
					{orderCount.orders +
						" " +
						orderCount.orderString +
						" / " +
						orderCount.tires +
						" " +
						orderCount.tireString}
				</div>
			);
		}
	};
	renderOrdersTitle = () => {
		const { isLoading, isLoadingReceive } = this.props;
		const orderSelected = calcSelected(this.state.list);
		if (!isLoading && !isLoadingReceive) {
			return (
				<div className="orders-table-title">
					<div>Selección:</div>
					<div className="detail">
						<span>{orderSelected.orders}</span> {orderSelected.orderString}
					</div>
					<div className="detail">
						<span>{orderSelected.tires}</span> {orderSelected.tireString}
					</div>
				</div>
			);
		}
	};
	render = () => {
		const { activeTabItem } = this.props;
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.HrOrders) {
			return (
				<section className="delivery">
					<div>
						<h1>Recepción de Llantas</h1>
						<p>
							Bienvenido al sistema de administración de pedidos de ContiShop.
						</p>
						<p>
							El proceso de recepción de pedidos sirve para generar la evidencia
							de recepción de pedidos en la localidad, y para notificar de forma
							automática a los empleados que sus llantas ya se encuentran
							disponibles para que pasen a recogerlas cuanto antes.
						</p>
						<p>
							Por favor selecciona los pedidos de las llantas que ya se
							recibieron y oprime el botón para generar la evidencia y notificar
							a los empleados.
						</p>
					</div>
					<div className="orders-table">
						{this.renderOrdersSummary()}
						{this.renderOrdersTitle()}
						<div className="orders-table-content-check">
							{this.renderLoader()}
							{this.renderOrders()}
						</div>
					</div>
					{this.renderDownload()}
				</section>
			);
		}
		return null;
	};
}
