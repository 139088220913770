import {
	fetchBlockedProductDelete,
	setBlockedProductItem,
	toggleBlockedProductModal,
} from "features/products/blockedProductsSlice";
import { connect } from "react-redux";
import BlockedProductModal from "./BlockedProductModal";

const mapStateToProps = (state) => {
	const { isLoading, isOpen, selectedItem } = state.blockedProducts;
	return {
		isLoading,
		isOpen,
		selectedItem,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDelete: (id) => {
			dispatch(fetchBlockedProductDelete(id));
		},
		onClose: () => {
			dispatch(setBlockedProductItem({ selectedItem: null }));
			dispatch(toggleBlockedProductModal({ isOpen: true }));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BlockedProductModal);
