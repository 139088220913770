import React from "react";
import Loader from "../../loader/Loader";
import { MAIN_TABS } from "features/constants";
import { numberFormat } from "features/functions";
import SupportMessageDetails from "./SupportMessageDetails";
import SupportMessageItem from "./SupportMessageItem";

export default class SupportMessages extends React.Component {
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Nombre</th>
					<th>Correo</th>
					<th>Mensaje</th>
					<th>Tipo</th>
					<th>Categoría</th>
					<th>Estatus</th>
					<th>Última Modificación</th>
					<th>Fecha Atención</th>
					<th>Atendido por</th>
				</tr>
			</thead>
		);
	};
	renderMessages = () => {
		const cols = 10;
		const { isLoading, total, list, onClick, isOpening, message } = this.props;
		if (isOpening || message != null) {
			return null;
		}
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<div className="orders-table">
						<div className="orders-table-title">
							<div>
								Mensajes: <span>{numberFormat(total)}</span>
							</div>
						</div>
						<div className="orders-table-content">
							<table cellPadding="0" cellSpacing="0" border="0">
								{this.renderTableHeaders()}
								<tbody>
									{list.map((el, i) => {
										return (
											<SupportMessageItem
												key={i}
												index={i}
												item={el}
												onClick={onClick}
											/>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan={cols}>&nbsp;</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				);
			}
			return (
				<div className="orders-table">
					<div className="orders-table-title">
						<div>
							Mensajes: <span>{numberFormat(total)}</span>
						</div>
					</div>
					<div className="orders-table-content">
						<table cellPadding="0" cellSpacing="0" border="0">
							{this.renderTableHeaders()}
							<tbody>
								<tr>
									<td colSpan={cols}>No hay mensajes que mostrar.</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={cols}>&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
		return null;
	};
	renderMessageDetails = () => {
		const {
			message,
			isOpening,
			onClose,
			isEditing,
			onEdit,
			onSave,
			onSetErrorMsg,
			errorMsg,
			isSaving,
		} = this.props;
		if (isOpening || message != null) {
			return (
				<SupportMessageDetails
					message={message}
					isLoading={isOpening}
					onClose={onClose}
					onEdit={onEdit}
					onSave={onSave}
					onSetErrorMsg={onSetErrorMsg}
					isEditing={isEditing}
					isSaving={isSaving}
					errorMsg={errorMsg}
				/>
			);
		}
		return null;
	};
	render = () => {
		const { isLoading, activeTabItem } = this.props;
		if (isLoading) {
			return <div>{this.renderLoader()}</div>;
		}
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.ContactMessages) {
			return (
				<section className="sales">
					<div>
						<h1>Mensajes de Contacto</h1>
					</div>

					{this.renderMessages()}
					{this.renderMessageDetails()}
				</section>
			);
		}
		return null;
	};
}
