import {
	CONTACT_MESSAGE_CATEGORY,
	CONTACT_MESSAGE_STATUS,
	CONTACT_MESSAGE_CATEGORY as mc,
	CONTACT_MESSAGE_STATUS as ms,
} from "features/constants";
import {
	objectToList,
	toFieldClass,
	toSelectorOptions,
} from "features/functions";
import React from "react";
import ReactDropdown from "react-dropdown";
import Loader from "components/loader/Loader";

export default class ContactMessageForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			action: "",
			selectedCategory: this.props.message.category,
			selectedStatus: this.props.message.status,
		};
	}
	// Handlers
	handleCancelClick = () => {
		this.props.onSetErrorMsg({ errorMsg: null });
		this.props.onCancel();
	};
	handleCategoryChange = (option) => {
		const { errorMsg, onSetErrorMsg } = this.props;
		if (errorMsg?.category != null) {
			onSetErrorMsg({ errorMsg: { ...errorMsg, category: null, error: null } });
		}
		this.setState({ selectedCategory: option.value });
	};
	handleStatusChange = (option) => {
		const { errorMsg, onSetErrorMsg } = this.props;
		if (errorMsg?.status != null) {
			onSetErrorMsg({ errorMsg: { ...errorMsg, status: null, error: null } });
		}
		this.setState({ selectedStatus: option.value });
	};
	handleActionChange = (e) => {
		const { errorMsg, onSetErrorMsg } = this.props;
		if (errorMsg?.action != null) {
			onSetErrorMsg({ errorMsg: { ...errorMsg, action: null, error: null } });
		}
		this.setState({
			action: e.target.value,
		});
	};
	handleSubmit = () => {
		const { action, selectedCategory, selectedStatus } = this.state;
		const { message, onSetErrorMsg, onSave } = this.props;
		let error = false;
		let errorMessages = {};
		if (action.trim() === "") {
			errorMessages = {
				...errorMessages,
				action: "Debes escribir las acciones.",
			};
			error = true;
		}
		if (selectedCategory === CONTACT_MESSAGE_CATEGORY.Uncategorized) {
			errorMessages = {
				...errorMessages,
				category: "Debes seleccionar una categoría",
			};
			error = true;
		}
		if (selectedStatus === CONTACT_MESSAGE_STATUS.Pending) {
			errorMessages = {
				...errorMessages,
				status: "Debes seleccionar el nuevo Estatus",
			};
			error = true;
		}
		if (error) {
			onSetErrorMsg({ errorMsg: errorMessages });
		} else {
			const data = {
				id: message.id,
				action,
				category: selectedCategory,
				status: selectedStatus,
			};
			onSave(data);
		}
	};
	// Render
	renderErrorList = (errorList) => {
		if (errorList.length > 0) {
			return (
				<div className="error-list">
					<ul>
						{errorList.map((err, index) => {
							if (err.value != null) {
								return <li key={index}>{err.value}</li>;
							}
							return null;
						})}
					</ul>
				</div>
			);
		}
	};
	renderCategoryDropdown = (fieldClass) => {
		const { selectedCategory } = this.state;
		const { message } = this.props;
		const categoryOptions =
			message.category !== mc.Uncategorized
				? toSelectorOptions(mc.properties, [mc.Uncategorized])
				: toSelectorOptions(mc.properties);
		const defaultCategoryOption =
			selectedCategory != null
				? categoryOptions.filter((opc) => opc.value === selectedCategory)[0]
				: categoryOptions.filter((opc) => opc.value === 0)[0];
		return (
			<div>
				<div style={{ padding: "5px 0" }} {...fieldClass.category}>
					<label>Categoría</label>
					<ReactDropdown
						options={categoryOptions}
						value={defaultCategoryOption}
						onChange={this.handleCategoryChange}
						placeholder={defaultCategoryOption.label}
					/>
				</div>
			</div>
		);
	};
	renderStatusDropdown = (fieldClass) => {
		const { selectedStatus } = this.state;
		const { message } = this.props;
		const statusOptions =
			message.status === ms.Pending
				? toSelectorOptions(ms.properties, [ms.ClosedUnanswered])
				: toSelectorOptions(ms.properties, [ms.Pending]);
		const defaultStatusOption =
			selectedStatus != null
				? statusOptions.filter((opc) => opc.value === selectedStatus)[0]
				: statusOptions.filter((opc) => opc.value === 0)[0];
		return (
			<div>
				<div style={{ padding: "5px 0" }} {...fieldClass.status}>
					<label>Estatus</label>
					<ReactDropdown
						options={statusOptions}
						value={defaultStatusOption}
						onChange={this.handleStatusChange}
						placeholder={defaultStatusOption.label}
					/>
				</div>
			</div>
		);
	};
	render = () => {
		const { errorMsg, isSaving } = this.props;
		const errorList = objectToList(errorMsg);
		const fieldClass = toFieldClass(errorList);
		if (isSaving) {
			return (
				<div className="delivery-form">
					<div className="form-header">
						<div>
							<div className="form-title">
								Formulario de Atención de Mensajes
							</div>
						</div>
					</div>
					<Loader msg="Guardando..." />
				</div>
			);
		}
		return (
			<div className="delivery-form">
				<div className="form-header">
					<div>
						<div className="form-title">Formulario de Atención de Mensajes</div>
						<div className="form-close">
							<span
								className="icon-close"
								onClick={this.handleCancelClick}
							></span>
						</div>
					</div>
				</div>
				<div className="form-content">
					<div className="form-a">
						{this.renderCategoryDropdown(fieldClass)}
						{this.renderStatusDropdown(fieldClass)}
					</div>
					<div className="form-a full">
						<div {...fieldClass.action}>
							<label htmlFor="action">Acciones</label>
							<textarea
								id="action"
								placeholder="Acciones"
								autoComplete="off"
								onChange={this.handleActionChange}
							></textarea>
						</div>
					</div>
					{this.renderErrorList(errorList)}
					<div className="form-submit">
						<div>
							<div className="button" onClick={this.handleCancelClick}>
								Cancelar
							</div>
						</div>
						<div>
							<div className="button main" onClick={this.handleSubmit}>
								Guardar
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}
