import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "features/constants";
import { fetchSearchProducts } from "./searchProductsSlice";
import { fetchInventory } from "features/inventory/inventorySlice";
import { runUnauthorizedActions } from "features/login/loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	selectedItem: null,
	source: null,
	errorMsg: null,
};

const stockAdd = createSlice({
	name: "stockAdd",
	initialState,
	reducers: {
		requestStockAdd(state) {
			state.isLoading = true;
		},
		receiveStockAdd(state) {
			state.isLoading = false;
		},
		setStockAddErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		toggleStockAddModal(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		setStockAddSelectedItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
			state.source = action.payload.source;
		},
		clearStockAddData: () => initialState,
	},
});

export const {
	requestStockAdd,
	receiveStockAdd,
	setStockAddErrorMsg,
	toggleStockAddModal,
	setStockAddSelectedItem,
	clearStockAddData,
} = stockAdd.actions;

export const fetchProductStockAdd = (data) => async (dispatch, getState) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				Codigo: data.code,
				IdAlmacen: data.warehouse,
				Cantidad: data.qty,
			}),
		};
		let responseStatus = 0;
		dispatch(requestStockAdd());
		return fetch(
			BASE_API_URL + "/api/Manager/Productos/Inventario/" + data.code,
			options
		)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.text();
				} else {
					return Promise.reject("No tiene permisos para este recurso.");
				}
			})
			.then((text) => {
				dispatch(receiveStockAdd());
				dispatch(toggleStockAddModal({ isOpen: true }));
				if (data.source != null && data.source === "search") {
					const theState = getState();
					if (theState.searchProducts.list.length > 0) {
						const list = theState.searchProducts.list.map(
							(item) => item["Codigo"]
						);
						dispatch(fetchSearchProducts(list));
					}
				}
				if (data.source != null && data.source === "inventory") {
					dispatch(fetchInventory());
				}
			})
			.catch((error) => {
				dispatch(receiveStockAdd());
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				if (responseStatus === 400) {
					dispatch(
						setStockAddErrorMsg({
							errorMsg:
								"No se pudo agregar inventario porque el producto ya cuenta con inventario.",
						})
					);
				}
			});
	});
};

export default stockAdd.reducer;
