import { connect } from "react-redux";
import DealerLocationOrders from "./DealerLocationOrders";
import { fetchDealerLocationOrders } from "features/dealer/dealerLocationOrdersSlice";
import { fetchDealerLocationOrdersReceive } from "features/dealer/dealerLocationOrdersReceiveSlice";

const mapStateToProps = (state) => {
	const { isLoading, list } = state.dealerLocationOrders;
	const isLoadingReceive = state.dealerLocationOrdersReceive.isLoading;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		isLoadingReceive,
		list,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLoad: () => {
			dispatch(fetchDealerLocationOrders());
		},
		onReceive: (data) => {
			// Validate Data
			// At least one order must be selected
			let orderList = [];
			if (data.length > 0) {
				// Build array
				for (let i = 0; i < data.length; i++) {
					const issues = data[i].Detalles.map((el,i) => {
						return {Codigo: el.Codigo, Estatus: el.status }
					})
					orderList.push({Numero: data[i].Pedido, Incidencias: issues});
				}
				dispatch(fetchDealerLocationOrdersReceive(orderList));
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerLocationOrders);
