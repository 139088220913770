import { createSlice } from "@reduxjs/toolkit";
import { fetchInventory } from "features/inventory/inventorySlice";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { clearSearchProducts } from "./searchProductsSlice";

const blockedProducts = createSlice({
	name: "bloquedProducts",
	initialState: {
		isLoading: false,
		list: [],
		isOpen: false,
		selectedItem: null,
		isOpenAdd: false,
		selectedAdd: null,
		source: null,
	},
	reducers: {
		requestBlockedProducts(state) {
			state.isLoading = true;
		},
		receiveBlockedProducts(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		completeBlockedProducts(state) {
			state.isLoading = false;
		},
		toggleBlockedProductModal(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		toggleBlockedProductModalAdd(state, action) {
			state.isOpenAdd = !action.payload.isOpenAdd;
		},
		setBlockedProductItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
			state.source = action.payload.source;
		},
		setBlockedProductAdd(state, action) {
			state.selectedAdd = action.payload.selectedAdd;
			state.source = action.payload.source;
		},
		clearBlockedProducts(state) {
			state.isLoading = false;
			state.list = [];
			state.isOpen = false;
			state.selectedItem = null;
			state.isOpenAdd = false;
			state.selectedAdd = null;
			state.source = null;
		},
	},
});

export const {
	requestBlockedProducts,
	receiveBlockedProducts,
	completeBlockedProducts,
	toggleBlockedProductModal,
	toggleBlockedProductModalAdd,
	setBlockedProductItem,
	setBlockedProductAdd,
	clearBlockedProducts,
} = blockedProducts.actions;

export const fetchBlockedProducts = () => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		let headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		let options = {
			method: "GET",
			headers: headers,
		};
		let responseStatus = 0;
		dispatch(requestBlockedProducts());
		return fetch(BASE_API_URL + "/api/Manager/Productos/Bloqueados", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("No tiene permisos para este recurso.");
				}
			})
			.then((json) => {
				dispatch(receiveBlockedProducts({ list: json }));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export const fetchBlockedProductDelete = (id) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "DELETE",
			headers: headers,
		};
		let responseStatus = 0;
		dispatch(requestBlockedProducts());
		return fetch(
			BASE_API_URL + "/api/Manager/Productos/Bloqueados/" + id,
			options
		)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.text();
				} else {
					return Promise.reject("No tiene permisos para este recurso.");
				}
			})
			.then((text) => {
				dispatch(completeBlockedProducts());
				dispatch(setBlockedProductItem({ selectedItem: null, source: null }));
				dispatch(toggleBlockedProductModal({ isOpen: true }));
				dispatch(fetchBlockedProducts());
			})
			.catch((error) => {
				dispatch(completeBlockedProducts());
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export const fetchBlockedProductAdd = (data) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				Codigo: data.code,
				IdAlmacen: data.warehouse,
				Motivo: data.reason,
			}),
		};
		let responseStatus = 0;
		dispatch(requestBlockedProducts());
		return fetch(BASE_API_URL + "/api/Manager/Productos/Bloqueados", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.text();
				} else {
					return Promise.reject("No tiene permisos para este recurso.");
				}
			})
			.then((text) => {
				dispatch(completeBlockedProducts());
				dispatch(
					setBlockedProductAdd({ selectedAdd: null, source: data.source })
				);
				dispatch(toggleBlockedProductModalAdd({ isOpenAdd: true }));
				if (data.source != null && data.source === "search") {
					dispatch(clearSearchProducts());
				}
				if (data.source != null && data.source === "inventory") {
					dispatch(fetchInventory());
				}
			})
			.catch((error) => {
				dispatch(completeBlockedProducts());
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export default blockedProducts.reducer;
