import { connect } from "react-redux";
import ContactMessages from "./ContactMessages";
import {
	fetchContactMessage,
	fetchContactMessages,
	fetchUpdateContactMessage,
	setContactMessageErrorMsg,
	setContactMessagesEditMessage,
	setContactMessagesFilters,
	setContactMessagesFrom,
	setContactMessagesMessage,
	setContactMessagesPage,
	setContactMessagesStatus,
	setContactMessagesUntil,
} from "features/messages/contactMessagesSlice";

const mapStateToProps = (state) => {
	const {
		isLoading,
		list,
		page,
		rows,
		total,
		from,
		until,
		status,
		isOpening,
		message,
		isEditing,
		isSaving,
		errorMsg,
	} = state.contactMessages;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		page,
		rows,
		total,
		from,
		until,
		status,
		isOpening,
		message,
		isEditing,
		errorMsg,
		isSaving,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (id) => {
			dispatch(fetchContactMessage(id));
		},
		onClose: () => {
			dispatch(setContactMessagesMessage({ message: null }));
		},
		onEdit: (edit) => {
			dispatch(setContactMessagesEditMessage({ isEditing: edit }));
		},
		onSetErrorMsg: (errorMsg) => {
			dispatch(setContactMessageErrorMsg(errorMsg));
		},
		onSave: (data) => {
			dispatch(fetchUpdateContactMessage(data));
		},
		onStatusChange: (status) => {
			dispatch(setContactMessagesStatus(status));
			dispatch(fetchContactMessages());
		},
		onFromChange: (from) => {
			dispatch(setContactMessagesFrom(from));
			dispatch(fetchContactMessages());
		},
		onUntilChange: (until) => {
			dispatch(setContactMessagesUntil(until));
			dispatch(fetchContactMessages());
		},
		onClearFilters: () => {
			dispatch(setContactMessagesFilters({
				page: 0,
				rows: 50,
				status: 0,
				from: null,
				until: null,
				category: 0,
				type: 0,
				managerId: null,
			}));
			dispatch(fetchContactMessages());
		},
		onPage: (page) => {
			dispatch(setContactMessagesPage({ page }));
			dispatch(fetchContactMessages());
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactMessages);
