import { connect } from "react-redux";
import { toggleShowAddContiPartnerCoupon } from "features/contipartners/addPartnerCouponSlice";
import {
	clearSelectedItem,
	fetchContiPartnerCoupons,
	fetchContiPartners,
	setSelectedItem,
} from "features/contipartners/contiPartnersSlice";
import ContiPartners from "./ContiPartners";
import { toggleShowAddContiPartner } from "features/contipartners/addPartnerSlice";

const mapStateToProps = (state) => {
	const { isLoading, list, selectedItem, couponList } = state.contiPartners;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		selectedItem,
		couponList,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (item) => {
			dispatch(setSelectedItem({ selectedItem: item }));
			dispatch(fetchContiPartnerCoupons(item.Id));
		},
		onClear: () => {
			dispatch(clearSelectedItem());
			dispatch(fetchContiPartners());
		},
		onAddPartner: () => {
			dispatch(toggleShowAddContiPartner({ isOpen: false }));
		},
		onChangePartner: () => {},
		onAddCoupon: () => {
			dispatch(toggleShowAddContiPartnerCoupon({ isOpen: false }));
		},
		onChangeCoupon: () => {},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContiPartners);
