import React from "react";
import ReactDatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import { MAIN_TABS } from "features/constants";
import { numberFormat } from "features/functions";
import PageControls from "components/common/pageControls";
import Loader from "../../loader/Loader";
import UserItem from "./UserItem";

const setTotals = (list, total, page, rows) => {
	if (list == null) {
		return {
			showingFrom: 0,
			showingTo: 0,
			pages: 0,
		};
	}
	const pages = Math.ceil(total / rows);
	const showingFrom = total !== 0 ? page * rows + 1 : 0;
	let showingTo = 0;
	if (total < rows) showingTo = total;
	if (total >= rows) {
		if (page + 1 === pages) {
			showingTo = total;
		} else {
			showingTo = (page + 1) * rows;
		}
	}
	return {
		showingFrom: showingFrom,
		showingTo: showingTo,
		pages: pages,
	};
};

const typeOptions = [
	{ label: "Todos", value: 0 },
	{ label: "Consumidor", value: 1 },
	{ label: "FamilyFriend", value: 3 },
	{ label: "ContiPartner", value: 4 },
];

export default class Users extends React.Component {
	handleTypeChange = (option) => {
		this.props.onTypeChange(option.value);
	};
	handleFromChange = (date) => {
		this.props.onFromChange(date);
	};
	handleUntilChange = (date) => {
		this.props.onUntilChange(date);
	};
	handleNext = () => {
		const { total, page, rows, list } = this.props;
		const pages = list != null ? Math.ceil(total / rows) - 1 : 0;
		if (page < pages) {
			this.props.onPage(page + 1);
		}
	};
	handleLast = () => {
		const { total, page, rows, list } = this.props;
		const pages = list != null ? Math.ceil(total / rows) - 1 : 0;
		if (page !== pages) {
			this.props.onPage(pages);
		}
	};
	handlePrev = () => {
		const { page } = this.props;
		if (page > 0) {
			this.props.onPage(page - 1);
		}
	};
	handleFirst = () => {
		const { page } = this.props;
		if (page > 0) {
			this.props.onPage(0);
		}
	};
	handlePageChange = (e) => {
		// const newPage = e.target.value.replace(/[^0-9]/g, "");
		// this.setState({ page: newPage });
	};
	handleClearFilters = () => {
		this.props.onClearFilters();
	};
	renderFilters = () => {
		const {
			page,
			rows,
			type,
			// active,
			// subscribed,
			// gender,
			// email,
			// name,
			// lastName,
			// familyName,
			from,
			until,
			// partnerId,
			// isLoading,
			list,
			total,
		} = this.props;
		const totals = setTotals(list, total, page, rows);
		const typeDefaultOption = typeOptions.filter(
			(option) => option.value === type ?? 0
		)[0];
		return (
			<div className="order-filters">
				<div>
					<div className="field">
						<label>Desde</label>
						<div>
							<ReactDatePicker
								selected={from}
								onChange={this.handleFromChange}
								closeOnScroll={true}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="field">
						<label>Hasta</label>
						<div>
							<ReactDatePicker
								selected={until}
								onChange={this.handleUntilChange}
								closeOnScroll={true}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="field">
						<label>Tipo</label>
						<Dropdown
							options={typeOptions}
							value={typeDefaultOption}
							onChange={this.handleTypeChange}
							placeholder={typeDefaultOption.label}
						/>
					</div>
				</div>
				<div className="controls">
					<div>
						<PageControls
							currentPage={page + 1}
							totalPages={totals.pages}
							onPageChange={this.handlePageChange}
							onFirst={this.handleFirst}
							onLast={this.handleLast}
							onPrev={this.handlePrev}
							onNext={this.handleNext}
						/>
						<div>
							<div className="button" onClick={this.handleClearFilters}>
								Restablecer Filtros
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Apellido Paterno</th>
					<th>Apellido Materno</th>
					<th>Tipo</th>
					<th>Correo</th>
					<th>Teléfono</th>
					<th>Género</th>
					<th>Fecha Nacimiento</th>
					<th>Fecha Registro</th>
					<th>Activo</th>
					<th>Suscrito</th>
					<th>ContiPartner</th>
					<th>Fecha Última Compra</th>
				</tr>
			</thead>
		);
	};
	renderData = () => {
		const cols = 13;
		const { isLoading, total, list } = this.props;
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<div className="orders-table">
						<div className="orders-table-title">
							<div>
								Usuarios: <span>{numberFormat(total)}</span>
							</div>
						</div>
						<div className="orders-table-content">
							<table cellPadding="0" cellSpacing="0" border="0">
								{this.renderTableHeaders()}
								<tbody>
									{list.map((el, i) => {
										return <UserItem key={i} index={i} item={el} />;
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan={cols}>&nbsp;</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				);
			}
			return (
				<div className="orders-table">
					<div className="orders-table-title">
						<div>
							Usuarios: <span>{numberFormat(total)}</span>
						</div>
					</div>
					<div className="orders-table-content">
						<table cellPadding="0" cellSpacing="0" border="0">
							{this.renderTableHeaders()}
							<tbody>
								<tr>
									<td colSpan={cols}>No hay usuarios que mostrar.</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={cols}>&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
		return null;
	};
	render = () => {
		const { activeTabItem } = this.props;
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.Users) {
			return (
				<section className="sales">
					<div>
						<h1>Usuarios Registrados</h1>
					</div>
					{this.renderFilters()}
					{this.renderLoader()}
					{this.renderData()}
				</section>
			);
		}
		return null;
	};
}
