import React from "react";
import { format, parseISO } from "date-fns";

export default class ContiPartnerCouponItem extends React.Component {
	handleClick = () => {
		this.props.onClick(this.props.item);
	};
	renderStatus = () => {
		const { item } = this.props;
		if (!item.Activo) {
			return <td className="red">Inactivo</td>;
		}
		return <td>Activo</td>;
	};
	render = () => {
		const { item, index } = this.props;
		return (
			<tr onClick={this.handleClick}>
				<td>{index + 1}</td>
				<td>{item.Llave}</td>
				<td>{item.Capacidad}</td>
				<td>{item.Periodo}</td>
				<td>{format(parseISO(item.Modificado), "dd/MMM/yyyy hh:mm aaaa")}</td>
				{this.renderStatus()}
				<td>{item.Manager}</td>
				<td>{format(parseISO(item.Creado), "dd/MMM/yyyy")}</td>
			</tr>
		);
	};
}
