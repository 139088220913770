import React from "react";
import Modal from "react-modal";
import { format, parseISO } from "date-fns";
import Loader from "components/loader/Loader";

export default class BlockedProductModal extends React.Component {
	handleClose = () => {
		this.props.onClose();
	};
	handleDelete = () => {
		const { selectedItem } = this.props;
		this.props.onDelete(selectedItem.Id);
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderItem = () => {
		const { isLoading, selectedItem } = this.props;
		if (!isLoading && selectedItem != null) {
			return (
				<div>
					<h3>
						{selectedItem.Marca}<br />
						<strong>
							{selectedItem.Producto}
						</strong><br />
						<strong><span>{selectedItem.Medida}</span></strong>
						<br />
						{selectedItem.Codigo}
					</h3>
					<p>
						<span>{"Almacén"}</span><br />
						<strong>{selectedItem.Almacen}</strong>
						<br />
						<span>{"Inventario"}</span><br />
						<strong>{selectedItem.Inventario}</strong>
						<br />
						<span>{"Bloqueado por"}</span><br />
						<strong>{selectedItem.Usuario}</strong>
						<br />
						<span>{"Fecha"}</span><br />
						<strong>
							{format(parseISO(selectedItem.Fecha), "dd/MMM/yyyy hh:mm aaaa")}
						</strong>
					</p>
					<p style={{ marginBottom: 0 }}><span>{"Motivo"}</span></p>
					<p className="conti-area">
						{selectedItem.Motivo}
					</p>
					<div>
						<div className="button alert" onClick={this.handleDelete}>
							Eliminar
						</div>
						<div className="button main" onClick={this.handleClose}>
							Cerrar
						</div>
					</div>
				</div>
			);
		}
	};
	render = () => {
		const customStyles = {
			content: {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)",
				minWidth: "550px",
				minHeight: "350px",
			},
			overlay: {
				backgroundColor: "rgb(0,0,0,.7)",
				zIndex: "2",
			},
		};
		const { isOpen } = this.props;
		if (isOpen) {
			return (
				<div>
					<Modal
						isOpen={isOpen}
						onRequestClose={this.handleClose}
						style={customStyles}
						contentLabel="Producto Bloqueado"
					>
						<div className="conti-modal">
							<h2>Producto Bloqueado</h2>
							{this.renderItem()}
							{this.renderLoader()}
						</div>
					</Modal>
				</div>
			);
		}
		return null;
	};
}
