import { connect } from "react-redux";
import SupportOrders from "./SupportOrders";
import {
	fetchSupportOrder,
	setSupportErrorMsg,
} from "features/support/supportSlice";

const mapStateToProps = (state) => {
	const { tabList, activeTab } = state.tabs;
	const { errorMsg } = state.support;
	return {
		activeTabItem: tabList != null ? tabList[activeTab] : null,
		errorMsg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOpenOrder: (order) => {
			dispatch(fetchSupportOrder(order));
		},
		onClearError: () => {
			dispatch(setSupportErrorMsg({ errorMsg: null }));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportOrders);
