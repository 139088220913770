import React from "react";
import { MAIN_TABS } from "features/constants";
import DealerDeliveryForm from "./DealerDeliveryForm";
import DealerDeliveryOrder from "./DealerDeliveryOrder";
import DealerDeliverySuccess from "./DealerDeliverySuccess";
import Loader from "../../loader/Loader";

export default class DealerDelivery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orderNumber: "",
			employeeNumber: "",
		};
	}
	handleSearchClick = () => {
		if (this.state.orderNumber.trim() === "") {
			this.props.onSetErrorMsg({
				search: "Debes escribir el número del pedido",
			});
		} else {
			this.props.onSearchOrder(this.state);
		}
	};
	handleCancelClick = () => {
		this.setState({ orderNumber: "", employeeNumber: "" });
		this.props.onCancel();
	};
	handleSuccessClick = () => {
		this.setState({ orderNumber: "", employeeNumber: "" });
		this.props.onSuccessClick();
	};
	handleSetErrorMessage = (errorMsg) => {
		this.props.onSetErrorMsg(errorMsg);
	};
	handleDownloadClick = (data) => {
		this.props.onDownload(data, this.props.order);
	};
	handleOrderNumberChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.search != null) {
			this.props.onSetErrorMsg({ ...errorMsg, search: null });
		}
		this.setState({
			orderNumber: e.target.value,
		});
	};
	handleEmployeeNumberChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.employee != null) {
			this.props.onSetErrorMsg({ ...errorMsg, employee: null });
		}
		this.setState({
			employeeNumber: e.target.value,
		});
	};
	handleOrderKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleSearchClick();
		}
	};
	renderError = (error) => {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	};
	renderErrorList = () => {
		const { errorMsg } = this.props;
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{this.renderError(errorMsg.error)}
						{this.renderError(errorMsg.search)}
						{this.renderError(errorMsg.employee)}
					</ul>
				</div>
			);
		}
	};
	renderLoader = () => {
		const { isLoading, order, success } = this.props;
		if (isLoading && order == null && success == null) {
			return <Loader msg="Buscando..." />;
		}
		if (isLoading && order != null) {
			return <Loader msg="Preparando descarga..." />;
		}
		return null;
	};
	renderSearch = () => {
		const { isLoading, order, success, errorMsg } = this.props;
		const searchProps =
			errorMsg != null && errorMsg.search != null
				? { className: "field-error" }
				: {};
		const employeeProps =
			errorMsg != null && errorMsg.employee != null
				? { className: "field-error" }
				: {};
		if (!isLoading && order == null && success == null) {
			return (
				<div>
					<div className="form-a">
						<div className="right">
							<div {...searchProps}>
								<label htmlFor="order-number">Número de Pedido</label>
								<input
									id="order-number"
									type="text"
									placeholder="Número de Pedido"
									autoComplete="off"
									onChange={this.handleOrderNumberChange}
									onKeyPress={this.handleOrderKeyPress}
									ref={(input) => (this.orderNumber = input)}
									value={this.state.orderNumber}
								></input>
							</div>
							<div {...employeeProps}>
								<label htmlFor="employee-number">Número de Empleado</label>
								<input
									id="employee-number"
									type="text"
									placeholder="Número de Empleado"
									autoComplete="off"
									onChange={this.handleEmployeeNumberChange}
									onKeyPress={this.handleOrderKeyPress}
									ref={(input) => (this.employeeNumber = input)}
									value={this.state.employeeNumber}
								></input>
							</div>
							<div>
								<div className="button main" onClick={this.handleSearchClick}>
									Buscar
								</div>
							</div>
						</div>
					</div>
					<div className="form-a full">{this.renderErrorList()}</div>
				</div>
			);
		}
		return null;
	};
	renderForm = () => {
		const { isLoading, order, errorMsg, list, user } = this.props;
		if (!isLoading && order != null) {
			return (
				<DealerDeliveryForm
					order={order}
					errorMsg={errorMsg}
					onCancel={this.handleCancelClick}
					onSetErrorMsg={this.handleSetErrorMessage}
					onDownload={this.handleDownloadClick}
					list={list}
					user={user}
				/>
			);
		}
		return null;
	};
	renderOrder = () => {
		const { isLoading, order } = this.props;
		if (!isLoading && order != null) {
			return <DealerDeliveryOrder order={order} />;
		}
		return null;
	};
	renderSuccess = () => {
		const { isLoading, success } = this.props;
		if (!isLoading && success != null) {
			return (
				<DealerDeliverySuccess
					success={success}
					onClick={this.handleSuccessClick}
				/>
			);
		}
		return null;
	};
	render = () => {
		const { activeTabItem } = this.props;
		if (
			activeTabItem != null &&
			activeTabItem.tab === MAIN_TABS.DealerOrderDelivery
		) {
			return (
				<section className="delivery">
					<div>
						<h1>Entrega de Pedidos</h1>
						<p>Bienvenido al sistema de entrega de pedidos de ContiShop.</p>
						<p>
							Para realizar una entrega por favor introduce el número del pedido
							y oprime el botón de buscar.
						</p>
					</div>
					{this.renderLoader()}
					{this.renderSearch()}
					{this.renderOrder()}
					{this.renderForm()}
					{this.renderSuccess()}
				</section>
			);
		}
		return null;
	};
}
