import { connect } from "react-redux";
import { fetchContiPartnerCoupons } from "features/contipartners/contiPartnersSlice";
import {
	clearAddContiPartnerCouponData,
	fetchAddContiPartnerCoupon,
	setAddContiPartnerCouponErrorMsg,
	setAddContiPartnerCouponName,
	setAddContiPartnerCouponQty,
} from "features/contipartners/addPartnerCouponSlice";
import AddPartnerCoupon from "./AddPartnerCoupon";

const mapStateToProps = (state) => {
	const { isOpen, isLoading, errorMsg, qty, name } = state.addPartnerCoupon;
	const { selectedItem } = state.contiPartners;
	return {
		isOpen,
		isLoading,
		errorMsg,
		qty,
		name,
		selectedItem,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (item, qty, name, errorMsg) => {
			let valid = true;
			let errorMessages = { ...errorMsg };
			if (name.trim() === "") {
				errorMessages = {
					...errorMessages,
					name: "Debes escribir la localidad para el cupón",
				};
				valid = false;
			}
			if (qty === 0) {
				errorMessages = {
					...errorMessages,
					qty: "Debes escribir la capacidad del cupón",
				};
				valid = false;
			}
			if (valid) {
				dispatch(fetchAddContiPartnerCoupon(item, qty, name));
			} else {
				dispatch(setAddContiPartnerCouponErrorMsg({ errorMsg: errorMessages }));
			}
		},
		onCancel: (item) => {
			dispatch(clearAddContiPartnerCouponData());
			dispatch(fetchContiPartnerCoupons(item.Id));
		},
		onQtyChange: (qty) => {
			dispatch(setAddContiPartnerCouponQty({ qty }));
		},
		onNameChange: (name) => {
			dispatch(setAddContiPartnerCouponName({ name }));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerCoupon);
