import { connect } from 'react-redux'
import Manager from './Manager'

const mapStateToProps = state => {
    const { authenticated, user } = state.session
    return {
        authenticated,
        user,
    }
}

export default connect(
    mapStateToProps,
    null
)(Manager)