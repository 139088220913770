import { connect } from "react-redux";
import AddPartnerModal from "./AddPartnerModal";
import { clearAddContiPartnerData, fetchAddContiPartner, setAddContiPartnerAlias, setAddContiPartnerErrorMsg, setAddContiPartnerFactor, setAddContiPartnerName } from "features/contipartners/addPartnerSlice";
import { fetchContiPartners } from "features/contipartners/contiPartnersSlice";

const mapStateToProps = (state) => {
	const { isOpen, isLoading, errorMsg, factor, alias, name } = state.addPartner;
	return {
		isOpen,
		isLoading,
		errorMsg,
		alias,
		name,
		factor,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (alias, name, factor, errorMsg) => {
			let valid = true;
			let errorMessages = { ...errorMsg };
			if (alias.trim() === "") {
				errorMessages = {
					...errorMessages,
					alias: "Debes escribir un alias sin espacios para el partner.",
				};
				valid = false;
			}
			if (name.trim() === "") {
				errorMessages = {
					...errorMessages,
					name: "Debes escribir el nombre del partner.",
				};
				valid = false;
			}
			if (factor === 0) {
				errorMessages = {
					...errorMessages,
					factor: "Debes escribir el factor de incremento sobre el precio de empleados de ContiShop.",
				};
				valid = false;
			}
			if (valid) {
				dispatch(fetchAddContiPartner(alias, name, factor));
			} else {
				dispatch(setAddContiPartnerErrorMsg({ errorMsg: errorMessages }));
			}
		},
		onCancel: () => {
			dispatch(clearAddContiPartnerData());
			dispatch(fetchContiPartners());
		},
		onNameChange: (name) => {
			dispatch(setAddContiPartnerName({ name }));
		},
		onAliasChange: (alias) => {
			dispatch(setAddContiPartnerAlias({ alias }));
		},
		onFactorChange: (factor) => {
			dispatch(setAddContiPartnerFactor({ factor }));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerModal);
