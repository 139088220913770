import {
	fetchProductStockAdd,
	toggleStockAddModal,
	setStockAddSelectedItem,
} from "features/products/stockAdd";
import { connect } from "react-redux";
import StockAddModal from "./StockAddModal";

const mapStateToProps = (state) => {
	const { isLoading, isOpen, selectedItem, source } = state.stockAdd;
	return {
		isLoading,
		isOpen,
		selectedItem,
		source,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddStock: (data) => {
			dispatch(fetchProductStockAdd(data));
		},
		onClose: () => {
			dispatch(setStockAddSelectedItem({ selectedItem: null, source: null }));
			dispatch(toggleStockAddModal({ isOpen: true }));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StockAddModal);
