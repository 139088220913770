import React from "react";

export default class DealerDeliverySuccess extends React.Component {
	handleClick = () => {
		this.props.onClick();
	};
	render = () => {
		const { success } = this.props;
		return (
			<div className="delivery-form">
				<div className="form-header">
					<div>
						<div className="form-title">
							Pedido <span>{success.order}</span>
						</div>
						<div className="form-close" onClick={this.handleClick}>
							<span className="icon-close"></span>
						</div>
					</div>
				</div>
				<div className="form-message">
					<div className="form-content">
						<div>
							<p>Se registró con éxito la entrega del pedido.</p>
							<p>
								Favor de imprimir el formato de entrega en pdf para que lo firme
								el cliente.
							</p>
						</div>
						<div className="form-submit">
							<div>
								<div className="button main" onClick={this.handleClick}>
									Cerrar
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}
