import { connect } from 'react-redux'
import MainMenu from './MainMenu'
import { runUnauthorizedActions } from 'features/login/loginSlice'

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            dispatch(runUnauthorizedActions())
        }
    }
}


export default connect(
    null,
    mapDispatchToProps,
)(MainMenu)