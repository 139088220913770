import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL, MAIN_TABS, PAYMENT_METHODS } from "features/constants";
import { createOrderTabs } from "features/functions";
import { runUnauthorizedActions } from "../login/loginSlice";
import { fetchCreditOrders } from "features/credit/creditOrdersSlice";
import { fetchInventory } from "features/inventory/inventorySlice";
import { fetchSoftware } from "features/software/softwareSlice";
import { fetchBlockedProducts } from "features/products/blockedProductsSlice";
import { fetchContiPartners } from "features/contipartners/contiPartnersSlice";
import { fetchContactMessages } from "features/messages/contactMessagesSlice";
import { fetchSupportMessages } from "features/support/supportMessagesSlice";
import { fetchUsers } from "features/users/usersSlice";

const tabs = createSlice({
	name: "tabs",
	initialState: {
		tabList: [],
		activeTab: 0,
		tabsMain: [],
		tabsOrders: [],
	},
	reducers: {
		setTabsMain(state, action) {
			state.tabsMain = action.payload.tabsMain;
			state.tabList = [...action.payload.tabsMain, ...state.tabsOrders];
		},
		setTabsOrders(state, action) {
			state.tabsOrders = action.payload.tabsOrders;
			state.tabList = [...state.tabsMain, ...action.payload.tabsOrders];
		},
		setActiveTab(state, action) {
			state.activeTab = action.payload.activeTab;
		},
		clearTabs(state) {
			state.tabList = [];
			state.activeTab = 0;
			state.tabsMain = [];
			state.tabsOrders = [];
		},
	},
});

export const {
	setTabsMain,
	setTabsOrders,
	setActiveTab,
	clearTabs,
} = tabs.actions;

export const fetchOrderTabs = () => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		let headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		let options = {
			method: "GET",
			headers: headers,
		};
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/TabsPedidos", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				const orderTabs = createOrderTabs(json.Pedidos);
				dispatch(setTabsOrders({ tabsOrders: orderTabs }));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export const fetchAddOrderTab = (order) => async (dispatch, getState) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const state = getState();
		const { tabsOrders, tabList } = state.tabs;
		const body = JSON.stringify({
			Accion: 1,
			Pedido: order,
		});
		const options = {
			method: "POST",
			headers: headers,
			body: body,
		};
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/TabsPedidos", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				const orderTabs = createOrderTabs(json.Pedidos);
				dispatch(setTabsOrders({ tabsOrders: orderTabs }));
				if (tabsOrders.length !== orderTabs.length) {
					dispatch(setActiveTab({ activeTab: tabList.length }));
				}
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export const fetchRemoveOrderTab = (order) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const body = JSON.stringify({
			Accion: 2,
			Pedido: order,
		});
		const options = {
			method: "POST",
			headers: headers,
			body: body,
		};
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/TabsPedidos", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				const orderTabs = createOrderTabs(json.Pedidos);
				dispatch(setTabsOrders({ tabsOrders: orderTabs }));
				dispatch(setActiveTab({ activeTab: 0 }));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};
export const runSelectedTabActions = (activeTab) => async (
	dispatch,
	getState
) => {
	const theState = getState();
	const { tabList } = theState.tabs;
	const m = MAIN_TABS;
	const p = PAYMENT_METHODS;
	switch (tabList[activeTab].tab) {
		case m.CreditOrders:
			dispatch(fetchCreditOrders(p.CREDITCARD));
			dispatch(fetchCreditOrders(p.PAYPAL));
			dispatch(fetchCreditOrders(p.SAFETYPAY));
			dispatch(fetchCreditOrders(p.PAYNET));
			break;
		case m.Inventory:
			dispatch(fetchInventory());
			break;
		case m.Software:
			dispatch(fetchSoftware());
			break;
		case m.ProductsBlocked:
			dispatch(fetchBlockedProducts());
			break;
		case m.ContiPartners:
			dispatch(fetchContiPartners());
			break;
		case m.Messages:
			dispatch(fetchContactMessages());
			break;
		case m.ContactMessages:
			dispatch(fetchSupportMessages());
			break;
		case m.Users:
			dispatch(fetchUsers());
			break;
		default:
			break;
	}
};
export default tabs.reducer;
