import {
	fetchSalesOrderCancel,
	setSalesOrderCancelErrorMsg,
} from "features/sales/salesOrderCancelSlice";
import { connect } from "react-redux";
import { validateName } from "features/functions";
import SalesOrder from "./SalesOrder";
import { USER_PROFILE } from "features/constants";

const mapStateToProps = (state) => {
	const { tabList, activeTab } = state.tabs;
	const { user } = state.session;
	if (user.user != null && user.user.Profile === USER_PROFILE.ContactCenter) {
		const { order, isLoading, errorMsg } = state.support;
		return {
			activeTabItem: tabList != null ? tabList[activeTab] : null,
			order,
			isLoading,
			isLoadingCancel: isLoading,
			errorMsg,
		};
	}
	const { order, isLoadingOrder } = state.salesOrders;
	const { isLoading, errorMsg } = state.salesOrderCancel;
	return {
		activeTabItem: tabList != null ? tabList[activeTab] : null,
		order,
		isLoading: isLoadingOrder,
		isLoadingCancel: isLoading,
		errorMsg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetErrorMsg: (errorMsg) => {
			dispatch(setSalesOrderCancelErrorMsg({ errorMsg }));
		},
		onCancel: (order, reason) => {
			let valid = true;
			let errorMsg = {};
			if (reason.trim() === "") {
				errorMsg = {
					...errorMsg,
					reason: "Debe escribir el motivo por el cuál se cancela el pedido.",
				};
				valid = false;
			} else {
				if (!validateName(reason.trim())) {
					errorMsg = {
						...errorMsg,
						reason: "El motivo debe ser un texto válido.",
					};
					valid = false;
				}
			}
			if (valid) {
				dispatch(fetchSalesOrderCancel(order, reason));
			} else {
				dispatch(setSalesOrderCancelErrorMsg({ errorMsg }));
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrder);
