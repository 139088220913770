import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL, PAYMENT_METHODS } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { getNameFromDate } from "features/functions";
import { fetchCreditOrders } from "./creditOrdersSlice";

const creditOrdersRelease = createSlice({
	name: "creditOrdersRelease",
	initialState: {
		isLoading: false,
	},
	reducers: {
		requestCreditOrdersRelease(state) {
			state.isLoading = true;
		},
		receiveCreditOrdersRelease(state) {
			state.isLoading = false;
		},
	},
});

export const {
	requestCreditOrdersRelease,
	receiveCreditOrdersRelease,
} = creditOrdersRelease.actions;

export const fetchCreditOrdersRelease = (data, type) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const p = PAYMENT_METHODS;
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				Pedidos: data,
				MetodoPago: type,
			}),
		};
		const dateString = getNameFromDate(new Date());
		dispatch(requestCreditOrdersRelease());
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/PedidosCredito/Libera", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.text();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((text) => {
				const link = document.createElement("a");
				const url =
					"data:application/octec-stream;charset=windows-1252;base64," + text;
				link.href = url;
				link.download = "libera-" + dateString + "-" + p.properties[type].shortname + ".csv";
				link.click();
				dispatch(receiveCreditOrdersRelease());
				dispatch(fetchCreditOrders(type));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};

export default creditOrdersRelease.reducer;
