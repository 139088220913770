import React from "react";
import { format, parseISO } from "date-fns";

export default class HrDeliveryOrder extends React.Component {
	handleClick = () => {
		this.props.onClick(this.props.order);
	};
	handleCancel = () => {
		this.props.onCancel();
	}
	render = () => {
		// updateLocale();
		const { order } = this.props;
		const articles = order.Unidades === 1 ? " Artículo" : " Artículos";
		if (order != null) {
			return (
				<div className="order">
					<div className="order-info">
						<div className="order-number">
							<div>Número de pedido</div>
							<div>{order.Pedido}</div>
						</div>
						<div className="order-data">
							<div>
								<div>Fecha</div>
								<div>{format(parseISO(order.Fecha), "dd/MMM/yyyy")}</div>
								<div>Empleado</div>
								<div>{order.NombreCompleto}</div>
							</div>
							<div>
								<div>Método de Envío</div>
								<div>{order.TipoEnvio}</div>
								<div>Correo</div>
								<div>{order.Correo}</div>
							</div>
							<div>
								<div>Estatus</div>
								<div>{order.Estatus}</div>
								<div>Teléfono</div>
								<div>{order.Telefono}</div>
							</div>
						</div>
					</div>
					<div className="order-items">{order.Unidades + articles}</div>
					<div className="order-details">
						<div className="details-header">
							<div className="center">Cant.</div>
							<div className="center">Código</div>
							<div>Producto</div>
							<div>Medida</div>
						</div>
						{order.Detalles.map((el, i) => {
							return (
								<div className="details" key={i}>
									<div className="center">{el.Cantidad}</div>
									<div className="center">{el.Codigo}</div>
									<div>{el.Marca + " " + el.Producto}</div>
									<div>{el.Medida}</div>
								</div>
							);
						})}
					</div>
					<div className="form-submit">
						<div>
							<div className="button" onClick={this.handleCancel}>Cancelar</div>
						</div>
						<div>
							<div className="button main" onClick={this.handleClick}>
								Entregar e Imprimir Formato
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
}
