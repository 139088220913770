import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL, PAYMENT_METHODS } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const creditOrders = createSlice({
	name: "creditOrders",
	initialState: {
		type: PAYMENT_METHODS.CREDITCARD,
		isLoadingCC: false,
		isLoadingPP: false,
		isLoadingPN: false,
		isLoadingSP: false,
		ccList: [],
		ppList: [],
		pnList: [],
		spList: [],
	},
	reducers: {
		setCreditOrdersType(state, action) {
			state.type = action.payload.type;
		},
		requestCreditOrdersCC(state) {
			state.isLoadingCC = true;
		},
		receiveCreditOrdersCC(state, action) {
			state.isLoadingCC = false;
			state.ccList = action.payload.ccList;
		},
		requestCreditOrdersPP(state) {
			state.isLoadingPP = true;
		},
		receiveCreditOrdersPP(state, action) {
			state.isLoadingPP = false;
			state.ppList = action.payload.ppList;
		},
		requestCreditOrdersPN(state) {
			state.isLoadingPN = true;
		},
		receiveCreditOrdersPN(state, action) {
			state.isLoadingPN = false;
			state.pnList = action.payload.pnList;
		},
		requestCreditOrdersSP(state) {
			state.isLoadingSP = true;
		},
		receiveCreditOrdersSP(state, action) {
			state.isLoadingSP = false;
			state.spList = action.payload.spList;
		},
		clearCreditOrders(state) {
			state.type = PAYMENT_METHODS.CREDITCARD;
			state.isLoadingCC = false;
			state.isLoadingPP = false;
			state.isLoadingPN = false;
			state.isLoadingSP = false;
			state.ccList = [];
			state.ppList = [];
			state.pnList = [];
			state.spList = [];
		},
	},
});

export const {
	setCreditOrdersType,
	requestCreditOrdersCC,
	receiveCreditOrdersCC,
	requestCreditOrdersPP,
	receiveCreditOrdersPP,
	requestCreditOrdersPN,
	receiveCreditOrdersPN,
	requestCreditOrdersSP,
	receiveCreditOrdersSP,
	clearCreditOrders,
} = creditOrders.actions;

export const fetchCreditOrders = (type) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const p = PAYMENT_METHODS;
		let headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		let options = {
			method: "GET",
			headers: headers,
		};
		switch (type) {
			case p.CREDITCARD:
				dispatch(requestCreditOrdersCC());
				break;
			case p.PAYPAL:
				dispatch(requestCreditOrdersPP());
				break;
			case p.PAYNET:
				dispatch(requestCreditOrdersPN());
				break;
			case p.SAFETYPAY:
				dispatch(requestCreditOrdersSP());
				break;
			default:
				break;
		}
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/PedidosCredito/?type="+type, options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				switch (type) {
					case p.CREDITCARD:
						dispatch(receiveCreditOrdersCC({ ccList: json }));
						break;
					case p.PAYPAL:
						dispatch(receiveCreditOrdersPP({ ppList: json }));
						break;
					case p.PAYNET:
						dispatch(receiveCreditOrdersPN({ pnList: json }));
						break;
					case p.SAFETYPAY:
						dispatch(receiveCreditOrdersSP({ spList: json }));
						break;
					default:
						break;
				}
			})
			.catch((error) => {
				switch (type) {
					case p.CREDITCARD:
						dispatch(receiveCreditOrdersCC({ ccList: [] }));
						break;
					case p.PAYPAL:
						dispatch(receiveCreditOrdersPP({ ppList: [] }));
						break;
					case p.PAYNET:
						dispatch(receiveCreditOrdersPN({ pnList: [] }));
						break;
					case p.SAFETYPAY:
						dispatch(receiveCreditOrdersSP({ spList: [] }));
						break;
					default: break;
				}
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};

export default creditOrders.reducer;
