import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { receiveSalesOrder } from "./salesOrdersSlice";

const salesOrderCancel = createSlice({
	name: "salesOrderCancel",
	initialState: {
		isLoading: false,
		errorMsg: null,
	},
	reducers: {
		requestSalesOrderCancel(state) {
			state.isLoading = true;
		},
		receiveSalesOrderCancel(state) {
			state.isLoading = false;
		},
		setSalesOrderCancelErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearSalesOrderCancel(state) {
			state.isLoading = false;
			state.errorMsg = null;
		},
	},
});

export const {
	requestSalesOrderCancel,
	receiveSalesOrderCancel,
	setSalesOrderCancelErrorMsg,
	clearSalesOrderCancel,
} = salesOrderCancel.actions;

export const fetchSalesOrderCancel = (order, reason) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const body = JSON.stringify({
			Pedido: order,
			Motivo: reason,
		});
		const options = {
			method: "POST",
			headers: headers,
			body: body,
		};
		dispatch(requestSalesOrderCancel());
		let responseStatus = 0;
		return fetch(
			BASE_API_URL + "/api/Manager/Pedidos/Cancela/" + order,
			options
		)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				dispatch(receiveSalesOrderCancel());
				dispatch(receiveSalesOrder({ order: json }));
			})
			.catch((error) => {
				dispatch(receiveSalesOrderCancel());
				// If 400 Bad Request
				if (responseStatus === 400) {
					const json = JSON.parse(error);
					dispatch(
						setSalesOrderCancelErrorMsg({ errorMsg: { error: json.Message } })
					);
				}
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
			});
	});
};

export default salesOrderCancel.reducer;
