import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addMonths } from "date-fns";
import { getUsers } from "services/usersService";

const initialState = {
	page: 0,
	rows: 50,
	type: 0,
	active: null,
	subscribed: null,
	gender: null,
	email: null,
	name: null,
	lastName: null,
	familyName: null,
	from: addMonths(new Date(), -3),
	until: new Date(),
	partnerId: null,
	isLoading: false,
	list: null,
	total: 0,
};

export const fetchUsers = createAsyncThunk(
	"fetchUsers",
	async (_, { getState }) => {
		const state = getState();
		const {
			page,
			rows,
			type,
			active,
			subscribed,
			gender,
			email,
			name,
			lastName,
			familyName,
			from,
			until,
			partnerId,
		} = state.users;
		const body = {
			page,
			rows,
			type,
			active,
			subscribed,
			gender,
			email,
			name,
			lastName,
			familyName,
			from: from.toISOString(),
			until: until.toISOString(),
			partnerId,
		};
		const response = await getUsers(body);
		return response;
	}
);

const users = createSlice({
	name: "users",
	initialState,
	reducers: {
		setUsersPage(state, { payload }) {
			state.page = payload;
		},
		setUsersRows(state, action) {
			state.rows = action.payload.rows;
		},
		setUsersType(state, { payload }) {
			state.type = payload ?? 0;
			state.page = 0;
		},
		setUsersActive(state, { payload }) {
			state.active = payload ?? null;
			state.page = 0;
		},
		setUsersSubscribed(state, { payload }) {
			state.subscribed = payload ?? null;
			state.page = 0;
		},
		setUsersGender(state, { payload }) {
			state.gender = payload ?? null;
			state.page = 0;
		},
		setUsersEmail(state, { payload }) {
			state.email = payload ?? null;
			state.page = 0;
		},
		setUsersName(state, { payload }) {
			state.name = payload ?? null;
			state.page = 0;
		},
		setUsersLastName(state, { payload }) {
			state.lastName = payload ?? null;
			state.page = 0;
		},
		setUsersFamilyName(state, { payload }) {
			state.familyName = payload ?? null;
			state.page = 0;
		},
		setUsersFrom(state, { payload }) {
			state.from = payload ?? null;
			state.page = 0;
		},
		setUsersUntil(state, { payload }) {
			state.until = payload ?? null;
			state.page = 0;
		},
		setUsersPartnerId(state, { payload }) {
			state.partnerId = payload ?? null;
			state.page = 0;
		},
		setUsersFilters(state, { payload }) {
			state.page = payload.page ?? 0;
			state.rows = payload.rows ?? 50;
			state.type = payload.type ?? 0;
			state.active = payload.active ?? null;
			state.subscribed = payload.subscribed ?? null;
			state.gender = payload.gender ?? null;
			state.email = payload.email ?? null;
			state.name = payload.name ?? null;
			state.lastName = payload.lastName ?? null;
			state.familyName = payload.familyName ?? null;
			state.partnerId = payload.partnerId ?? null;
		},
		clearUsers: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// Users List
			.addCase(fetchUsers.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchUsers.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.users ?? [];
				state.total = payload.data.total ?? 0;
			})
			.addCase(fetchUsers.rejected, (state) => {
				state.isLoading = false;
				state.list = [];
			});
	},
});

export const {
	setUsersPage,
	setUsersRows,
	setUsersType,
	setUsersActive,
	setUsersSubscribed,
	setUsersGender,
	setUsersEmail,
	setUsersName,
	setUsersLastName,
	setUsersFamilyName,
	setUsersFrom,
	setUsersUntil,
	setUsersPartnerId,
	setUsersFilters,
	clearUsers,
} = users.actions;

export default users.reducer;
