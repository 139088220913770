import {
	setBlockedProductItem,
	toggleBlockedProductModal,
} from "features/products/blockedProductsSlice";
import { connect } from "react-redux";
import BlockedProducts from "./BlockedProducts";

const mapStateToProps = (state) => {
	const { isLoading, list } = state.blockedProducts;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (selectedItem) => {
			dispatch(setBlockedProductItem({ selectedItem }));
			dispatch(toggleBlockedProductModal({ isOpen: false }));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedProducts);
