import React from "react";
import Loader from "../../loader/Loader";
import DealerOrdersItem from "./DealerOrdersItem";
import { MAIN_TABS } from "features/constants";

export default class DealerOrders extends React.Component {
	handleDownloadClick = () => {
		this.props.onDownload();
	};
	renderOrders = () => {
		const { isLoading, isLoadingDownload, list } = this.props;
		if (!isLoading && !isLoadingDownload && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						<thead>
							<tr>
								<th>
									Pedido
									<span className="icon-sort-inverse"></span>
								</th>
								<th>Fecha</th>
								<th>Tipo</th>
								<th>Estatus</th>
								<th>Método Envío</th>
								<th>Sucursal</th>
								<th>Correo</th>
								<th>Nombre</th>
								<th>Ciudad</th>
								<th>Estado</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, i) => {
								return <DealerOrdersItem key={i} index={i} item={el} />;
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="10">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					<thead>
						<tr>
							<th>
								Pedido
								<span className="icon-sort-inverse"></span>
							</th>
							<th>Fecha</th>
							<th>Tipo</th>
							<th>Estatus</th>
							<th>Método Envío</th>
							<th>Sucursal</th>
							<th>Correo</th>
							<th>Nombre</th>
							<th>Ciudad</th>
							<th>Estado</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan="10">No se encontraron pedidos.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="10">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	renderLoader = () => {
		const { isLoading, isLoadingDownload } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando datos..." />;
		}
		if (isLoadingDownload) {
			return <Loader msg="Preparando descarga..." />;
		}
		return null;
	};
	renderDownload = () => {
		const { isLoading, isLoadingDownload, list } = this.props;
		if (!isLoading && !isLoadingDownload && list != null && list.length > 0) {
			return (
				<div className="form-download">
					<div>
						<div className="button main" onClick={this.handleDownloadClick}>
							Descargar
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	renderOrdersTitle = () => {
		const { isLoading, isLoadingDownload, list } = this.props;
		const orderCount = list != null ? list.length : 0;
		const orderString = orderCount === 1 ? "Pedido" : "Pedidos";
		if (!isLoading && !isLoadingDownload) {
			return (
				<div className="orders-table-title">
					<div>
						<span>{orderCount}</span> {orderString}
					</div>
				</div>
			);
		}
		return null;
	};
	render = () => {
		const { activeTabItem } = this.props;
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.DealerOrders) {
			return (
				<section className="delivery">
					<div>
						<h1>Administración de Pedidos</h1>
						<p>
							A continuación la lista de pedidos liberados por Continental para
							ser atendidos:
						</p>
					</div>
					<div className="orders-table">
						{this.renderOrdersTitle()}
						<div className="orders-table-content">
							{this.renderLoader()}
							{this.renderOrders()}
						</div>
					</div>
					{this.renderDownload()}
				</section>
			);
		}
		return null;
	};
}
