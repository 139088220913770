import React from "react";
import { format, parseISO } from "date-fns";

export default class DealerDeliveryOrder extends React.Component {
	render = () => {
		const { order } = this.props;
		const articles = order.Unidades === 1 ? " Artículo" : " Artículos" 
		if (order != null) {
			return (
				<div className="order">
					<div className="order-info">
						<div className="order-number">
							<div>Número de pedido</div>
							<div>{order.Pedido}</div>
						</div>
						<div className="order-data">
							<div>
								<div>Fecha</div>
								<div>{format(parseISO(order.Fecha), "dd/MMM/yyyy")}</div>
								<div>Tipo de Usuario</div>
								<div>{order.Tipo}</div>
							</div>
							<div>
								<div>Método de Envío</div>
								<div>{order.MetodoEnvio}</div>
								<div>Nombre</div>
								<div>{order.NombreCompleto}</div>
							</div>
							<div>
								<div>Estatus</div>
								<div>{order.Estatus}</div>
								<div>Correo</div>
								<div>{order.Correo}</div>
							</div>
							<div>
								<div>Pedido SAP</div>
								<div>{order.PedidoSAP}</div>
								<div>Teléfono</div>
								<div>{order.Telefono}</div>
							</div>
							<div>
								<div>Delivery</div>
								<div>{order.Delivery}</div>
								<div>&nbsp;</div>
								<div>&nbsp;</div>
							</div>
						</div>
					</div>
					<div className="order-items">{order.Unidades + articles}</div>
					<div className="order-details">
						<div className="details-header">
							<div className="center">Cant.</div>
							<div className="center">Código</div>
							<div>Producto</div>
							<div>Medida</div>
						</div>
						{order.Detalles.map((el, i) => {
							return (
								<div className="details" key={i}>
									<div className="center">{el.Cantidad}</div>
									<div className="center">{el.Codigo}</div>
									<div>{el.Marca + " " + el.Producto}</div>
									<div>{el.Medida}</div>
								</div>
							)
						})}
					</div>
				</div>
			);
		}
		return null;
	};
}
