import React from 'react'

export default class Footer extends React.Component {
    render = () => {
        const year = new Date().getFullYear()
        return (
            <footer>
                <div>&copy;{year} Continental Tire de México</div>
            </footer>
        )
    }
}