import { connect } from "react-redux";
import {
	clearUsers,
	fetchUsers,
	// setUsersFilters,
	setUsersFrom,
	setUsersPage,
	setUsersType,
	setUsersUntil,
} from "features/users/usersSlice";
import Users from "./Users";

const mapStateToProps = (state) => {
	const {
		page,
		rows,
		type,
		active,
		subscribed,
		gender,
		email,
		name,
		lastName,
		familyName,
		from,
		until,
		partnerId,
		list,
		total,
		isLoading,
	} = state.users;
	const { tabList, activeTab } = state.tabs;
	return {
		page,
		rows,
		type,
		active,
		subscribed,
		gender,
		email,
		name,
		lastName,
		familyName,
		from,
		until,
		partnerId,
		list,
		total,
		isLoading,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onTypeChange: (type) => {
			dispatch(setUsersType(type));
			dispatch(fetchUsers());
		},
		onFromChange: (from) => {
			dispatch(setUsersFrom(from));
			dispatch(fetchUsers());
		},
		onUntilChange: (until) => {
			dispatch(setUsersUntil(until));
			dispatch(fetchUsers());
		},
		onClearFilters: () => {
			dispatch(clearUsers());
			dispatch(fetchUsers());
		},
		onPage: (page) => {
			dispatch(setUsersPage(page));
			dispatch(fetchUsers());
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
