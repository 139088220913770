import React from "react";
import Loader from "../loader/Loader";

export default class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
		};
		this.passwordRef = React.createRef();
	}
	// Validar si se debe limpiar la el formulario
	static getDerivedStateFromProps(props, state) {
		if (props.resetForm === true && state.username !== "") {
			props.onLoad();
			return {
				username: "",
				password: "",
			};
		}
		return null;
	}
	handleSubmit = (e) => {
		const { errorMsg } = this.props;
		e.preventDefault();
		let errorMessages = { ...errorMsg };
		if (this.state.username.trim() === "") {
			errorMessages = {
				...errorMessages,
				username: "Debe escribir su nombre de usuario",
			};
		}
		if (this.state.password.trim() === "") {
			errorMessages = {
				...errorMessages,
				password: "Debe escribir su contraseña",
			};
		}
		if (
			this.state.username.trim() !== "" &&
			this.state.password.trim() !== ""
		) {
			this.props.onClick(this.state);
		} else {
			this.props.onSetError(errorMessages);
		}
	};
	handleUsernameChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.username != null) {
			this.props.onSetError({ ...errorMsg, username: null });
		}
		if (errorMsg != null && errorMsg.error != null) {
			this.props.onClearError();
		}
		this.setState({
			username: e.target.value,
		});
	};
	handlePasswordChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.password != null) {
			this.props.onSetError({ ...errorMsg, password: null });
		}
		if (errorMsg != null && errorMsg.error != null) {
			this.props.onClearError();
		}
		this.setState({
			password: e.target.value,
		});
	};
	handleUserKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			this.passwordRef.current.focus();
		}
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Iniciando sesión..." />;
		}
	};
	renderError = (error) => {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	};
	renderErrorList = () => {
		const { errorMsg } = this.props;
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{this.renderError(errorMsg.error)}
						{this.renderError(errorMsg.username)}
						{this.renderError(errorMsg.password)}
					</ul>
				</div>
			);
		}
		return null;
	};
	renderForm = () => {
		const { isLoading, errorMsg, authenticated } = this.props;
		const { username, password } = this.state;
		let fieldClass = { username: "form-a", password: "form-a" };
		if (errorMsg != null) {
			if (errorMsg.error != null) {
				fieldClass = {
					...fieldClass,
					username: "form-a error",
					password: "form-a error",
				};
			}
			if (errorMsg.username != null) {
				fieldClass = { ...fieldClass, username: "form-a error" };
			}
			if (errorMsg.password != null) {
				fieldClass = { ...fieldClass, password: "form-a error" };
			}
		}
		if (!isLoading && !authenticated) {
			return (
				<form onSubmit={this.handleSubmit}>
					<div
						id="login-content"
						style={{ backgroundImage: "url('/images/login-bg.jpg')" }}
					>
						<div>
							<h1>ContiShop Manager</h1>
							<p>Bienvenido al Administrador de pedidos de ContiShop</p>
							<div className={fieldClass.username}>
								<label>Usuario</label>
								<input
									type="text"
									value={username}
									onChange={this.handleUsernameChange}
									onKeyPress={this.handleUserKeyPress}
									autoComplete="username"
									placeholder="Usuario"
								/>
							</div>
							<div className={fieldClass.password}>
								<label>Contraseña</label>
								<input
									type="password"
									value={password}
									onChange={this.handlePasswordChange}
									autoComplete="current-password"
									placeholder="Contraseña"
									ref={this.passwordRef}
								/>
							</div>
							{this.renderErrorList()}
							<div>
								<button type="submit" className="button main">
									Iniciar Sesión
								</button>
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	};
	render = () => {
		const { authenticated } = this.props;
		if (!authenticated) {
			return (
				<div className="login">
					<div>
						<div id="logo-conti-top">
							<span></span>
						</div>
					</div>
					{this.renderForm()}
					{this.renderLoader()}
					<div id="outer-frame">
						<div id="main-container"></div>
					</div>
				</div>
			);
		}
		return null;
	};
}
