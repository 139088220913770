import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const dealerOrders = createSlice({
	name: "dealerOrders",
	initialState: {
		isLoading: false,
		list: null,
	},
	reducers: {
		requestDealerOrders(state, action) {
			state.isLoading = true;
		},
		receiveDealerOrders(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearDealersOrders(state, action) {
			state.list = null;
		},
	},
});

export const {
	requestDealerOrders,
	receiveDealerOrders,
	clearDealersOrders,
} = dealerOrders.actions;

export const fetchDealerOrders = () => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		let headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		let options = {
			method: "GET",
			headers: headers,
		};
		dispatch(requestDealerOrders());
		let responseStatus = 0;
		return fetch(BASE_API_URL + "/api/Manager/PedidosDistribuidor", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("El usuario o la contraseña son incorrectos.");
				}
			})
			.then((json) => {
				dispatch(receiveDealerOrders({ list: json }));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};

export default dealerOrders.reducer;
