import { USER_PROFILE } from "features/constants";
import React from "react";
import Autosuggest from "react-autosuggest";

var locationList = [];

const getSuggestions = (value) => {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;
	if (inputLength < 3) return [];
	return inputLength === 0
		? []
		: locationList.filter(
				(element) =>
					element.Nombre.toLowerCase().slice(0, inputLength) === inputValue
		  );
};
const getSuggestionValue = (suggestion) => suggestion.Nombre;

const renderSuggestion = (suggestion) => <div>{suggestion.Nombre}</div>;
export default class DealerDeliveryForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			location: "",
			selectedLocation: null,
			idText: "",
			suggestions: [],
			profile: USER_PROFILE.SocioSucursal,
		};
	}
	// Validar el tipo de usuario
	static getDerivedStateFromProps(props, state) {
		if (props.user.user != null && props.user.user.Profile !== state.profile) {
			return {
				profile: props.user.user.Profile,
			};
		}
		return null;
	}
	handleClick = () => {
		this.props.onClick(this.props.index);
	};
	handleDownloadClick = () => {
		this.props.onDownload(this.state);
	};
	handleCancelClick = () => {
		this.setState({
			name: "",
			employee: "",
			location: "",
			selectedLocation: null,
			idText: "",
		});
		this.props.onCancel();
	};
	handleNameChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.name != null) {
			this.props.onSetErrorMsg({ ...errorMsg, name: null, error: null });
		}
		this.setState({
			name: e.target.value,
		});
	};
	clearError = () => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.error != null) {
			this.props.onSetErrorMsg({ ...errorMsg, error: null });
		}
	};
	handleIdTextChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.idText != null) {
			this.props.onSetErrorMsg({ ...errorMsg, idText: null });
		}
		this.clearError();
		this.setState({
			idText: e.target.value,
		});
	};
	handleLocationChange = (e, { newValue }) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.location != null) {
			this.props.onSetErrorMsg({ ...errorMsg, location: null });
		}
		this.clearError();
		this.setState({
			location: newValue,
			selectedLocation: null,
		});
	};
	onSuggestionSelected = (event, { suggestion }) => {
		this.setState({ selectedLocation: suggestion });
	};
	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: getSuggestions(value),
		});
	};
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};
	renderError = (error) => {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	};
	renderErrorList = () => {
		const { errorMsg } = this.props;
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{this.renderError(errorMsg.error)}
						{this.renderError(errorMsg.name)}
						{this.renderError(errorMsg.idText)}
						{this.renderError(errorMsg.location)}
					</ul>
				</div>
			);
		}
	};
	renderDropdown = (fieldClass) => {
		const { list, user } = this.props;
		const { location, suggestions } = this.state;
		const inputProps = {
			placeholder: "Sucursal",
			value: location,
			autoComplete: "off",
			onChange: this.handleLocationChange,
		};
		locationList = list;
		if (user.user != null && user.user.Profile === USER_PROFILE.Socio) {
			return (
				<div {...fieldClass}>
					<label htmlFor="sucursal">Sucursal</label>
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
						onSuggestionsClearRequested={this.onSuggestionsClearRequested}
						getSuggestionValue={getSuggestionValue}
						onSuggestionSelected={this.onSuggestionSelected}
						renderSuggestion={renderSuggestion}
						highlightFirstSuggestion
						inputProps={inputProps}
					/>
				</div>
			);
		}
		return null;
	};
	render = () => {
		const { errorMsg } = this.props;
		let fieldClass = {
			name: {},
			employee: {},
			idText: {},
			location: { className: "autosuggest-field" },
		};
		if (errorMsg != null) {
			if (errorMsg.name != null) {
				fieldClass = { ...fieldClass, name: { className: "field-error" } };
			}
			if (errorMsg.employee != null) {
				fieldClass = { ...fieldClass, employee: { className: "field-error" } };
			}
			if (errorMsg.idText != null) {
				fieldClass = { ...fieldClass, idText: { className: "field-error" } };
			}
			if (errorMsg.location != null) {
				fieldClass = {
					...fieldClass,
					location: { className: "autosuggest-field field-error" },
				};
			}
		}
		return (
			<div className="delivery-form">
				<div className="form-header">
					<div>
						<div className="form-title">Formulario de Entrega</div>
						<div className="form-close" onClick={this.handleCancelClick}>
							<span className="icon-close"></span>
						</div>
					</div>
				</div>
				<div className="form-content">
					<div className="form-a full">
						<div>
							<div {...fieldClass.name}>
								<label htmlFor="name">Recibe</label>
								<input
									id="name"
									type="text"
									placeholder="Nombre completo"
									autoComplete="off"
									onChange={this.handleNameChange}
									value={this.state.name}
								></input>
							</div>
						</div>
					</div>
					<div className="form-a three">
						<div>
							<div {...fieldClass.idText}>
								<label htmlFor="identificacion">Identificación</label>
								<input
									id="identificacion"
									type="text"
									placeholder="Identificación"
									autoComplete="off"
									onChange={this.handleIdTextChange}
									value={this.state.idText}
								></input>
							</div>
							{this.renderDropdown(fieldClass.location)}
						</div>
					</div>
					{this.renderErrorList()}
					<div className="form-submit">
						<div>
							<div className="button" onClick={this.handleCancelClick}>
								Cancelar
							</div>
						</div>
						<div>
							<div className="button main" onClick={this.handleDownloadClick}>
								Entregar e Imprimir Formato
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}
