import React from "react";
import LoginForm from "./login/LoginFormContainer";
import Manager from "./manager/ManagerContainer";

export default class App extends React.Component {
	componentDidMount = () => {
		this.props.onLoad();
	};
	render = () => {
		return (
			<div>
				<Manager />
				<LoginForm />
			</div>
		);
	};
}
