import { createSlice } from '@reduxjs/toolkit'
import { sessionService } from 'redux-react-session'
import { BASE_API_URL } from '../constants'
import {runUnauthorizedActions} from '../login/loginSlice'
import { fetchDealerOrders } from './dealerOrdersSlice'
import { getNameFromDate } from 'features/functions'

const dealerOrdersDownload = createSlice({
    name: "dealerOrdersDownload",
    initialState: {
        isLoading: false,
    },
    reducers: {
        requestDealerOrdersDownload(state) {
            state.isLoading = true
        },
        receiveDealerOrdersDownload(state) {
            state.isLoading = false
        },
    },
})

export const {
    requestDealerOrdersDownload,
    receiveDealerOrdersDownload,
} = dealerOrdersDownload.actions;

export const fetchDealerOrdersDownload = () => async (dispatch) => {
    sessionService.loadSession()
    .then(session => {
        let headers = {
            'Authorization': 'bearer ' + session.loginData.access_token,
            "Content-Type": "application/json",
        }
        let options = {
            method: "GET",
            headers: headers,
        }
        const dateString = getNameFromDate(new Date())
        dispatch(requestDealerOrdersDownload())
        let responseStatus = 0
        return fetch(BASE_API_URL + "/api/Manager/PedidosDistribuidor/Descarga", options)
        .then((response) => {
            responseStatus = response.status
            if (response.ok) {
                return response.text()
            } else {
                return Promise.reject("El usuario o la contraseña son incorrectos.")
            }
        })
        .then((text) => {
            const link = document.createElement('a')
            const url = "data:application/octec-stream;charset=utf-8;base64," + text
            link.href = url
            link.download = "ReporteContiShop-"+dateString+".zip"
            link.click()
            dispatch(receiveDealerOrdersDownload())
            dispatch(fetchDealerOrders())
        })
        .catch((error) => {
            // TODO: fetch error actions
            // If 401 Unauthorized login failed
            if (responseStatus === 401) {
                dispatch(runUnauthorizedActions())
            }
            // If 400 Bad Request
            // if (responseStatus === 400) {
            // }
        })
    })

}

export default dealerOrdersDownload.reducer
