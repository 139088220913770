import React from "react";
import Modal from "react-modal";
import Loader from "components/loader/Loader";

export default class AddPartnerCouponModal extends React.Component {
	handleClose = () => {
		const { selectedItem } = this.props;
		this.props.onCancel(selectedItem);
	};
	handleClick = () => {
		const { selectedItem, qty, name, errorMsg } = this.props;
		this.props.onClick(selectedItem, qty, name, errorMsg);
	};
	handleQtyFocus = (e) => {
		e.target.select();
	};
	handleQtyChange = (e) => {
		const qty = Number(e.target.value.replace(/[^0-9]/g, ""));
		this.props.onQtyChange(qty);
	};
	handleNameChange = (e) => {
		const name = e.target.value.toUpperCase().replace(/[^A-Z]/g, "");
		this.props.onNameChange(name);
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderQty = () => {
		const { qty, selectedItem } = this.props;
		return (
			<div>
				<div style={{ padding: "5px 0" }}>
					<p>
						Por favor llene el formulario para crear un nuevo cupón para{" "}
						<strong>{selectedItem.Alias}</strong>.
					</p>
					<p>
						Máximo permitido: <strong>30 piezas</strong>
					</p>
					<label>Cantidad: </label>
					<input
						type="text"
						placeholder="Cantidad"
						style={{
							textAlign: "center",
							maxWidth: "60px",
							minWidth: "60px",
							fontSize: "1.3em",
							fontStyle: "normal",
						}}
						value={qty}
						onChange={this.handleQtyChange}
						onFocus={this.handleQtyFocus}
					></input>
				</div>
			</div>
		);
	};
	renderName = () => {
		const { name } = this.props;
		return (
			<div>
				<div>
					<label>Localidad: </label>
					<input
						type="text"
						placeholder="Localidad"
						style={{
							textAlign: "left",
							fontStyle: "normal",
						}}
						value={name}
						onChange={this.handleNameChange}
					></input>
				</div>
			</div>
		);
	};
	renderSubmit = () => {
		const { qty, name } = this.props;
		if (qty > 0 && qty <= 30 && name.trim() !== "") {
			return (
				<div>
					<div className="button" onClick={this.handleClose}>
						Cancelar
					</div>
					<div className="button main" onClick={this.handleClick}>
						Guardar
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className="button" onClick={this.handleClose}>
					Cancelar
				</div>
				<div className="button disabled">Guardar</div>
			</div>
		);
	};
	renderForm = () => {
		const { isLoading } = this.props;
		if (!isLoading) {
			return (
				<div>
					<div className="form-a">{this.renderQty()}</div>
					<div className="form-a">{this.renderName()}</div>
					{this.renderSubmit()}
				</div>
			);
		}
	};
	render = () => {
		const customStyles = {
			content: {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)",
				minWidth: "550px",
				minHeight: "350px",
			},
			overlay: {
				backgroundColor: "rgb(0,0,0,.7)",
				zIndex: "2",
			},
		};
		const { isOpen } = this.props;
		if (isOpen) {
			return (
				<div>
					<Modal
						isOpen={isOpen}
						onRequestClose={this.handleClose}
						style={customStyles}
						contentLabel="NewContiPartnerCoupon"
					>
						<div className="conti-modal">
							<h2>Nuevo Cupón ContiPartners</h2>
							{this.renderForm()}
							{this.renderLoader()}
						</div>
					</Modal>
				</div>
			);
		}
		return null;
	};
}
