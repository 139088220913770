export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const TAB_TYPE = {
	Main: 1,
	Order: 2,
};
export const CLIENT_TYPE = {
	Consumer: 1,
	Employee: 2,
	FamilyFriend: 3,
	ContiPartner: 4,
	properties: {
		1: { id: 1, name: "Consumidor" },
		2: { id: 2, name: "Empleado" },
		3: { id: 3, name: "FamilyFriend" },
		4: { id: 4, name: "ContiPartner" },
	},
};
export const MAIN_TABS = {
	Orders: 1,
	OrderDelivery: 2,
	CreditOrders: 3,
	Users: 4,
	Messages: 5,
	Guide: 7,
	HrOrders: 8,
	HrOrderDelivery: 9,
	DealerOrders: 11,
	DealerOrderDelivery: 12,
	DealerLocationOrders: 13,
	Inventory: 14,
	ProductSearch: 15,
	ProductsBlocked: 16,
	ContiPartners: 20,
	Software: 40,
	ContactCenter: 50,
	ContactMessages: 51,
	properties: {
		1: { tab: 1, title: "Pedidos", type: TAB_TYPE.Main },
		2: { tab: 2, title: "Entrega de Pedidos", type: TAB_TYPE.Main },
		3: { tab: 3, title: "Liberación", type: TAB_TYPE.Main },
		4: { tab: 4, title: "Usuarios", type: TAB_TYPE.Main },
		5: { tab: 5, title: "Mensajes", type: TAB_TYPE.Main },
		8: { tab: 8, title: "Recepción de Llantas", type: TAB_TYPE.Main },
		7: { tab: 7, title: "Guiá de Aplicación", type: TAB_TYPE.Main },
		9: { tab: 9, title: "Entrega de Llantas", type: TAB_TYPE.Main },
		11: { tab: 11, title: "Pedidos", type: TAB_TYPE.Main },
		12: { tab: 12, title: "Entrega de Pedidos", type: TAB_TYPE.Main },
		13: { tab: 13, title: "Recepción de Llantas", type: TAB_TYPE.Main },
		14: { tab: 14, title: "Inventarios", type: TAB_TYPE.Main },
		15: { tab: 15, title: "Productos", type: TAB_TYPE.Main },
		16: { tab: 16, title: "Bloqueos", type: TAB_TYPE.Main },
		20: { tab: 20, title: "ContiPartners", type: TAB_TYPE.Main },
		40: { tab: 40, title: "Software", type: TAB_TYPE.Main },
		50: { tab: 50, title: "Buscador", type: TAB_TYPE.Main },
		51: { tab: 51, title: "Mensajes", type: TAB_TYPE.Main },
	},
};
export const RECEIVE_PRODUCT_STATUS = {
	Ok: 0,
	Wrong: 1,
	Damaged: 2,
	Incomplete: 3,
	Missing: 4,
	properties: {
		0: { value: 0, name: "Completo" },
		1: { value: 1, name: "Equivocado" },
		2: { value: 2, name: "Dañado" },
		3: { value: 3, name: "Incompleto" },
		4: { value: 4, name: "No llegó" },
	},
};
export const USER_PROFILE = {
	Webmaster: 50,
	Ventas: 100,
	VentasPartners: 110,
	ProductManager: 200,
	Credito: 300,
	Envios: 400,
	ContactCenter: 450,
	Inventarios: 500,
	GuiaAplicacion: 510,
	RecursosHumanos: 601,
	Localidad: 700,
	GerenteVentas: 801,
	TSM: 802,
	Socio: 900,
	SocioSucursal: 910,
	SucursalDistribuidor: 920,
	properties: {
		50: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.Orders],
				MAIN_TABS.properties[MAIN_TABS.CreditOrders],
				MAIN_TABS.properties[MAIN_TABS.Users],
				MAIN_TABS.properties[MAIN_TABS.Messages],
				MAIN_TABS.properties[MAIN_TABS.Inventory],
				MAIN_TABS.properties[MAIN_TABS.ProductSearch],
				MAIN_TABS.properties[MAIN_TABS.ProductsBlocked],
				MAIN_TABS.properties[MAIN_TABS.ContiPartners],
				MAIN_TABS.properties[MAIN_TABS.Software],
			],
		},
		100: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.Orders],
				MAIN_TABS.properties[MAIN_TABS.Users],
				MAIN_TABS.properties[MAIN_TABS.Messages],
				MAIN_TABS.properties[MAIN_TABS.Inventory],
				MAIN_TABS.properties[MAIN_TABS.ProductSearch],
				MAIN_TABS.properties[MAIN_TABS.ProductsBlocked],
				MAIN_TABS.properties[MAIN_TABS.ContiPartners],
			],
		},
		110: {
			tabs: [MAIN_TABS.properties[MAIN_TABS.ContiPartners]],
		},
		200: {},
		300: { tabs: [MAIN_TABS.properties[MAIN_TABS.CreditOrders]] },
		400: {},
		450: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.ContactCenter],
				MAIN_TABS.properties[MAIN_TABS.ContactMessages],
			],
		},
		500: {},
		510: {},
		601: {},
		700: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.HrOrders],
				MAIN_TABS.properties[MAIN_TABS.HrOrderDelivery],
			],
		},
		801: {},
		802: {},
		900: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.DealerOrders],
				MAIN_TABS.properties[MAIN_TABS.DealerOrderDelivery],
			],
		},
		910: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.DealerLocationOrders],
				MAIN_TABS.properties[MAIN_TABS.DealerOrderDelivery],
			],
		},
		920: {
			tabs: [
				MAIN_TABS.properties[MAIN_TABS.DealerLocationOrders],
				MAIN_TABS.properties[MAIN_TABS.DealerOrderDelivery],
			],
		},
	},
};
export const PAYMENT_METHODS = {
	CREDITCARD: 2,
	PAYPAL: 3,
	SAFETYPAY: 5,
	PAYNET: 6,
	CREDITLINE: 7,
	properties: {
		2: {
			name: "Tarjeta de Crédito",
			shortname: "TarjetaCrédito",
			fullname: "Tarjeta de Crédito",
			imageUrl: "/images/cart/logo-tc-visa-mastercard.png",
			disabledImageUrl: "/images/cart/logo-tc-visa-mastercard_disabled.png",
		},
		3: {
			name: "PayPal",
			shortname: "PayPal",
			fullname: "PayPal Pagos Seguros",
			imageUrl: "/images/cart/logo-paypal.png",
			disabledImageUrl: "/images/cart/logo-paypal_disabled.png",
		},
		5: {
			name: "SafetyPay",
			shortname: "SafetyPay",
			fullname: "SafetyPay Pagos Seguros",
			imageUrl: "/images/cart/logo-safetypay.png",
			disabledImageUrl: "/images/cart/logo-safetypay_disabled.png",
		},
		6: {
			name: "Pago en Establecimientos",
			shortname: "Paynet",
			fullname: "Pago en Establecimientos",
			imageUrl: "/images/cart/logo-paynet-openpay.png",
			disabledImageUrl: "/images/cart/logo-paynet-openpay_disabled.png",
		},
		7: {
			name: "Línea de Crédito",
			shortname: "LineaCredito",
			fullname: "Línea de Crédito",
			imageUrl: "/images/cart/logo-conti-credito.png",
			disabledImageUrl: "/images/cart/logo-conti-credito_disabled.png",
		},
	},
};
export const WAREHOUSE = {
	MXTX: 1,
	MXCO: 3,
	properties: {
		1: { name: "MXTX", value: 1 },
		3: { name: "MXCO", value: 3 },
	},
};

export const CONTACT_MESSAGE_TYPE = {
	CustomerService: 1,
	Warranty: 2,
	properties: {
		1: { name: "Atención a Clientes", value: 1 },
		2: { name: "Garantías", value: 2 },
	},
};

export const CONTACT_MESSAGE_CATEGORY = {
	Uncategorized: 1,
	Purchase: 2,
	Order: 3,
	Complaint: 4,
	Product: 5,
	Warranty: 6,
	Other: 7,
	Return: 8,
	properties: {
		1: { name: "Sin Categoría", value: 1 },
		2: { name: "Proceso de Compra", value: 2 },
		3: { name: "Pedidos", value: 3 },
		4: { name: "Quejas", value: 4 },
		5: { name: "Información de Productos", value: 5 },
		6: { name: "Garantías", value: 6 },
		7: { name: "Otro", value: 7 },
		8: { name: "Devolución de Productos", value: 8},
	},
};

export const CONTACT_MESSAGE_STATUS = {
	Pending: 1,
	WaitingReply: 2,
	Closed: 3,
	ClosedUnanswered: 4,
	properties: {
		1: { name: "Sin Atender", value: 1 },
		2: { name: "Esperando Respuesta", value: 2 },
		3: { name: "Atendido", value: 3 },
		4: { name: "Cerrado sin Respuesta", value: 4 },
	},
};
