import { connect } from "react-redux";
import DealerOrders from "./DealerOrders";
import { fetchDealerOrders } from "features/dealer/dealerOrdersSlice";
import { fetchDealerOrdersDownload,
	requestDealerOrdersDownload } from "features/dealer/dealerOrdersDownloadSlice";

const mapStateToProps = (state) => {
	const { isLoading, list } = state.dealerOrders;
	const isLoadingDownload = state.dealerOrdersDownload.isLoading;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		isLoadingDownload,
		list,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLoad: () => {
			dispatch(fetchDealerOrders());
		},
		onDownload: () => {
			dispatch(requestDealerOrdersDownload())
			dispatch(fetchDealerOrdersDownload());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerOrders);
