import React from 'react'

export default class MainMenu extends React.Component {
	constructor(props, context) {
    	super(props, context)

	    this.state = {
	    	dropdownClass: 'dropdown-menu',
	    	dropdownIsActive: false,
	    }
	}
	handleBlur = (e) => {
		this.setState({
			dropdownClass: 'dropdown-menu',
			dropdownIsActive: false,
		})
	}
	handleMouseDown = (e) => {
		if (this.state.dropdownIsActive) {
			e.preventDefault()
	    	e.stopPropagation()
			return
		}
	}
	toggleShowMenu = () => {
		if (this.state.dropdownIsActive) {
			this.setState({
				dropdownIsActive: false,
				dropdownClass: 'dropdown-menu',
			})
		}
		else {
			this.setState({
				dropdownIsActive: true,
				dropdownClass: 'dropdown-menu show',
			})
		}
	}
	handleLogout = () => {
		this.toggleShowMenu()
		this.props.onLogout()
	}
	renderDropdown = () => {
		const {dropdownClass} = this.state
		return (
			<div className={dropdownClass}
				onMouseDown={this.handleMouseDown}
				onBlur={this.handleBlur}>
				<ul>
					<li onClick={this.handleLogout}>Cerrar Sesión</li>
				</ul>
			</div>
		)
	}
  	render = () => {
		const {userName} = this.props
		return (
			<div className="main-menu">
				<div className="user" onClick={this.toggleShowMenu}>
					<div><span className="icon-user"></span></div>
					<div>{userName}</div>
				</div>
				<div onClick={this.toggleShowMenu}><span className="icon-menu"></span></div>
				{this.renderDropdown()}
			</div>
		)
	}
}