import React from 'react'
import PropTypes from 'prop-types'

class Loader extends React.Component{
    render = () => {
        const {msg} = this.props
        return (
            <div className="loader">
                <div></div>
                <div>{msg}</div>
            </div>
        )
    }
}
Loader.propTypes = {
    msg: PropTypes.string.isRequired
}

export default Loader