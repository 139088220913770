import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const searchProducts = createSlice({
	name: "searchProducts",
	initialState: {
		isLoading: false,
		list: [],
		searchList: [],
	},
	reducers: {
		requestSearchProducts(state) {
			state.isLoading = true;
		},
		receiveSearchProducts(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		setSearchProductsList(state, action) {
			state.selectedItem = action.payload.searchList;
		},
		clearSearchProducts(state) {
			state.isLoading = false;
			state.list = [];
			state.searchList = [];
		},
	},
});

export const { 
	requestSearchProducts, 
	receiveSearchProducts,
	setSearchProductsList,
	clearSearchProducts,
} = searchProducts.actions;

export const fetchSearchProducts = (list) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify(list)
		};
		let responseStatus = 0;
		dispatch(requestSearchProducts());
		return fetch(BASE_API_URL + "/api/Manager/Productos/Buscar", options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject("No tiene permisos para este recurso.");
				}
			})
			.then((json) => {
				dispatch(receiveSearchProducts({ list: json }));
				dispatch(setSearchProductsList({searchList: list}));
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				// if (responseStatus === 400) {
				// }
			});
	});
};

export default searchProducts.reducer;
