import client from "./networking";

/**
 * Get user list.
 * @param {object} data object - form data.
 * @returns
 */
export const getUsers = (data) => {
  return client.post(`/api/Manager/Usuarios`, data);
};
