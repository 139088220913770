import React from "react";
import Modal from "react-modal";
import Loader from "components/loader/Loader";

export default class AddPartnerModal extends React.Component {
	handleClose = () => {
		const { selectedItem } = this.props;
		this.props.onCancel(selectedItem);
	};
	handleClick = () => {
		const { alias, name, factor, errorMsg } = this.props;
		this.props.onClick(alias, name, factor, errorMsg);
	};
	handleFactorFocus = (e) => {
		e.target.select();
	};
	handleAliasChange = (e) => {
		const alias = e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, "");
		this.props.onAliasChange(alias);
	};
	handleNameChange = (e) => {
		const name = e.target.value;
		this.props.onNameChange(name);
	};
	handleFactorChange = (e) => {
		const regexp = /^(\d{0,2})(\.|\.\d{1,2})?$/g;
		const match = regexp.test(e.target.value);
		if (match) {
			this.props.onFactorChange(e.target.value);
		}
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderAlias = () => {
		const { alias } = this.props;
		return (
			<div>
				<div style={{ padding: "5px 0" }}>
					<p>Por favor llene el formulario para agregar un nuevo Partner.</p>
					<label>Alias: </label>
					<input
						type="text"
						placeholder="Alias"
						style={{
							maxWidth: "150px",
							minWidth: "150px",
							fontStyle: "normal",
						}}
						value={alias}
						onChange={this.handleAliasChange}
					></input>
				</div>
			</div>
		);
	};
	renderName = () => {
		const { name } = this.props;
		return (
			<div>
				<div>
					<label>Nombre: </label>
					<input
						type="text"
						placeholder="Nombre"
						style={{
							textAlign: "left",
							fontStyle: "normal",
						}}
						value={name}
						onChange={this.handleNameChange}
					></input>
				</div>
			</div>
		);
	};
	renderFactor = () => {
		const { factor } = this.props;
		return (
			<div>
				<div>
					<label>Incremento: </label>
					<input
						type="text"
						placeholder=""
						style={{
							textAlign: "right",
							maxWidth: "60px",
							minWidth: "60px",
							fontStyle: "normal",
						}}
						value={factor}
						onChange={this.handleFactorChange}
						onFocus={this.handleFactorFocus}
					></input>{" "}%
				</div>
			</div>
		);
	};
	renderSubmit = () => {
		const { factor, name } = this.props;
		if (factor !== "" && Number(factor) <= 60 && name.trim() !== "") {
			return (
				<div>
					<div className="button" onClick={this.handleClose}>
						Cancelar
					</div>
					<div className="button main" onClick={this.handleClick}>
						Guardar
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className="button" onClick={this.handleClose}>
					Cancelar
				</div>
				<div className="button disabled">Guardar</div>
			</div>
		);
	};
	renderForm = () => {
		const { isLoading } = this.props;
		if (!isLoading) {
			return (
				<div>
					<div className="form-a">
						{this.renderAlias()}
						{this.renderName()}
					</div>
					<div className="form-a">{this.renderFactor()}</div>
					{this.renderSubmit()}
				</div>
			);
		}
	};
	render = () => {
		const customStyles = {
			content: {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)",
				minWidth: "550px",
				minHeight: "350px",
			},
			overlay: {
				backgroundColor: "rgb(0,0,0,.7)",
				zIndex: "2",
			},
		};
		const { isOpen } = this.props;
		if (isOpen) {
			return (
				<div>
					<Modal
						isOpen={isOpen}
						onRequestClose={this.handleClose}
						style={customStyles}
						contentLabel="NewContiPartner"
					>
						<div className="conti-modal">
							<h2>Nuevo ContiPartner</h2>
							{this.renderForm()}
							{this.renderLoader()}
						</div>
					</Modal>
				</div>
			);
		}
		return null;
	};
}
