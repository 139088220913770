import { connect } from "react-redux";
import Software from "./Software";

const mapStateToProps = (state) => {
	const { isLoading, list } = state.software;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};

export default connect(mapStateToProps )(Software);
