import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { fetchContiPartnerCoupons } from "./contiPartnersSlice";

const initialState = {
	isOpen: false,
	isLoading: false,
	errorMsg: null,
	qty: 0,
	name: "",
};
const addPartnerCoupon = createSlice({
	name: "addPartnerCoupon",
	initialState,
	reducers: {
		requestAddContiPartnerCoupon(state) {
			state.isLoading = true;
		},
		receiveAddContiPartnerCoupon(state) {
			state.isLoading = false;
		},
		toggleShowAddContiPartnerCoupon(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		setAddContiPartnerCouponQty(state, action) {
			state.qty = action.payload.qty;
		},
		setAddContiPartnerCouponName(state, action) {
			state.name = action.payload.name;
		},
		setAddContiPartnerCouponErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearAddContiPartnerCouponData: () => initialState,
	},
});

export const {
	requestAddContiPartnerCoupon,
	receiveAddContiPartnerCoupon,
	toggleShowAddContiPartnerCoupon,
	setAddContiPartnerCouponQty,
	setAddContiPartnerCouponName,
	setAddContiPartnerCouponErrorMsg,
	clearAddContiPartnerCouponData,
} = addPartnerCoupon.actions;

export const fetchAddContiPartnerCoupon =
	(item, qty, name) => async (dispatch) => {
		sessionService.loadSession().then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const data = JSON.stringify({
				idPartner: item.Id,
				localidad: name,
				capacidad: qty,
			});
			dispatch(requestAddContiPartnerCoupon());
			const url = BASE_API_URL + "/api/Manager/ContiPartners/Cupones";
			axios
				.post(url, data, options)
				.then(() => {
					dispatch(receiveAddContiPartnerCoupon());
					dispatch(fetchContiPartnerCoupons(item.Id));
					dispatch(toggleShowAddContiPartnerCoupon({isOpen: true}));
					dispatch(clearAddContiPartnerCouponData());
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					if (error.status === 400) {
						dispatch(setAddContiPartnerCouponErrorMsg({errorMsg: {system: "Ocurrió un error al intentar crear el cupón."}}));
					}
				});
		});
	};

export default addPartnerCoupon.reducer;
