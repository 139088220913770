import React from "react";
import { format, parseISO } from "date-fns";
import { numberFormat } from "../../../features/functions";

export default class ContiPartnerItem extends React.Component {
	handleClick = () => {
		this.props.onClick(this.props.item);
	};
	renderStatus = () => {
		const { item } = this.props;
		if (item.IdEstatus !== 1) {
			return <td className="red">{item.Estatus}</td>;
		}
		return <td>{item.Estatus}</td>;
	};
	render = () => {
		const { item, index } = this.props;
		return (
			<tr onClick={this.handleClick}>
				<td>{index + 1}</td>
				<td>{item.Alias}</td>
				<td>{item.Nombre}</td>
				<td>{numberFormat(item.Incremento, 2) + " %"}</td>
				<td>{format(parseISO(item.Modificacion), "dd/MMM/yyyy hh:mm aaaa")}</td>
				{this.renderStatus()}
				<td>{item.Manager}</td>
				<td>{format(parseISO(item.Fecha), "dd/MMM/yyyy")}</td>
				<td>{item.Propietario !== null ? item.Propietario : ""}</td>
			</tr>
		);
	};
}
