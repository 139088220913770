import { connect } from "react-redux";
import { PAYMENT_METHODS } from "features/constants";
import { fetchCreditOrders, setCreditOrdersType } from "features/credit/creditOrdersSlice";
import { fetchCreditOrdersRelease } from "features/credit/creditOrdersReleaseSlice";
import CreditOrders from "./CreditOrders";

const mapStateToProps = (state) => {
	const { 
		type,
		isLoadingCC,
		isLoadingPP,
		isLoadingPN,
		isLoadingSP,
		ccList,
		ppList,
		pnList,
		spList,
	} = state.creditOrders;
	const isLoadingRelease = state.creditOrdersRelease.isLoading;
	const { tabList, activeTab } = state.tabs;
	return {
		type,
		isLoadingCC,
		isLoadingPP,
		isLoadingPN,
		isLoadingSP,
		ccList,
		ppList,
		pnList,
		spList,
		isLoadingRelease,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLoad: () => {
			const p = PAYMENT_METHODS;
			dispatch(fetchCreditOrders(p.CREDITCARD));
			dispatch(fetchCreditOrders(p.PAYPAL));
			dispatch(fetchCreditOrders(p.PAYNET));
			dispatch(fetchCreditOrders(p.SAFETYPAY));
		},
		onTypeSelect: (type) => {
			dispatch(fetchCreditOrders(type));
			dispatch(setCreditOrdersType({type}));
		},
		onRelease: (data, type) => {
			// Validate Data
			// At least one order must be selected
			let orderList = [];
			if (data.length > 0) {
				// Build array
				for (let i = 0; i < data.length; i++) {
					orderList.push(data[i].Pedido);
				}
				dispatch(fetchCreditOrdersRelease(orderList, type));
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditOrders);
