import React from "react";
import Loader from "../../loader/Loader";
import BlockedProductsItem from "./BlockedProductsItem";
import { MAIN_TABS } from "features/constants";

export default class BlockedProducts extends React.Component {
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>#</th>
					<th>
						Código<span className="icon-sort-inverse"></span>
					</th>
					<th>Marca</th>
					<th>Producto</th>
					<th>Medida</th>
					<th>Almacén</th>
					<th>Inventario</th>
					<th>Fecha</th>
					<th>Creado por</th>
					<th className="left">Motivo</th>
				</tr>
			</thead>
		);
	};
	renderProducts = () => {
		const { isLoading, list } = this.props;
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderTableHeaders()}
						<tbody>
							{list.map((el, i) => {
								return (
									<BlockedProductsItem
										key={i}
										index={i}
										item={el}
										onClick={this.props.onClick}
									/>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="10">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderTableHeaders()}
					<tbody>
						<tr>
							<td colSpan="10">No hay datos que mostrar.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="10">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	render = () => {
		const { isLoading, activeTabItem } = this.props;
		if (isLoading) {
			return (<div>{this.renderLoader()}</div>)
		}
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.ProductsBlocked) {
			return (
				<section className="sales">
					<div>
						<h1>Productos Bloqueados</h1>
					</div>
					<div className="orders-table">
						<div className="orders-table-title">
							<div>Detalles:</div>
						</div>
						<div className="orders-table-content">{this.renderProducts()}</div>
					</div>
				</section>
			);
		}
		return null;
	};
}
