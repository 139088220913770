import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const initialState = {
	isLoading: false,
	list: [],
};

const contiPartnerList = createSlice({
	name: "contiPartnerList",
	initialState,
	reducers: {
		requestPartnerList(state) {
			state.isLoading = true;
		},
		receivePartnerList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearPartnerList: () => initialState,
	},
});

export const {
	requestPartnerList,
	receivePartnerList,
	clearPartnerList,
} = contiPartnerList.actions;

export const fetchPartnerList = () => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			//method: "GET",
			headers: headers,
		};
		dispatch(requestPartnerList());
		const url = BASE_API_URL + "/api/Manager/ContiPartners/Lista";
		axios
			.get(url, options)
			.then((response) => {
				dispatch(receivePartnerList({ list: response.data }));
			})
			.catch((error) => {
				// If 401 Unauthorized login failed
				if (error.status === 401) {
					dispatch(runUnauthorizedActions());
				}
			});
	});
};

export default contiPartnerList.reducer;
