import React from "react";
import {currencyFormat} from "../../../features/functions";
import { format, parseISO } from "date-fns";

export default class CreditOrdersItem extends React.Component {
	constructor(props) {
		super(props);
		this.checkRef = React.createRef();
	}
	handleCheckChange = (e) => {
		//this.props.onClick(this.props.item.Pedido, e.target.checked);
	};
	handleSelect = (index, selectedValue) => {
		let newSelected = this.state.selected;
		newSelected[index] = selectedValue;
		this.setState({ selected: newSelected });
		this.props.onChange(this.props.item.Pedido, index, selectedValue);
	};
	handleClick = () => {
		const isChecked = !this.checkRef.current.checked;
		this.checkRef.current.checked = isChecked;
		this.props.onClick(this.props.item.Pedido, isChecked);
	};
	render = () => {
		// updateLocale();
		const { item, isChecked } = this.props;
		if (item != null) {
			const itemProps = isChecked === true 
				? { className: "selected", onClick: this.handleClick } 
				: {onClick: this.handleClick};
			const checkboxName = "order-" + item.Pedido;
			return (
				<tbody {...itemProps}>
								<tr key={item.key}>
									<td>
										<div className="check-switch">
											<input
												type="checkbox"
												id={checkboxName}
												name={checkboxName}
												value={item.Pedido}
												checked={!!isChecked}
												onChange={this.handleCheckChange}
												ref={this.checkRef}
											/>
											<label htmlFor={checkboxName}>
												<span className="icon-switch"></span>
											</label>
										</div>
									</td>
									<td>{item.Pedido}</td>
									<td className="right">{currencyFormat(item.Total)}</td>
									<td>{format(parseISO(item.Fecha), "dd/MMM/yyyy")}</td>
									<td>{item.Estatus}</td>
									<td>{item.MetodoPago}</td>
									<td>{item.Delivery}</td>
									<td className="left">{item.Cliente.NombreCompleto}</td>
									<td>{item.Cliente.TipoUsuario}</td>
									<td className="left">{item.Localidad}</td>
								</tr>
				</tbody>
			);
		}
		return null;
	};
}
