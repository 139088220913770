import { connect } from "react-redux";
import DealerDelivery from "./DealerDelivery";
import {
	fetchDealerOrderDelivery,
	fetchDealerOrderDeliveryDownload,
	setDealerOrderDeliveryErrorMsg,
	cancelDealerOrderDelivery,
	setDealerOrderDeliverySuccess,
} from "features/dealer/dealerOrderDeliverySlice";
import {
	validateName,
	validateEmployeeNumber,
	validateIdText,
} from "features/functions";
import { CLIENT_TYPE, USER_PROFILE } from "features/constants";

const mapStateToProps = (state) => {
	const { tabList, activeTab } = state.tabs;
	const { user } = state.session;
	const {
		isLoading,
		order,
		errorMsg,
		success,
		employee,
	} = state.dealerOrderDelivery;
	const { list } = state.dealerLocations;
	return {
		isLoading,
		order,
		errorMsg,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
		list,
		success,
		employee,
		user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSearchOrder: (data) => {
			let valid = true;
			let errorMsg = {};
			if (data.employeeNumber.trim() !== "") {
				if (!validateEmployeeNumber(data.employeeNumber)) {
					errorMsg = {
						...errorMsg,
						employee: "El número de empleado no es válido.",
					};
					valid = false;
				}
			}
			if (valid) {
				dispatch(fetchDealerOrderDelivery(data));
			} else {
				dispatch(setDealerOrderDeliveryErrorMsg({ errorMsg }));
			}
		},
		onSetErrorMsg: (errorMsg) => {
			dispatch(setDealerOrderDeliveryErrorMsg({ errorMsg }));
		},
		onCancel: () => {
			dispatch(cancelDealerOrderDelivery());
		},
		onSuccessClick: () => {
			dispatch(setDealerOrderDeliverySuccess({ success: null }));
		},
		onDownload: (data, order) => {
			let valid = true;
			let errorMsg = {};
			if (data.name.trim() === "") {
				errorMsg = {
					...errorMsg,
					name: "El nombre de la persona que recibe es obligatorio.",
				};
				valid = false;
			} else {
				if (!validateName(data.name.trim())) {
					errorMsg = {
						...errorMsg,
						name: "Debe escribir el nombre completo y debe ser válido.",
					};
					valid = false;
				}
			}
			if (
				data.profile === USER_PROFILE.Socio &&
				data.selectedLocation == null
			) {
				errorMsg = {
					...errorMsg,
					location: "Debe especificar la sucursal de entrega",
				};
				valid = false;
			}
			if (data.idText.trim() === "") {
				errorMsg = {
					...errorMsg,
					idText:
						"Por favor especifique el tipo de identificación y número de la persona que recibe. Ejemplo: INE 12345678910",
				};
				valid = false;
			} else {
				if (!validateIdText(data.idText)) {
					errorMsg = {
						...errorMsg,
						idText:
							"Debe escribir el tipo de identificación (INE, pasaporte, licencia, etc), un espacio y luego el folio o número de identificación. Ejemplo: INE 12345678910",
					};
					valid = false;
				}
			}
			if (valid) {
				if (order.TipoId === CLIENT_TYPE.Employee) {
					dispatch(
						fetchDealerOrderDeliveryDownload({ ...data, order: order.Pedido })
					);
				} else {
					dispatch(
						fetchDealerOrderDeliveryDownload({
							...data,
							NumEmpleado: null,
							order: order.Pedido,
						})
					);
				}
			} else {
				dispatch(setDealerOrderDeliveryErrorMsg({ errorMsg }));
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerDelivery);
