import React from "react";
import { TAB_TYPE } from "features/constants";

export default class Tab extends React.Component {
	handleClick = () => {
		const {index, tab} = this.props;
		this.props.onClick(index);
		if (tab.type === TAB_TYPE.Order){
			this.props.onOrder(tab.tab);
		}
	};
	handleClose = () => {
		this.props.onClose(this.props.tab.tab);
	};
	render = () => {
		const { tab, index, tabCssClass } = this.props;
		if (tab.type === TAB_TYPE.Order) {
			return (
				<div key={index} className={tabCssClass}>
					<div onClick={this.handleClick}>{tab.title}</div>
					<div onClick={this.handleClose}>
						<span className="icon-tab-close"></span>
					</div>
				</div>
			);
		}
		return (
			<div key={index} className={tabCssClass} onClick={this.handleClick}>
				<div>{tab.title}</div>
			</div>
		);
	};
}
