import React from "react";

export default class PageControls extends React.Component {
	render = () => {
		const {
			currentPage,
			totalPages,
			onPageChange,
			onFirst,
			onLast,
			onPrev,
			onNext,
		} = this.props;
		return (
			<div className="control-buttons">
				<div>
					<div className="button main" onClick={() => onFirst()}>
						<span className="icon-first"></span>
					</div>
				</div>
				<div>
					<div className="button main" onClick={() => onPrev()}>
						<span className="icon-prev"></span>
					</div>
				</div>
				<div>
					<input
						type="text"
						value={currentPage}
						onChange={(e) => onPageChange(e)}
					/>
				</div>
				<div>{"de " + totalPages}</div>
				<div>
					<div className="button main" onClick={() => onNext()}>
						<span className="icon-next"></span>
					</div>
				</div>
				<div>
					<div className="button main" onClick={() => onLast()}>
						<span className="icon-last"></span>
					</div>
				</div>
			</div>
		);
	};
}
