import { connect } from "react-redux";
import { fetchSupportMessage, fetchUpdateSupportMessage, setSupportMessageErrorMsg, setSupportMessagesEditMessage, setSupportMessagesMessage } from "features/support/supportMessagesSlice";
import SupportMessages from "./SupportMessages";

const mapStateToProps = (state) => {
	const {
		isLoading,
		list,
		total,
		isOpening,
		message,
		isEditing,
		isSaving,
		errorMsg,
	} = state.supportMessages;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		total,
		isOpening,
		message,
		isEditing,
		errorMsg,
		isSaving,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (id) => {
			dispatch(fetchSupportMessage(id));
		},
		onClose: () => {
			dispatch(setSupportMessagesMessage({ message: null }));
		},
		onEdit: (edit) => {
			dispatch(setSupportMessagesEditMessage({ isEditing: edit }));
		},
		onSetErrorMsg: (errorMsg) => {
			dispatch(setSupportMessageErrorMsg(errorMsg));
		},
		onSave: (data) => {
			dispatch(fetchUpdateSupportMessage(data));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportMessages);
