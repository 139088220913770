import { TAB_TYPE } from "./constants";
export const validateName = (name) => {
	if (name.trim().length < 5) {
		return false;
	}
	return /([A-Za-zÀ-ÖØ-öø-ÿ.]+\s?\b){2,}/.test(name);
};
export const validateEmployeeNumber = (employee) => {
	return /^(\d{6,})$/.test(employee);
};
export const validateOrderNumber = (order) => {
	return /^([1-9])(\d){5,}$/.test(order);
};
export const validateIdText = (idText) => {
	const textArray = idText.trim().split(/(\s+)/);
	if (textArray[0].length < 3) {
		return false;
	}
	return /^([A-Za-zÀ-ÖØ-öø-ÿ.]+\s{1,}\b){1}([0-9A-Za-zÀ-ÖØ-öø-ÿ.-]+\s?\b){1,}/.test(
		idText
	);
};
export const getNameFromDate = (date) => {
	return date.toISOString().slice(0, 19).replace(/[T:]/g, "-");
};
export const currencyFormat = (num) => {
	return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const numberFormat = (num, places = 0) => {
	return num.toFixed(places).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const createOrderTabs = (orders) => {
	let orderTabs = [];
	if (orders != null && orders.length > 0) {
		for (let i = 0; i < orders.length; i++) {
			orderTabs.push({
				tab: orders[i],
				title: orders[i] + "",
				type: TAB_TYPE.Order,
			});
		}
	}
	return orderTabs;
};

export const toSelectorOptions = (properties, ignore = []) => {
	const keys = Object.values(properties);
	let list = [];
	keys.forEach(function myFunction(e) {
		if (ignore.includes(e.value)) return;
		list.push({ value: e.value, label: e.name });
	});
	return list;
};

export const objectToList = (item) => {
	if (item == null) {
		return [];
	}
	const keys = Object.keys(item);
	return keys.map((key) => {
		return { key: key, value: item[key] };
	});
};

export const toFieldClass = (list) => {
	const listMap = list.map((item) => {
		if (item.value == null) return null;
		else return [item.key, { className: "field-error" }];
	});
	return Object.fromEntries(listMap.filter((item) => item != null));
};
