import { connect } from "react-redux";
import { fetchAccount } from "../features/account/accountSlice";
import App from "./App";

const mapDispatchToProps = (dispatch) => {
	return {
		onLoad: () => {
			dispatch(fetchAccount());
		},
	};
};

export default connect(null, mapDispatchToProps)(App);
