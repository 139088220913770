import React, { Component } from "react";
import { format, parseISO } from "date-fns";
import { CLIENT_TYPE } from "features/constants";

export default class UserItem extends Component {
	render() {
		const { item } = this.props;
		return (
			<tr>
				<td>{item.name}</td>
				<td>{item.lastName}</td>
				<td>{item.familyName}</td>
				<td>{CLIENT_TYPE.properties[item.type].name}</td>
				<td>{item.email}</td>
				<td>{item.phone}</td>
				<td>{item.gender}</td>
				<td>{format(parseISO(item.birthDate), "dd/MMM/yyyy")}</td>
				<td>{format(parseISO(item.registerDate), "dd/MMM/yyyy hh:mm aaaa")}</td>
				<td>{item.active ? "Sí" : "-"}</td>
				<td>{item.subscribed ? "Sí" : "-"}</td>
				<td>{item.contiPartner ?? ""}</td>
				<td>
					{item.lastPurchase != null
						? format(parseISO(item.lastPurchase), "dd/MMM/yyyy hh:mm aaaa")
						: "-"}
				</td>
			</tr>
		);
	}
}
