import { connect } from "react-redux";
import TabsBar from "./TabsBar";
import { setActiveTab, fetchRemoveOrderTab, runSelectedTabActions } from "features/tabs/tabsSlice";
import { fetchSalesOrder } from "features/sales/salesOrdersSlice";
import { fetchSupportOrder } from "features/support/supportSlice";

const mapStateToProps = (state) => {
	const { tabList, activeTab } = state.tabs;
	const { user } = state.session;
	return {
		tabList,
		activeTab,
		user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onTabClick: (activeTab) => {
			dispatch(setActiveTab({ activeTab }));
			dispatch(runSelectedTabActions(activeTab));
		},
		onTabClose: (order) => {
			dispatch(fetchRemoveOrderTab(order));
		},
		onLoadOrder: (order) => {
			dispatch(fetchSalesOrder(order));
		},
		onLoadSupportOrder: (order) => {
			dispatch(fetchSupportOrder(order));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsBar);
