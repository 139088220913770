import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL, PAYMENT_METHODS, USER_PROFILE } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { fetchDealerOrders } from "../dealer/dealerOrdersSlice";
import { fetchDealerLocationOrders } from "features/dealer/dealerLocationOrdersSlice";
import { fetchHrOrders } from "../hr/hrOrdersSlice";
import { fetchOrderTabs, setTabsMain } from "features/tabs/tabsSlice";
import { fetchDealerLocations } from "features/dealer/dealerLocationsSlice";
import { fetchCreditOrders } from "features/credit/creditOrdersSlice";
import { fetchSalesOrders } from "features/sales/salesOrdersSlice";
import { fetchLocations } from "features/locations/locationsSlice";
import { fetchStates } from "features/states/statesSlice";
import { fetchPartnerList } from "features/contipartners/contiPartnerListSlice";

const accountSlice = createSlice({
	name: "account",
	initialState: {
		isLoading: false,
	},
	reducers: {
		requestAccountData(state) {
			state.isLoading = true;
		},
		receiveAccountData(state) {
			state.isLoading = false;
		},
	},
});

export const { requestAccountData, receiveAccountData } = accountSlice.actions;

export const fetchAccount = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			let headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			let options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestAccountData());
			let responseStatus = 0;
			return fetch(BASE_API_URL + "/api/Manager/Account", options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject(
							"El usuario o la contraseña son incorrectos."
						);
					}
				})
				.then((json) => {
					sessionService
						.saveUser({ user: json })
						.then()
						.catch((error) => console.log("error", error));
					dispatch(receiveAccountData());
					dispatch(
						setTabsMain({
							tabsMain: USER_PROFILE.properties[json.Profile].tabs,
						})
					);
					dispatch(fetchLocations());
					const u = USER_PROFILE;
					const p = PAYMENT_METHODS;
					switch (json.Profile) {
						case u.Webmaster:
						case u.Ventas:
						case u.VentasPartners:
							dispatch(fetchOrderTabs());
							dispatch(fetchSalesOrders());
							dispatch(fetchDealerLocations());
							dispatch(fetchStates());
							dispatch(fetchPartnerList());
							break;
						case u.Credito:
							dispatch(fetchCreditOrders(p.CREDITCARD));
							dispatch(fetchCreditOrders(p.PAYPAL));
							dispatch(fetchCreditOrders(p.PAYNET));
							dispatch(fetchCreditOrders(p.SAFETYPAY));
							break;
						case u.ContactCenter:
							dispatch(fetchOrderTabs());
							break;
						case u.Socio:
							dispatch(fetchDealerOrders());
							dispatch(fetchDealerLocations());
							break;
						case u.SocioSucursal:
						case u.SucursalDistribuidor:
							dispatch(fetchDealerLocationOrders());
							break;
						case u.Localidad:
							dispatch(fetchHrOrders());
							break;
						default:
							break;
					}
				})
				.catch((error) => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default accountSlice.reducer;
