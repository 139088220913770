import React from "react";
import Tab from "./Tab";
import { TAB_TYPE, USER_PROFILE } from "../../../features/constants";

export default class TabsBar extends React.Component {
	handleTabClick = (activeTab) => {
		this.props.onTabClick(activeTab);
	};
	handleTabClose = (order) => {
		this.props.onTabClose(order);
	};
	handleLoadOrder = (order) => {
		this.props.onLoadOrder(order);
	};
	handleLoadSupportOrder = (order) => {
		this.props.onLoadSupportOrder(order);
	};
	renderTabs = () => {
		const { tabList, activeTab, user } = this.props;
		const loadOrder =
			user.user != null && user.user.Profile === USER_PROFILE.ContactCenter
				? this.handleLoadSupportOrder
				: this.handleLoadOrder;
		if (tabList != null) {
			return (
				<div>
					{tabList.map((tab, i) => {
						const tabCssClass =
							(tab.type === TAB_TYPE.Main ? "main-tab" : "tab") +
							(activeTab === i ? " active" : "");
						return (
							<Tab
								key={i}
								index={i}
								tabCssClass={tabCssClass}
								tab={tab}
								onClick={this.handleTabClick}
								onClose={this.handleTabClose}
								onOrder={loadOrder}
							/>
						);
					})}
				</div>
			);
		}
		return null;
	};
	render = () => {
		return (
			<div id="tabs">
				<div>{this.renderTabs()}</div>
			</div>
		);
	};
}
