import client from "./networking";

/**
 * Get contact message list.
 * @param {object} data object - form data.
 * @returns
 */
export const getContactMessages = (data) => {
  return client.post(`/api/Manager/Mensajes`, data);
};

/**
 * Get a contact message by id.
 * @param {string} id message id.
 * @returns
 */
export const getContactMessage = (id) => {
  return client.get(`/api/Manager/Mensajes/${id}`);
};

/**
 * Update a contact message.
 * @param {string} id message id.
 * @param {object} data object - form data.
 * @returns
 */
export const updateContactMessage = (id, data) => {
  return client.put(`/api/Manager/Mensajes/${id}`, data);
};