import React from "react";
import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import { MAIN_TABS } from "features/constants";
import { currencyFormat, numberFormat } from "features/functions";
import SalesOrdersItem from "./SalesOrdersItem";
import PageControls from "components/common/pageControls";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const setTotals = (list, page, rows) => {
	if (list == null) {
		return {
			showingFrom: 0,
			showingTo: 0,
			pages: 0,
			orders: 0,
			tires: 0,
			amount: 0,
			tax: 0,
			ship: 0,
			total: 0,
		};
	}
	const pages = Math.ceil(list.Cantidad / rows);
	const showingFrom = list.Cantidad !== 0 ? page * rows + 1 : 0;
	let showingTo = 0;
	if (list.Cantidad < rows) showingTo = list.Cantidad;
	if (list.Cantidad >= rows) {
		if (page + 1 === pages) {
			showingTo = list.Cantidad;
		} else {
			showingTo = (page + 1) * rows;
		}
	}
	return {
		showingFrom: showingFrom,
		showingTo: showingTo,
		pages: pages,
		orders: list.Cantidad,
		tires: list.Llantas,
		amount: list.Monto,
		tax: list.Impuestos,
		ship: list.Envio,
		total: list.Total,
	};
};
const statusOptions = [
	{ label: "Todos", value: 0 },
	{ label: "Solo Válidos", value: 1 },
	{ label: "Esperando Pago", value: 15 },
	{ label: "Esperando Aprobación", value: 16 },
	{ label: "Pago Recibido", value: 18 },
	{ label: "Pedido Aprobado", value: 19 },
	{ label: "Pago Verificado", value: 20 },
	{ label: "En Proceso", value: 21 },
	{ label: "Preparando Envío", value: 40 },
	{ label: "Delivery Factory", value: 45 },
	{ label: "Delivery Empleados", value: 47 },
	{ label: "Enviado", value: 48 },
	{ label: "En Tránsito", value: 50 },
	{ label: "En Sucursal DHL", value: 55 },
	{ label: "En Localidad RH", value: 58 },
	{ label: "En Sucursal de Distribuidor", value: 59 },
	{ label: "Entregado", value: 60 },
	{ label: "Incompleto", value: 10 },
	{ label: "No Autorizado", value: 23 },
	{ label: "No Pagado", value: 26 },
	{ label: "Cancelado", value: 900 },
];
const userTypeOptions = [
	{ label: "Todos", value: 0 },
	{ label: "Consumidor", value: 1 },
	{ label: "Empleado", value: 2 },
	{ label: "FamilyFriend", value: 3 },
	{ label: "ContiPartner", value: 4 },
	{ label: "ExpressDelivery", value: 5 },
];
const paymentOptions = [
	{ label: "Todos", value: 0 },
	{ label: "Tarjeta de Crédito", value: 2 },
	{ label: "PayPal", value: 3 },
	{ label: "SafetyPay", value: 5 },
	{ label: "Pago en Establecimientos", value: 6 },
	{ label: "Línea de Crédito", value: 7 },
];
const shipOptions = [
	{ label: "Todos", value: 0 },
	{ label: "Domicilio", value: 1 },
	{ label: "Factory Store", value: 2 },
	{ label: "Entrega en Recursos Humanos", value: 4 },
	{ label: "Sucursal DHL", value: 5 },
	{ label: "Sucursal Distribuidor", value: 6 },
	{ label: "Express Delivery", value: 7 },
];
const getLocationOptions = (locations) => {
	if (locations == null) {
		return [{ label: "Todas", value: null }];
	}
	let options = [{ label: "Todas", value: null }];
	for (let i = 0; i < locations.length; i++) {
		options.push({ label: locations[i].Name, value: locations[i].Id });
	}
	return options;
};
var locationOptions = getLocationOptions();
const getDealerLocationOptions = (locations) => {
	if (locations == null) {
		return [{ label: "Todas", value: 0 }];
	}
	let options = [{ label: "Todas", value: 0 }];
	for (let i = 0; i < locations.length; i++) {
		options.push({ label: locations[i].Nombre, value: locations[i].Id });
	}
	return options;
};
var dealerLocationOptions = getDealerLocationOptions();
const getContiPartnerOptions = (partners) => {
	if (partners == null) {
		return [{ label: "Todos", value: 0 }];
	}
	let options = [{ label: "Todos", value: 0 }];
	for (let i = 0; i < partners.length; i++) {
		options.push({ label: partners[i].Nombre, value: partners[i].Id });
	}
	return options;
};
var contiPartnerOptions = getContiPartnerOptions();
const getStateOptions = (states) => {
	if (states == null) {
		return [{ label: "Todos", value: null }];
	}
	let options = [{ label: "Todos", value: null }];
	for (let i = 0; i < states.length; i++) {
		options.push({ label: states[i].Nombre, value: states[i].Id });
	}
	return options;
};
var stateOptions = getStateOptions();
export default class SalesOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = { orderField: "" };
	}
	handleOrderChange = (e) => {
		const value = e.target.value.replace(/[^0-9]/g, "");
		this.setState({ orderField: value });
	};
	handleOrderSearch = () => {
		this.props.onOpenOrder(this.state.orderField);
		this.setState({ orderField: "" });
	};
	handleOrderKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleOrderSearch();
		}
	};
	handleFromDateChange = (date) => {
		this.props.onFromDate(date);
	};
	handleUntilDateChange = (date) => {
		this.props.onUntilDate(date);
	};
	handleStatusChange = (option) => {
		this.props.onOrderStatus(option.value);
	};
	handleUserTypeChange = (option) => {
		this.props.onUserType(option.value);
	};
	handlePaymentChange = (option) => {
		this.props.onPayment(option.value);
	};
	handleShipChange = (option) => {
		this.props.onShip(option.value);
	};
	handleClearFilters = () => {
		this.props.onClearFilters();
	};
	handleLocationChange = (option) => {
		this.props.onLocation(option.value);
	};
	handleStateChange = (option) => {
		this.props.onState(option.value);
	};
	handleDealerLocationChange = (option) => {
		this.props.onDealerLocation(option.value);
	};
	handleContiPartnerChange = (option) => {
		this.props.onContiPartner(option.value);
	}
	handleNext = () => {
		const { page, rows, list } = this.props;
		const pages = list != null ? Math.ceil(list.Cantidad / rows) - 1 : 0;
		if (page < pages) {
			this.props.onPage(page + 1);
		}
	};
	handleLast = () => {
		const { page, rows, list } = this.props;
		const pages = list != null ? Math.ceil(list.Cantidad / rows) - 1 : 0;
		if (page !== pages) {
			this.props.onPage(pages);
		}
	};
	handlePrev = () => {
		const { page } = this.props;
		if (page > 0) {
			this.props.onPage(page - 1);
		}
	};
	handleFirst = () => {
		const { page } = this.props;
		if (page > 0) {
			this.props.onPage(0);
		}
	};
	handlePageChange = (e) => {
		// const newPage = e.target.value.replace(/[^0-9]/g, "");
		// this.setState({ page: newPage });
	};
	// handlePageBlur = (e) => {
	// 	const { page, rows, list } = this.props;
	// 	const pages = list != null ? Math.ceil(list.Cantidad / rows) - 1 : 0;
	// 	const newPage = Number(e.target.value.replace(/[^0-9]/g, "")) - 1;
	// 	if (newPage >= 0 && newPage !== page && newPage < pages) {
	// 		this.props.onPage(newPage);
	// 	} else {
	// 		e.target.value = page + 1;
	// 	}
	// };
	// handlePageKeyPress = (e) => {
	// 	if (e.key === "Enter") {
	// 		this.handlePageBlur(e);
	// 	}
	// };
	// handlePageKeyDown = (e) => {
	// 	const { page } = this.props;
	// 	if (e.key === "Tab") {
	// 		this.handlePageBlur(e);
	// 	}
	// 	if (e.key === "Escape") {
	// 		e.target.value = page + 1;
	// 	}
	// };
	handleOpenOrder = (order) => {
		this.props.onOpenOrder(order);
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>
						Pedido<span className="icon-sort-inverse"></span>
					</th>
					<th>Llantas</th>
					<th>Fecha</th>
					<th>Estatus</th>
					<th>SAP</th>
					<th>Delivery</th>
					<th>Fecha Envío</th>
					<th>Recepción Sucursal</th>
					<th>Entrega Sucursal</th>
					<th>Importe</th>
					<th>IVA</th>
					<th>Envío</th>
					<th>Total</th>
					<th>Metodo Pago</th>
					<th>Método Envío</th>
					<th>Envío a</th>
					<th>Tipo</th>
					<th>Nombre</th>
					<th>Correo</th>
					<th>Num Empleado</th>
					<th>Ciudad</th>
					<th>Estado</th>
					<th>Localidad</th>
					<th>ContiPartner</th>
				</tr>
			</thead>
		);
	};
	renderOrders = () => {
		const { isLoading, isLoadingDownload, list } = this.props;
		const columnCount = 24;
		if (!isLoading && !isLoadingDownload && list != null) {
			if (list.Pedidos.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderTableHeaders()}
						<tbody>
							{list.Pedidos.map((el, i) => {
								return (
									<SalesOrdersItem
										key={i}
										index={i}
										item={el}
										onClick={this.handleOpenOrder}
									/>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={columnCount}>&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderTableHeaders()}
					<tbody>
						<tr>
							<td colSpan={columnCount}>No hay pedidos que mostrar.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={columnCount}>&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	renderFilters = (totals) => {
		const {
			page,
			orderStatus,
			valid,
			userType,
			payment,
			ship,
			fromDate,
			untilDate,
			locationId,
			stateId,
			dealerLocationId,
			contiPartnerId,
			locations,
			states,
			dealerLocations,
			partnerList,
		} = this.props;
		const currentPage = page + 1;
		let statusDefaultOption =
			orderStatus != null
				? statusOptions.filter((opc) => opc.value === orderStatus)[0]
				: statusOptions.filter((opc) => opc.value === 0)[0];
		if (valid === 1) {
			statusDefaultOption = statusOptions.filter(
				(opc) => opc.value === valid
			)[0];
		}
		const userTypeDefaultOption =
			userType != null
				? userTypeOptions.filter((opc) => opc.value === userType)[0]
				: userTypeOptions.filter((opc) => opc.value === 0)[0];
		const paymentDefaultOption =
			payment != null
				? paymentOptions.filter((opc) => opc.value === payment)[0]
				: paymentOptions.filter((opc) => opc.value === 0)[0];
		const shipDefaultOption =
			ship != null
				? shipOptions.filter((opc) => opc.value === ship)[0]
				: shipOptions.filter((opc) => opc.value === 0)[0];
		locationOptions = getLocationOptions(locations);
		const locationDefaultOption =
			locationId != null
				? locationOptions.filter((opc) => opc.value === locationId)[0]
				: locationOptions.filter((opc) => opc.value === null)[0];
		stateOptions = getStateOptions(states);
		const stateDefaultOption =
			stateId != null
				? stateOptions.filter((opc) => opc.value === stateId)[0]
				: stateOptions.filter((opc) => opc.value === null)[0];
		dealerLocationOptions = getDealerLocationOptions(dealerLocations);
		const dealerLocationDefaultOption =
			dealerLocationId != null
				? dealerLocationOptions.filter(
						(opc) => opc.value === dealerLocationId
				  )[0]
				: dealerLocationOptions.filter((opc) => opc.value === null)[0];
		contiPartnerOptions = getContiPartnerOptions(partnerList);
		const contiPartnerDefaultOption =
			contiPartnerId != null
				? contiPartnerOptions.filter((opc) => opc.value === contiPartnerId)[0]
				: contiPartnerOptions.filter((opc) => opc.value === null)[0];
		return (
			<div className="order-filters">
				<div>
					<div className="field">
						<label>Desde</label>
						<div>
							<DatePicker
								selected={parseISO(fromDate)}
								onChange={this.handleFromDateChange}
								closeOnScroll={true}
								minDate={new Date(2010, 0, 1)}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="field">
						<label>Hasta</label>
						<div>
							<DatePicker
								selected={parseISO(untilDate)}
								onChange={this.handleUntilDateChange}
								closeOnScroll={true}
								minDate={new Date(2010, 0, 1)}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="field">
						<label>Estatus</label>
						<Dropdown
							options={statusOptions}
							value={statusDefaultOption}
							onChange={this.handleStatusChange}
							placeholder={statusDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>Tipo de usuario</label>
						<Dropdown
							options={userTypeOptions}
							value={userTypeDefaultOption}
							onChange={this.handleUserTypeChange}
							placeholder={userTypeDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>Método de Pago</label>
						<Dropdown
							options={paymentOptions}
							value={paymentDefaultOption}
							onChange={this.handlePaymentChange}
							placeholder={paymentDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>Método de Envío</label>
						<Dropdown
							options={shipOptions}
							value={shipDefaultOption}
							onChange={this.handleShipChange}
							placeholder={shipDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>Localidad</label>
						<Dropdown
							options={locationOptions}
							value={locationDefaultOption}
							onChange={this.handleLocationChange}
							placeholder={locationDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>Estado</label>
						<Dropdown
							options={stateOptions}
							value={stateDefaultOption}
							onChange={this.handleStateChange}
							placeholder={stateDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>Sucursal Distribuidor</label>
						<Dropdown
							options={dealerLocationOptions}
							value={dealerLocationDefaultOption}
							onChange={this.handleDealerLocationChange}
							placeholder={dealerLocationDefaultOption.label}
						/>
					</div>
					<div className="field">
						<label>ContiPartner</label>
						<Dropdown
							options={contiPartnerOptions}
							value={contiPartnerDefaultOption}
							onChange={this.handleContiPartnerChange}
							placeholder={contiPartnerDefaultOption.label}
						/>
					</div>
				</div>
				<div className="controls">
					<div>
						<PageControls
							currentPage={currentPage}
							totalPages={totals.pages}
							onPageChange={this.handlePageChange}
							onFirst={this.handleFirst}
							onLast={this.handleLast}
							onPrev={this.handlePrev}
							onNext={this.handleNext}
						/>
						<div>
							<div>
								<div className="button" onClick={this.handleClearFilters}>
									Restablecer Filtros
								</div>
							</div>
							<div className="left">
								<label>Pedido directo: </label>
								<input
									type="text"
									placeholder="Número"
									style={{ minWidth: "100px", textAlign: "left" }}
									value={this.state.orderField}
									onChange={this.handleOrderChange}
									onKeyPress={this.handleOrderKeyPress}
								></input>
							</div>
							<div>
								<div className="button main" onClick={this.handleOrderSearch}>
									Abrir
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	render = () => {
		const { list, page, rows, activeTabItem } = this.props;
		const totals = setTotals(list, page, rows);
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.Orders) {
			return (
				<section className="sales">
					<div>
						<h1>Administración de Pedidos</h1>
					</div>
					{this.renderFilters(totals)}
					<div className="orders-table">
						<div className="orders-table-title">
							<div>
								Pedidos{": "}
								<span>
									{numberFormat(totals.showingFrom) +
										" - " +
										numberFormat(totals.showingTo)}
								</span>{" "}
								de <span>{numberFormat(totals.orders)}</span>
								{" - "}Llantas{": "}
								<span> {numberFormat(totals.tires)} </span>
								{" - "}Total{": "}
								<span> {currencyFormat(totals.total)} </span>
							</div>
						</div>
						<div className="orders-table-content">{this.renderOrders()}</div>
					</div>
				</section>
			);
		}
		return null;
	};
}
