import {
	fetchBlockedProductAdd,
	setBlockedProductAdd,
	toggleBlockedProductModalAdd,
} from "features/products/blockedProductsSlice";
import { connect } from "react-redux";
import AddBlockedProductModal from "./AddBlockedProductModal";

const mapStateToProps = (state) => {
	const { isLoading, isOpenAdd, selectedAdd, source } = state.blockedProducts;
	return {
		isLoading,
		isOpen: isOpenAdd,
		selectedItem: selectedAdd,
		source,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onBlock: (data) => {
			dispatch(fetchBlockedProductAdd(data));
		},
		onClose: () => {
			dispatch(setBlockedProductAdd({ selectedAdd: null, source: null }));
			dispatch(toggleBlockedProductModalAdd({ isOpenAdd: true }));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddBlockedProductModal);
