import React from "react";
import Loader from "../../loader/Loader";
import InventoryItem from "./InventoryItem";
import { MAIN_TABS } from "features/constants";
import { numberFormat } from "features/functions";

const calcTotals = (list) => {
	if (list == null || list.length === 0) {
		return {
			Mxtx: 0,
			Mxco: 0,
		};
	} else {
		const mxtx = list.reduce((a, b) => a + b["Mxtx"], 0);
		const mxco = list.reduce((a, b) => a + b["Mxco"], 0);
		return {
			Mxtx: mxtx,
			Mxco: mxco,
		};
	}
};

export default class Inventory extends React.Component {
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>#</th>
					<th>
						Marca<span className="icon-sort-inverse"></span>
					</th>
					<th>Mkt</th>
					<th>Producto</th>
					<th>Código</th>
					<th>Medida</th>
					<th>Tipo</th>
					<th>Precio</th>
					<th>Precio Empleados</th>
					<th>MXTX</th>
					<th>Estatus</th>
					<th>MXCO</th>
					<th>Estatus MXCO</th>
					<th>Ancho</th>
					<th>Serie</th>
					<th>Radial</th>
					<th>Rin</th>
					<th>Índice Carga</th>
					<th>Índice Vel.</th>
					<th>SSR</th>
				</tr>
			</thead>
		);
	};
	renderProducts = () => {
		const cols = 21;
		const { isLoading, list } = this.props;
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderTableHeaders()}
						<tbody>
							{list.map((el, i) => {
								return (
									<InventoryItem
										key={i}
										index={i}
										item={el}
										onBlock={this.props.onBlock}
										onAddStock={this.props.onAddStock}
										source="inventory"
									/>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={cols}>&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderTableHeaders()}
					<tbody>
						<tr>
							<td colSpan={cols}>No hay productos que mostrar.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={cols}>&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	render = () => {
		const { list, isLoading, activeTabItem } = this.props;
		if (isLoading) {
			return <div>{this.renderLoader()}</div>;
		}
		const totals = calcTotals(list);
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.Inventory) {
			return (
				<section className="sales">
					<div>
						<h1>Inventarios</h1>
					</div>
					<div className="orders-table">
						<div className="orders-table-title">
							<div>
								Medidas{": "}
								<span>{numberFormat(list.length)}</span>
								{" - Unidades MXTX: "}
								<span> {numberFormat(totals.Mxtx)} </span>
								{" - Unidades MXCO: "}
								<span> {numberFormat(totals.Mxco)} </span>
							</div>
						</div>
						<div className="orders-table-content">{this.renderProducts()}</div>
					</div>
				</section>
			);
		}
		return null;
	};
}
