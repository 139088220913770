import client from "./networking";

/**
 * Get support message list.
 * @param {object} data object - form data.
 * @returns
 */
export const getSupportMessages = (data) => {
  return client.post(`/api/Manager/Soporte/Mensajes`, data);
};

/**
 * Get a support message by id.
 * @param {string} id message id.
 * @returns
 */
export const getSupportMessage = (id) => {
  return client.get(`/api/Manager/Soporte/Mensajes/${id}`);
};

/**
 * Update a support message.
 * @param {string} id message id.
 * @param {object} data object - form data.
 * @returns
 */
export const updateSupportMessage = (id, data) => {
  return client.put(`/api/Manager/Soporte/Mensajes/${id}`, data);
};