import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";

import accountReducer from "./account/accountSlice";
import addPartnerReducer from "./contipartners/addPartnerSlice";
import addPartnerCouponReducer from "./contipartners/addPartnerCouponSlice";
import contactMessagesReducer from "./messages/contactMessagesSlice";
import blockedProductsReducer from "./products/blockedProductsSlice";
import contiPartnerListReducer from "./contipartners/contiPartnerListSlice";
import contiPartnersReducer from "./contipartners/contiPartnersSlice";
import creditOrdersReducer from "./credit/creditOrdersSlice";
import creditOrdersReleaseReducer from "./credit/creditOrdersReleaseSlice";
import dealerLocationOrdersReducer from "./dealer/dealerLocationOrdersSlice";
import dealerLocationOrdersReceiveReducer from "./dealer/dealerLocationOrdersReceiveSlice";
import dealerLocationsReducer from "./dealer/dealerLocationsSlice";
import dealerOrderDeliveryReducer from "./dealer/dealerOrderDeliverySlice";
import dealerOrdersReducer from "./dealer/dealerOrdersSlice";
import dealerOrdersDownloadReducer from "./dealer/dealerOrdersDownloadSlice";
import hrOrderDeliveryReducer from "./hr/hrOrderDeliverySlice";
import hrOrdersReducer from "./hr/hrOrdersSlice";
import hrOrdersReceiveReducer from "./hr/hrOrdersReceiveSlice";
import inventoryReducer from "./inventory/inventorySlice";
import locationsReducer from "./locations/locationsSlice";
import loginReducer from "./login/loginSlice";
import salesOrderCancelReducer from "./sales/salesOrderCancelSlice";
import salesOrdersReducer from "./sales/salesOrdersSlice";
import searchProductsReducer from "./products/searchProductsSlice";
import softwareReducer from "./software/softwareSlice";
import statesReducer from "./states/statesSlice";
import stockAddReducer from "./products/stockAdd";
import supportReducer from "./support/supportSlice";
import supportMessagesReducer from "./support/supportMessagesSlice";
import tabsReducer from "./tabs/tabsSlice";
import usersSlice from "./users/usersSlice";

export default combineReducers({
	account: accountReducer,
	addPartner: addPartnerReducer,
	addPartnerCoupon: addPartnerCouponReducer,
	blockedProducts: blockedProductsReducer,
	contactMessages: contactMessagesReducer,
	contiPartnerList: contiPartnerListReducer,
	contiPartners: contiPartnersReducer,
	creditOrders: creditOrdersReducer,
	creditOrdersRelease: creditOrdersReleaseReducer,
	dealerLocationOrders: dealerLocationOrdersReducer,
	dealerLocationOrdersReceive: dealerLocationOrdersReceiveReducer,
	dealerLocations: dealerLocationsReducer,
	dealerOrderDelivery: dealerOrderDeliveryReducer,
	dealerOrders: dealerOrdersReducer,
	dealerOrdersDownload: dealerOrdersDownloadReducer,
	hrOrderDelivery: hrOrderDeliveryReducer,
	hrOrders: hrOrdersReducer,
	hrOrdersReceive: hrOrdersReceiveReducer,
	inventory: inventoryReducer,
	locations: locationsReducer,
	login: loginReducer,
	salesOrderCancel: salesOrderCancelReducer,
	salesOrders: salesOrdersReducer,
	searchProducts: searchProductsReducer,
	session: sessionReducer,
	software: softwareReducer,
	states: statesReducer,
	stockAdd: stockAddReducer,
	support: supportReducer,
	supportMessages: supportMessagesReducer,
	tabs: tabsReducer,
	users: usersSlice,
});
