import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";
import { setSelectedItem, fetchContiPartners } from "./contiPartnersSlice";
import { fetchPartnerList } from "./contiPartnerListSlice";

const initialState = {
	isOpen: false,
	isLoading: false,
	errorMsg: null,
	factor: "",
	alias: "",
	name: "",
};
const addPartner = createSlice({
	name: "addPartner",
	initialState,
	reducers: {
		requestAddContiPartner(state) {
			state.isLoading = true;
		},
		receiveAddContiPartner(state) {
			state.isLoading = false;
		},
		toggleShowAddContiPartner(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		setAddContiPartnerFactor(state, action) {
			state.factor = action.payload.factor;
		},
		setAddContiPartnerAlias(state, action) {
			state.alias = action.payload.alias;
		},
		setAddContiPartnerName(state, action) {
			state.name = action.payload.name;
		},
		setAddContiPartnerErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearAddContiPartnerData: () => initialState,
	},
});

export const {
	requestAddContiPartner,
	receiveAddContiPartner,
	toggleShowAddContiPartner,
	setAddContiPartnerFactor,
	setAddContiPartnerAlias,
	setAddContiPartnerName,
	setAddContiPartnerErrorMsg,
	clearAddContiPartnerData,
} = addPartner.actions;

export const fetchAddContiPartner =
	(alias, name, factor) => async (dispatch) => {
		sessionService.loadSession().then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const data = JSON.stringify({
				alias: alias,
				nombre: name,
				incremento: Number(factor),
			});
			dispatch(requestAddContiPartner());
			const url = BASE_API_URL + "/api/Manager/ContiPartners";
			axios
				.post(url, data, options)
				.then((response) => {
					dispatch(receiveAddContiPartner());
					dispatch(setSelectedItem({ selectedItem: response.data }));
					dispatch(fetchContiPartners());
					dispatch(fetchPartnerList());
					dispatch(toggleShowAddContiPartner({ isOpen: true }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					if (error.status === 400) {
						dispatch(
							setAddContiPartnerErrorMsg({
								errorMsg: {
									system: "Ocurrió un error al intentar crear el Partner.",
								},
							})
						);
					}
				});
		});
	};

export default addPartner.reducer;
