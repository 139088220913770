import { connect } from "react-redux";
import HrDelivery from "./HrDelivery";
import {
	fetchHrOrderDelivery,
	fetchHrOrderDeliveryDownload,
	setHrOrderDeliveryErrorMsg,
	clearHrOrderDelivery,
	setHrOrderDeliverySuccess,
} from "features/hr/hrOrderDeliverySlice";
import {
	validateOrderNumber,
	validateEmployeeNumber,
} from "features/functions";

const mapStateToProps = (state) => {
	const { tabList, activeTab } = state.tabs;
	const {
		isLoading,
		order,
		errorMsg,
		success,
		employee,
	} = state.hrOrderDelivery;
	return {
		isLoading,
		order,
		errorMsg,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
		success,
		employee,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSearchOrder: (data) => {
			let valid = true;
			let errorMsg = {};
			if (data.orderNumber.trim() === "") {
				errorMsg = {
					...errorMsg,
					search: "Debes escribir el número del pedido.",
				};
				valid = false;
			} else {
				if (!validateOrderNumber(data.orderNumber.trim())) {
					errorMsg = {
						...errorMsg,
						search: "El número del pedido no es válido.",
					};
					valid = false;
				}
			}
			if (data.employeeNumber.trim() === "") {
				errorMsg = {
					...errorMsg,
					employee: "Debes escribir el número del empleado.",
				};
				valid = false;
			} else {
				if (!validateEmployeeNumber(data.employeeNumber.trim())) {
					errorMsg = {
						...errorMsg,
						employee: "El número del empleado no es válido.",
					};
					valid = false;
				}
			}
			if (valid) {
				dispatch(fetchHrOrderDelivery(data));
			} else {
				dispatch(setHrOrderDeliveryErrorMsg({ errorMsg }));
			}
		},
		onSetErrorMsg: (errorMsg) => {
			dispatch(setHrOrderDeliveryErrorMsg({ errorMsg }));
		},
		onCancel: () => {
			dispatch(clearHrOrderDelivery());
		},
		onSuccessClick: () => {
			dispatch(setHrOrderDeliverySuccess({ success: null }));
		},
		onDownload: (data) => {
			dispatch(fetchHrOrderDeliveryDownload(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HrDelivery);
