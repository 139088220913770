import { sessionService } from "redux-react-session";
import { runUnauthorizedActions } from "features/login/loginSlice";

/**
 * Session utils class
 */
export class SessionUtils {
  /**
   *
   */
  static store;
  /**
   *
   * @param {*} store
   */
  static setStore(store) {
    SessionUtils.store = store;
  }
}
// dispatch();
/**
 * Remove session data from `sessionService` and redirect.
 */
export async function removeSession() {
  // Try remove with error handling.
  try {
    // Delete session.
    await sessionService.deleteSession();
    // delete user.
    await sessionService.deleteUser();

    // TODO: Clean from slices.
    SessionUtils.store?.dispatch(runUnauthorizedActions(true));
  } catch (error) {
    // Session unavailable.
  }
}
