import React from "react";
import { format, parseISO } from "date-fns";
import es from "date-fns/locale/es";
import { TAB_TYPE } from "features/constants";
import { currencyFormat } from "features/functions";
import Loader from "components/loader/Loader";

const formatShipAddres = (a) => {
	const number = a.Interior !== "" ? a.Numero + " - " + a.Interior : a.Numero;
	return (
		<div>
			{a.Nombre}
			<br />
			{a.Calle + number}
			<br />
			{a.Colonia}
			<br />
			{a.CodigoPostal + " " + a.Ciudad}
			<br />
			{a.Estado}
			<br />
			{a.Referencias}
		</div>
	);
};
const formatInvoiceAddres = (a) => {
	const number = a.Interior !== "" ? a.Numero + " - " + a.Interior : a.Numero;
	return (
		<div>
			{a.Nombre}
			<br />
			{a.RazonSocial}
			<br />
			{a.Rfc}
			<br />
			{a.Calle + number}
			<br />
			{a.Colonia}
			<br />
			{a.CodigoPostal + " " + a.Ciudad}
			<br />
			{a.Estado}
			<br />
			{a.Referencias}
		</div>
	);
};
export default class SalesOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = { reason: "" };
	}
	handleReasonChange = (e) => {
		const { errorMsg } = this.props;
		if (errorMsg != null && errorMsg.reason != null) {
			this.props.onSetErrorMsg({ ...errorMsg, reason: null, error: null });
		}
		this.setState({ reason: e.target.value });
	};
	handleCancelOrder = () => {
		const { order } = this.props;
		const { reason } = this.state;
		this.props.onCancel(order.Pedido, reason);
		this.setState({ reason: "" });
	};
	renderError = (error) => {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	};
	renderErrorList = () => {
		const { errorMsg } = this.props;
		if (errorMsg != null) {
			return (
				<div>
					<div className="error-list">
						<ul>
							{this.renderError(errorMsg.error)}
							{this.renderError(errorMsg.reason)}
						</ul>
					</div>
				</div>
			);
		}
	};
	renderCancel = () => {
		const { order } = this.props;
		if (order.IdEstatus === 18 
			|| order.IdEstatus === 20 
			|| order.IdEstatus === 21 
			|| order.IdEstatus === 40) {
			return (
				<div className="order-actions">
					{this.renderErrorList()}
					<div>
						<input
							type="text"
							value={this.state.reason}
							onChange={this.handleReasonChange}
							placeholder="Motivo de cancelación"
						/>
					</div>
					<div>
						<div className="button main" onClick={this.handleCancelOrder}>
							Cancelar pedido
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	renderAddress = () => {
		const { order } = this.props;
		return (
			<div className="order-address">
				<div>
					<div>Dirección de Envío</div>
					{formatShipAddres(order.DireccionEnvio)}
				</div>
				<div>
					<div>Dirección de Facturación</div>
					{formatInvoiceAddres(order.DireccionFacturacion)}
				</div>
			</div>
		);
	};
	renderTotals = () => {
		const { order } = this.props;
		return (
			<div className="order-totals">
				<div>
					<div>Subtotal</div>
					<div>{currencyFormat(order.Importe)}</div>
				</div>
				<div>
					<div>IVA</div>
					<div>{currencyFormat(order.Impuestos)}</div>
				</div>
				<div>
					<div>Envío</div>
					<div>{currencyFormat(order.Envio)}</div>
				</div>
				<div>
					<div className="totals">Total</div>
					<div className="totals">{currencyFormat(order.Total)}</div>
				</div>
			</div>
		);
	};
	renderOrder = () => {
		const { order, isLoading } = this.props;
		if (!isLoading && order != null) {
			const articles = order.Unidades === 1 ? " Artículo" : " Artículos";
			const deliveryDate =
				order.FechaDelivery != null
					? format(parseISO(order.FechaDelivery), "dd/MMM/yyyy hh:mm aaaa", {
							locale: es,
					  })
					: "";
			return (
				<div className="order">
					{this.renderCancel()}
					<div className="order-info">
						<div className="order-number">
							<div>Número de pedido</div>
							<div>{order.Pedido}</div>
						</div>
						<div className="order-data">
							<div>
								<div>Fecha y Hora</div>
								<div>
									{format(parseISO(order.Fecha), "dd/MMM/yyyy hh:mm aaaa", {
										locale: es,
									})}
								</div>
								<div>Tipo de Cliente</div>
								<div>{order.Cliente.TipoUsuario}</div>
								<div>Método de Envío</div>
								<div>{order.MetodoEnvio}</div>
							</div>
							<div>
								<div>Estatus</div>
								<div>{order.Estatus}</div>
								<div>Nombre</div>
								<div>{order.Cliente.NombreCompleto}</div>
								<div>Delivery</div>
								<div>{order.Delivery}</div>
							</div>
							<div>
								<div>Método de Pago</div>
								<div>{order.MetodoPago}</div>
								<div>Correo</div>
								<div>{order.Cliente.Correo}</div>
								<div>Fecha Delivery</div>
								<div>{deliveryDate}</div>
							</div>
							<div>
								<div>Meses sin intereses</div>
								<div>{order.PromoTC}</div>
								<div>Teléfono</div>
								<div>{order.Cliente.Telefono}</div>
								<div>Promo</div>
								<div>{order.Campania}</div>
							</div>
							<div>
								<div>Última modificación</div>
								<div>
									{format(
										parseISO(order.Modificacion),
										"dd/MMM/yyyy hh:mm aaaa",
										{ locale: es }
									)}
								</div>
								<div>Número de Empleado</div>
								<div>{order.Cliente.NumEmpleado.substring(1)}</div>
								<div>Carrier</div>
								<div>{order.Carrier}</div>
							</div>
							<div>
								<div>Pedido SAP</div>
								<div>{order.PedidoSap}</div>
								<div>Localidad</div>
								<div>{order.Localidad}</div>
								<div>Guía</div>
								<div>{order.Guia}</div>
							</div>
						</div>
					</div>
					<div className="order-items">{order.Unidades + articles}</div>
					<div className="order-details">
						<div className="details-header">
							<div className="center">Cant.</div>
							<div className="center">Código</div>
							<div>Producto</div>
							<div>Medida</div>
							<div className="right">Precio</div>
							<div className="right">Importe</div>
						</div>
						{order.Detalles.map((el, i) => {
							return (
								<div className="details" key={i}>
									<div className="center">{el.Cantidad}</div>
									<div className="center">{el.Kartis}</div>
									<div>{el.Marca + " " + el.Producto}</div>
									<div>{el.Medida}</div>
									<div className="right">{currencyFormat(el.Precio)}</div>
									<div className="right">
										{currencyFormat(el.Precio * el.Cantidad)}
									</div>
								</div>
							);
						})}
					</div>
					<div className="order-summary">
						<div>
							<div>{this.renderAddress()}</div>
							<div>{this.renderTotals()}</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	renderHistoryHeaders = () => {
		return (
			<thead>
				<tr>
					<th>#</th>
					<th>
						Fecha<span className="icon-sort-inverse"></span>
					</th>
					<th>Estatus</th>
					<th>Usuario</th>
					<th>Localidad</th>
					<th>Sucursal</th>
				</tr>
			</thead>
		);
	};
	renderHistory = () => {
		const { order, isLoading } = this.props;
		if (!isLoading && order != null && order.Historial.length > 0) {
			return (
				<div className="orders-table">
					<div>
						<h3>Historial</h3>
					</div>
					<div className="orders-table-content">
						<table cellPadding="0" cellSpacing="0" border="0">
							{this.renderHistoryHeaders()}
							<tbody>
								{order.Historial.map((el, i) => {
									return (
										<tr key={i}>
											<td>{i + 1}</td>
											<td>
												{format(parseISO(el.Fecha), "dd/MMM/yyyy hh:mm aaaa", {
													locale: es,
												})}
											</td>
											<td>{el.Estatus}</td>
											<td>{el.Usuario}</td>
											<td>{el.Localidad}</td>
											<td>{el.Sucursal}</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="6">&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
		return null;
	};
	renderOpenPayHeaders = () => {
		return (
			<thead>
				<tr>
					<th>Autorización<span className="icon-sort-inverse"></span></th>
					<th>Estatus</th>
					<th>Tarjeta</th>
					<th>Tipo</th>
					<th>Marca</th>
					<th>Titular</th>
					<th>Banco</th>
					<th>MSI</th>
					<th>CodigoError</th>
					<th>Error</th>
					<th>Descripción</th>
				</tr>
			</thead>
		);
	};
	renderOpenPay = () => {
		const { order, isLoading } = this.props;
		if (!isLoading && order != null && order.OpenPayLog.length > 0) {
			return (
				<div className="orders-table">
					<div>
						<h3>Log Open Pay</h3>
					</div>
					<div className="orders-table-content">
						<table cellPadding="0" cellSpacing="0" border="0">
							{this.renderOpenPayHeaders()}
							<tbody>
								{order.OpenPayLog.map((el, i) => {
									return (
										<tr key={i}>
											<td>{el.Autorizacion}</td>
											<td>{el.Status}</td>
											<td>{el.Tarjeta}</td>
											<td>{el.TipoTarjeta}</td>
											<td>{el.Marca}</td>
											<td>{el.Nombre}</td>
											<td>{el.Banco}</td>
											<td>{el.Msi === 0 ? "" : el.Msi}</td>
											<td>{el.CodigoError}</td>
											<td>{el.Error}</td>
											<td>{el.DescripcionError}</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="9">&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
		return null;
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	render = () => {
		const { activeTabItem } = this.props;
		if (activeTabItem != null && activeTabItem.type === TAB_TYPE.Order) {
			return (
				<section className="sales">
					<div>
						<h1>Detalles de Pedido</h1>
					</div>
					{this.renderLoader()}
					{this.renderOrder()}
					{this.renderHistory()}
					{this.renderOpenPay()}
				</section>
			);
		}
		return null;
	};
}
