import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const initialState = {
	isLoading: false,
	list: [],
	selectedItem: null,
	couponList: [],
};
const contiPartners = createSlice({
	name: "contiPartners",
	initialState,
	reducers: {
		requestContiPartners(state) {
			state.isLoading = true;
		},
		receiveContiPartners(state, action) {
			state.list = action.payload.list;
			state.isLoading = false;
		},
		receiveCouponList(state, action) {
			state.couponList = action.payload.couponList;
			state.isLoading = false;
		},
		setSelectedItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
		},
		clearSelectedItem(state) {
			state.selectedItem = null;
			state.couponList = [];
		},
		clearContiParntersData: () => initialState,
	},
});

export const {
	requestContiPartners,
	receiveContiPartners,
	receiveCouponList,
	setSelectedItem,
	clearSelectedItem,
	clearContiParntersData,
} = contiPartners.actions;

export const fetchContiPartners = () => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			headers: headers,
		};
		dispatch(requestContiPartners());
		const url = BASE_API_URL + "/api/Manager/ContiPartners";
		axios
			.get(url, options)
			.then((response) => {
				dispatch(receiveContiPartners({ list: response.data }));
			})
			.catch((error) => {
				dispatch(receiveContiPartners({ list: [] }));
				// If 401 Unauthorized login failed
				if (error.status === 401) {
					dispatch(runUnauthorizedActions());
				}
			});
	});
};
export const fetchContiPartnerCoupons = (id) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			headers: headers,
		};
		dispatch(requestContiPartners());
		const url = BASE_API_URL + "/api/Manager/ContiPartners/Cupones/" + id;
		axios
			.get(url, options)
			.then((response) => {
				dispatch(receiveCouponList({ couponList: response.data }));
			})
			.catch((error) => {
				// If 401 Unauthorized login failed
				if (error.status === 401) {
					dispatch(runUnauthorizedActions());
				}
			});
	});
};

export default contiPartners.reducer;
