import React from 'react'
import MainMenu from '../mainmenu/MainMenuContainer'

export default class Header extends React.Component {
    render = () => {
        const { user } = this.props
				const name = user.user != null ? user.user.Name : "usuario"
        return (
            <header id="header">
                <div>
                    <div><span className="main-logo"></span></div>
                    <div>Conti<em>Shop</em> Manager</div>
                    <MainMenu userName={name}/>
                </div>
            </header>
        )
    }
}