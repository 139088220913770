import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL, USER_PROFILE } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const dealerOrderDelivery = createSlice({
	name: "dealerOrderDelivery",
	initialState: {
		order: null,
		isLoading: false,
		errorMsg: null,
		success: null,
		employee: null,
	},
	reducers: {
		requestDealerOrderDelivery(state) {
			state.isLoading = true;
		},
		receiveDealerOrderDelivery(state, action) {
			state.isLoading = false;
			state.order = action.payload.order;
		},
		receiveDealerOrderDeliveryDownload(state) {
			state.isLoading = false;
		},
		setDealerOrderDeliveryErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		setDealerOrderDeliverySuccess(state, action) {
			state.success = action.payload.success;
		},
		setDealerOrderDeliveryEmployee(state, action) {
			state.employee = action.payload.employee;
		},
		cancelDealerOrderDelivery(state) {
			state.order = null;
			state.errorMsg = null;
		},
		clearDealerOrderDelivery(state) {
			state.order = null;
			state.errorMsg = null;
			state.isLoading = false;
			state.success = null;
			state.employee = null;
		},
	},
});

export const {
	requestDealerOrderDelivery,
	receiveDealerOrderDelivery,
	receiveDealerOrderDeliveryDownload,
	setDealerOrderDeliveryErrorMsg,
	setDealerOrderDeliverySuccess,
	setDealerOrderDeliveryEmployee,
	cancelDealerOrderDelivery,
	clearDealerOrderDelivery,
} = dealerOrderDelivery.actions;

export const fetchDealerOrderDelivery = (data) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		let headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		let options = {
			method: "GET",
			headers: headers,
		};
		dispatch(requestDealerOrderDelivery());
		let responseStatus = 0;
		const employee =
			data.employeeNumber !== "" ? "&numempleado=" + data.employeeNumber : "";
		const queryString = "?id=" + data.orderNumber + employee;
		const methodUrl = "/api/Manager/PedidosDistribuidor/Entrega" + queryString;
		return fetch(BASE_API_URL + methodUrl, options).then((response) => {
			responseStatus = response.status;
			return response.ok
				? response.json().then((json) => {
						dispatch(receiveDealerOrderDelivery({ order: json }));
						dispatch(setDealerOrderDeliveryErrorMsg({ errorMsg: null }));
						if (data.employeeNumber != null)
							dispatch(
								setDealerOrderDeliveryEmployee({
									employee: data.employeeNumber,
								})
							);
				  })
				: response
						.text()
						.then((error) => Promise.reject(error))
						.catch((error) => {
							// TODO: fetch error actions
							// If 401 Unauthorized login failed
							if (responseStatus === 401) {
								dispatch(runUnauthorizedActions());
							}
							// If 404 Not Found
							if (responseStatus === 404) {
								dispatch(
									setDealerOrderDeliveryErrorMsg({
										errorMsg: {
											search:
												"No se pudo encotrar el pedido o no está disponible para entrega.",
										},
									})
								);
							}
							// If 400 Bad Request
							if (responseStatus === 400) {
								const json = JSON.parse(error);
								dispatch(
									setDealerOrderDeliveryErrorMsg({
										errorMsg: { employee: json.Message },
									})
								);
							}
							dispatch(receiveDealerOrderDelivery({ order: null }));
						});
		});
	});
};

export const fetchDealerOrderDeliveryDownload = (data) => async (
	dispatch,
	getState
) => {
	sessionService.loadSession().then((session) => {
		const theState = getState();
		let headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		let options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				Pedido: data.order,
				Recibe: data.name,
				IdSucursal:
					data.profile === USER_PROFILE.Socio
						? data.selectedLocation.Id
						: null,
				Identificacion: data.idText,
				NumEmpleado: theState.dealerOrderDelivery.employee,
			}),
		};
		dispatch(requestDealerOrderDelivery());
		let responseStatus = 0;
		const methodUrl = "/api/Manager/PedidosDistribuidor/Entrega";
		return fetch(BASE_API_URL + methodUrl, options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.text();
				} else {
					return Promise.reject(
						"No se pudo registrar la entrega, verifique que los datos sean correctos."
					);
				}
			})
			.then((text) => {
				const link = document.createElement("a");
				const url =
					"data:application/octec-stream;charset=utf-8;base64," + text;
				link.href = url;
				link.download = "EntregaPedidoContiShop-" + data.order + ".pdf";
				link.click();
				dispatch(receiveDealerOrderDeliveryDownload());
				dispatch(clearDealerOrderDelivery());
				dispatch(
					setDealerOrderDeliverySuccess({ success: { order: data.order } })
				);
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				if (responseStatus === 400) {
					dispatch(setDealerOrderDeliveryErrorMsg({ errorMsg: { error } }));
					dispatch(receiveDealerOrderDeliveryDownload());
				}
			});
	});
};

export default dealerOrderDelivery.reducer;
