import React from "react";
import Modal from "react-modal";
import Dropdown from "react-dropdown";
import Loader from "components/loader/Loader";
import { numberFormat } from "features/functions";

const warehouseOptions = [
	{ label: "MXTX", value: 1 },
	{ label: "MXCO", value: 3 },
];
export default class AddBlockedProductModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { warehouse: 1, reason: "" };
	}
	handleClose = () => {
		this.props.onClose();
	};
	handleBlock = () => {
		const { selectedItem, source } = this.props;
		const { warehouse, reason } = this.state;
		if (source != null && source === "search") {
			this.props.onBlock({
				code: selectedItem.Codigo,
				warehouse,
				reason,
				source,
			});
		}
		if (source != null && source === "inventory") {
			this.props.onBlock({
				code: selectedItem.Code,
				warehouse,
				reason,
				source,
			});
		}
		this.setState({ reason: "" });
	};
	handleWarehouseChange = (option) => {
		this.setState({ warehouse: option.value });
	};
	handleReasonChange = (e) => {
		this.setState({ reason: e.target.value });
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderDropdown = () => {
		const { warehouse } = this.state;
		const warehouseDefaultOption =
			warehouse != null
				? warehouseOptions.filter((opc) => opc.value === warehouse)[0]
				: warehouseOptions.filter((opc) => opc.value === 0)[0];
		return (
			<div>
				<div style={{ padding: "5px 0" }}>
					<label>Almacén para bloquear</label>
					<Dropdown
						options={warehouseOptions}
						value={warehouseDefaultOption}
						onChange={this.handleWarehouseChange}
						placeholder={warehouseDefaultOption.label}
					/>
				</div>
			</div>
		);
	};
	renderReason = () => {
		return (
			<div>
				<div style={{ padding: "5px 0" }}>
					<label>Motivo del bloqueo: </label>
					<input
						type="text"
						placeholder="Escribe el motivo por el cuál bloqueas este producto."
						style={{ textAlign: "left" }}
						value={this.state.reason}
						onChange={this.handleReasonChange}
					></input>
				</div>
			</div>
		);
	};
	renderSubmit = () => {
		const { reason } = this.state;
		if (reason.trim().length > 10) {
			return (
				<div>
					<div className="button" onClick={this.handleClose}>
						Cerrar
					</div>
					<div className="button main" onClick={this.handleBlock}>
						Bloquear
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className="button" onClick={this.handleClose}>
					Cerrar
				</div>
				<div className="button disabled">Bloquear</div>
			</div>
		);
	};
	renderItemInventory = () => {
		const { isLoading, selectedItem, source } = this.props;
		if (!isLoading && selectedItem != null && source === "inventory") {
			return (
				<div>
					<h3>
						{selectedItem.Brand}
						<br />
						<strong>{selectedItem.Product}</strong>
						<br />
						<strong>
							<span>{selectedItem.Size}</span>
						</strong>
						<br />
						{selectedItem.Code}
					</h3>
					<p>
						<strong>Inventarios actuales</strong>
						<br />
						<span>{"MXTX"}</span>
						<br />
						<strong>
							{selectedItem.Mxtx > 0 ? numberFormat(selectedItem.Mxtx) : "-"}
						</strong>
						<br />
						<span>{"MXCO"}</span>
						<br />
						<strong>
							{selectedItem.Mxco > 0 ? numberFormat(selectedItem.Mxco) : "-"}
						</strong>
					</p>
					<div className="form-a full">
						{this.renderDropdown()}
						{this.renderReason()}
					</div>
					{this.renderSubmit()}
				</div>
			);
		}
	};
	renderItemSearch = () => {
		const { isLoading, selectedItem, source } = this.props;
		if (!isLoading && selectedItem != null && source === "search") {
			return (
				<div>
					<h3>
						{selectedItem.Marca}
						<br />
						<strong>{selectedItem.Producto}</strong>
						<br />
						<strong>
							<span>{selectedItem.Medida}</span>
						</strong>
						<br />
						{selectedItem.Codigo}
					</h3>
					<p>
						<strong>Inventarios actuales</strong>
						<br />
						<span>{"MXTX"}</span>
						<br />
						<strong>
							{selectedItem.Sap > 0 ? numberFormat(selectedItem.Sap) : "-"}
						</strong>
						<br />
						<span>{"MXCO"}</span>
						<br />
						<strong>
							{selectedItem.Mxco > 0 ? numberFormat(selectedItem.Mxco) : "-"}
						</strong>
					</p>
					<div className="form-a full">
						{this.renderDropdown()}
						{this.renderReason()}
					</div>
					{this.renderSubmit()}
				</div>
			);
		}
	};
	render = () => {
		const customStyles = {
			content: {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)",
				minWidth: "550px",
				minHeight: "350px",
			},
			overlay: {
				backgroundColor: "rgb(0,0,0,.7)",
				zIndex: "2",
			},
		};
		const { isOpen } = this.props;
		if (isOpen) {
			return (
				<div>
					<Modal
						isOpen={isOpen}
						onRequestClose={this.handleClose}
						style={customStyles}
						contentLabel="Bloquear Producto"
					>
						<div className="conti-modal">
							<h2>Bloquear Producto</h2>
							{this.renderItemSearch()}
							{this.renderItemInventory()}
							{this.renderLoader()}
						</div>
					</Modal>
				</div>
			);
		}
		return null;
	};
}
