import React from "react";
import Loader from "../../loader/Loader";
import CreditOrdersItem from "./CreditOrdersItem";
import { MAIN_TABS, PAYMENT_METHODS } from "features/constants";
import { currencyFormat } from "features/functions";

const p = PAYMENT_METHODS;
const calcTotals = (list) => {
	if (list == null || list.length === 0) {
		return {
			orders: 0,
			orderString: "Pedidos",
		};
	} else {
		const orders = list.length;
		const orderString = orders === 1 ? "Pedido" : "Pedidos";
		return {
			orders: orders,
			orderString: orderString,
		};
	}
};
const calcSelected = (list) => {
	if (list == null || list.length === 0) {
		return {
			orders: 0,
			amount: 0,
			orderString: "Pedidos",
		};
	} else {
		const filteredList = list.filter((item) => item.isChecked === true);
		const amount = filteredList.reduce((a, b) => a + b["Total"], 0);
		const orders = filteredList.length;
		const orderString = orders === 1 ? "Pedido" : "Pedidos";
		return {
			orders: orders,
			amount: amount,
			orderString: orderString,
		};
	}
};
const allChecked = (list) => {
	let checkedArray = [];
	if (list != null && list.length > 0) {
		for (let i = 0; i < list.length; i++) {
			checkedArray.push({ ...list[i], isChecked: true });
		}
	}
	return checkedArray;
};
const allUnChecked = (list) => {
	let checkedArray = [];
	if (list != null && list.length > 0) {
		for (let i = 0; i < list.length; i++) {
			checkedArray.push({ ...list[i], isChecked: false });
		}
	}
	return checkedArray;
};
const arrayFindByProp = (array, prop, value) => {
	for (let i = 0; i < array.length; i++) {
		if (array[i][prop] === value) {
			return i;
		}
	}
	return -1;
};
export default class CreditOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			list: allUnChecked(this.props.ccList),
		};
		this.checkAll = React.createRef();
	}
	// Validar si cambian los datos
	static getDerivedStateFromProps(props, state) {
		let list = [];
		switch (props.type) {
			case p.CREDITCARD:
			default:
				list = props.ccList;
				break;
			case p.PAYPAL:
				list = props.ppList;
				break;
			case p.PAYNET:
				list = props.pnList;
				break;
			case p.SAFETYPAY:
				list = props.spList;
				break;
		}
		if (list != null && list.length !== state.list.length) {
			return { list: allUnChecked(list) };
		}
		return null;
	}
	handleCheckChange = (order, isChecked) => {
		let newList = this.state.list;
		const index = arrayFindByProp(newList, "Pedido", order);
		newList[index] = { ...newList[index], isChecked: isChecked };
		this.setState({ list: newList });
		const checkedList = newList.filter((item) => item.isChecked === true);
		if (checkedList.length === newList.length) {
			this.checkAll.current.checked = true;
		} else {
			this.checkAll.current.checked = false;
		}
	};
	handleCheckAll = (e) => {
		if (e.target.checked) {
			this.setState({ list: allChecked(this.state.list) });
		} else {
			this.setState({ list: allUnChecked(this.state.list) });
		}
	};
	handleDownloadClick = () => {
		const selected = this.state.list.filter((i) => i.isChecked === true);
		this.props.onRelease(selected, this.props.type);
	};
	handleCreditCardClick = () => {
		const { ccList } = this.props;
		this.props.onTypeSelect(p.CREDITCARD);
		let newList = allUnChecked(ccList);
		this.setState({ list: newList });
		const checkedList = newList.filter((item) => item.isChecked === true);
		if (newList.length === 0) {
			this.checkAll.current.checked = false;
		} else {
			if (checkedList.length === newList.length) {
				this.checkAll.current.checked = true;
			} else {
				this.checkAll.current.checked = false;
			}
		}
	};
	handlePayPalClick = () => {
		const { ppList } = this.props;
		this.props.onTypeSelect(p.PAYPAL);
		let newList = allUnChecked(ppList);
		this.setState({ list: newList });
		const checkedList = newList.filter((item) => item.isChecked === true);
		if (newList.length === 0) {
			this.checkAll.current.checked = false;
		} else {
			if (checkedList.length === newList.length) {
				this.checkAll.current.checked = true;
			} else {
				this.checkAll.current.checked = false;
			}
		}
	};
	handlePayNetClick = () => {
		const { pnList } = this.props;
		this.props.onTypeSelect(p.PAYNET);
		let newList = allUnChecked(pnList);
		this.setState({ list: newList });
		const checkedList = newList.filter((item) => item.isChecked === true);
		if (newList.length === 0) {
			this.checkAll.current.checked = false;
		} else {
			if (checkedList.length === newList.length) {
				this.checkAll.current.checked = true;
			} else {
				this.checkAll.current.checked = false;
			}
		}
	};
	handleSafetyPayClick = () => {
		const { spList } = this.props;
		this.props.onTypeSelect(p.SAFETYPAY);
		let newList = allUnChecked(spList);
		this.setState({ list: newList });
		const checkedList = newList.filter((item) => item.isChecked === true);
		if (newList.length === 0) {
			this.checkAll.current.checked = false;
		} else {
			if (checkedList.length === newList.length) {
				this.checkAll.current.checked = true;
			} else {
				this.checkAll.current.checked = false;
			}
		}
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>
						<div className="check-switch-black">
							<input
								type="checkbox"
								id="select-all"
								name="select-all"
								value="on"
								onChange={this.handleCheckAll}
								ref={this.checkAll}
							/>
							<label htmlFor="select-all">
								<span className="icon-switch"></span>
							</label>
						</div>
					</th>
					<th>
						Pedido<span className="icon-sort-inverse"></span>
					</th>
					<th className="right">Importe</th>
					<th>Fecha</th>
					<th>Estatus</th>
					<th>Método de Pago</th>
					<th>SAP</th>
					<th className="left">Cliente</th>
					<th>Tipo</th>
					<th className="left">Localidad</th>
				</tr>
			</thead>
		);
	};
	renderOrders = () => {
		const { isLoading, isLoadingReceive, list } = this.state;
		if (!isLoading && !isLoadingReceive && list != null) {
			if (list.length > 0) {
				return (
					<table cellPadding="0" cellSpacing="0" border="0">
						{this.renderTableHeaders()}
						{list.map((el, i) => {
							return (
								<CreditOrdersItem
									key={i}
									item={el}
									isChecked={el.isChecked}
									onClick={this.handleCheckChange}
									onChange={this.handleItemStatusChange}
								/>
							);
						})}
						<tfoot>
							<tr>
								<td colSpan="10">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				);
			}
			return (
				<table cellPadding="0" cellSpacing="0" border="0">
					{this.renderTableHeaders()}
					<tbody>
						<tr>
							<td colSpan="10">No se encontraron pedidos.</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="10">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			);
		}
		return null;
	};
	renderLoader = () => {
		const {
			type,
			isLoadingCC,
			isLoadingPP,
			isLoadingPN,
			isLoadingSP,
			isLoadingRelease,
		} = this.props;
		if (
			(type === p.CREDITCARD && isLoadingCC) ||
			(type === p.PAYPAL && isLoadingPP) ||
			(type === p.PAYNET && isLoadingPN) ||
			(type === p.SAFETYPAY && isLoadingSP)
		) {
			return <Loader msg="Cargando datos..." />;
		}
		if (isLoadingRelease) {
			return <Loader msg="Preparando descarga..." />;
		}
		return null;
	};
	renderDownload = () => {
		const { isLoading, isLoadingReceive } = this.props;
		const stateList = this.state.list;
		const selected =
			stateList != null
				? stateList.filter((item) => item.isChecked === true)
				: [];
		const buttonText = "Confirmar pago y descargar";
		if (
			!isLoading &&
			!isLoadingReceive &&
			stateList != null &&
			stateList.length > 0
		) {
			if (selected.length > 0) {
				return (
					<div className="form-download">
						<div>
							<div className="button main" onClick={this.handleDownloadClick}>
								{buttonText}
							</div>
						</div>
					</div>
				);
			}
			return (
				<div className="form-download">
					<div>
						<div className="button main disabled">{buttonText}</div>
					</div>
				</div>
			);
		}
		return null;
	};
	renderOrdersSummary = () => {
		const { isLoadingReceive } = this.props;
		const { list } = this.state;
		const orderCount = calcTotals(list);
		if (!isLoadingReceive) {
			return (
				<div className="orders-table-summary">
					{orderCount.orders + " " + orderCount.orderString}
				</div>
			);
		}
	};
	renderOrdersTitle = () => {
		const { isLoadingReceive } = this.props;
		const { list } = this.state;
		const orderSelected = calcSelected(list);
		if (!isLoadingReceive) {
			return (
				<div className="orders-table-title">
					<div>Selección:</div>
					<div className="detail">
						<span>{orderSelected.orders}</span> {orderSelected.orderString}
					</div>
					<div className="detail">
						<span>{currencyFormat(orderSelected.amount)}</span>
					</div>
				</div>
			);
		}
	};
	renderFilterTabs = () => {
		const { ccList, ppList, pnList, spList, type } = this.props;
		const buttonProps = {
			ccProps:
				type === p.CREDITCARD
					? { className: "active" }
					: { onClick: this.handleCreditCardClick },
			ppProps:
				type === p.PAYPAL
					? { className: "active" }
					: { onClick: this.handlePayPalClick },
			pnProps:
				type === p.PAYNET
					? { className: "active" }
					: { onClick: this.handlePayNetClick },
			spProps:
				type === p.SAFETYPAY
					? { className: "active" }
					: { onClick: this.handleSafetyPayClick },
		};
		return (
			<div className="filter-tabs">
				<div {...buttonProps.ccProps}>
					{p.properties[p.CREDITCARD].name} <span>{ccList.length}</span>
				</div>
				<div {...buttonProps.ppProps}>
					{p.properties[p.PAYPAL].name} <span>{ppList.length}</span>
				</div>
				<div {...buttonProps.pnProps}>
					{p.properties[p.PAYNET].name} <span>{pnList.length}</span>
				</div>
				<div {...buttonProps.spProps}>
					{p.properties[p.SAFETYPAY].name} <span>{spList.length}</span>
				</div>
			</div>
		);
	};
	render = () => {
		const { activeTabItem } = this.props;
		let list;
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.CreditOrders) {
			return (
				<section className="delivery">
					<div>
						<h1>Liberación de pedidos</h1>
						<p>
							Por favor selecciona los pedidos para los que el pago está
							confirmado y oprime el botón para confirmar y descargar.
						</p>
					</div>
					{this.renderFilterTabs()}
					<div className="orders-table">
						{this.renderOrdersSummary()}
						{this.renderOrdersTitle()}
						<div className="orders-table-content-check">
							{this.renderLoader()}
							{this.renderOrders()}
						</div>
					</div>
					{this.renderDownload(list)}
				</section>
			);
		}
		return null;
	};
}
