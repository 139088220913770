import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "features/constants";
import { runUnauthorizedActions } from "features/login/loginSlice";
import { fetchAddOrderTab } from "features/tabs/tabsSlice";

const initialState = {
	isLoading: false,
	order: null,
	errorMsg: null,
};

const support = createSlice({
	name: "support",
	initialState,
	reducers: {
		requestSupportOrder(state) {
			state.isLoading = true;
		},
		receiveSupportOrder(state, action) {
			state.isLoading = false;
			state.order = action.payload.order;
		},
		setSupportErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearSupportData: () => initialState,
	},
});

export const {
	requestSupportOrder,
	receiveSupportOrder,
	setSupportErrorMsg,
	clearSupportData,
} = support.actions;

export const fetchSupportOrder = (order) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Manager/Soporte/Pedidos/" + order;
			dispatch(requestSupportOrder());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveSupportOrder({ order: response.data }));
					dispatch(fetchAddOrderTab(response.data.Pedido));
				})
				.catch((error) => {
					dispatch(receiveSupportOrder({ order: null }));
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					if (error.response.status === 404) {
						dispatch(
							setSupportErrorMsg({
								errorMsg:
									"No se pudo encontrar el pedido " + order + ", verifique el número y vuelva a intentar.",
							})
						);
					}
				});
		})
		.catch((error) => {
			console.log("Error", error);
		});
};

export default support.reducer;
