import React from "react";
import ReactDatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import Loader from "../../loader/Loader";
import { MAIN_TABS } from "features/constants";
import { numberFormat } from "features/functions";
import ContactMessageItem from "./ContactMessageItem";
import ContactMessageDetails from "./ContactMessageDetails";
import PageControls from "components/common/pageControls";

const setTotals = (list, total, page, rows) => {
	if (list == null) {
		return {
			showingFrom: 0,
			showingTo: 0,
			pages: 0,
		};
	}
	const pages = Math.ceil(total / rows);
	const showingFrom = total !== 0 ? page * rows + 1 : 0;
	let showingTo = 0;
	if (total < rows) showingTo = total;
	if (total >= rows) {
		if (page + 1 === pages) {
			showingTo = total;
		} else {
			showingTo = (page + 1) * rows;
		}
	}
	return {
		showingFrom: showingFrom,
		showingTo: showingTo,
		pages: pages,
	};
};
const statusOptions = [
	{ label: "Pendientes", value: 0 },
	{ label: "Cerrados", value: null },
];

export default class ContactMessages extends React.Component {
	handleStatusChange = (option) => {
		this.props.onStatusChange(option.value);
	};
	handleFromChange = (date) => {
		this.props.onFromChange(date);
	};
	handleUntilChange = (date) => {
		this.props.onUntilChange(date);
	};
	handleNext = () => {
		const { total, page, rows, list } = this.props;
		const pages = list != null ? Math.ceil(total / rows) - 1 : 0;
		if (page < pages) {
			this.props.onPage(page + 1);
		}
	};
	handleLast = () => {
		const { total, page, rows, list } = this.props;
		const pages = list != null ? Math.ceil(total / rows) - 1 : 0;
		if (page !== pages) {
			this.props.onPage(pages);
		}
	};
	handlePrev = () => {
		const { page } = this.props;
		if (page > 0) {
			this.props.onPage(page - 1);
		}
	};
	handleFirst = () => {
		const { page } = this.props;
		if (page > 0) {
			this.props.onPage(0);
		}
	};
	handlePageChange = (e) => {
		// const newPage = e.target.value.replace(/[^0-9]/g, "");
		// this.setState({ page: newPage });
	};
	handleClearFilters = () => {
		this.props.onClearFilters();
	};
	renderFilters = () => {
		const {
			status,
			from,
			until,
			total,
			list,
			page,
			rows,
			message,
			isLoading,
			isOpening,
			isEditing,
			isSaving,
		} = this.props;
		if (message != null || isLoading || isOpening || isEditing || isSaving) {
			return null;
		}
		const totals = setTotals(list, total, page, rows);
		const statusDefaultOption = statusOptions.filter(
			(option) => option.value === status ?? null
		)[0];
		return (
			<div className="order-filters">
				<div>
					<div className="field">
						<label>Desde</label>
						<div>
							<ReactDatePicker
								selected={from}
								onChange={this.handleFromChange}
								closeOnScroll={true}
								minDate={new Date(2024, 1, 1)}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="field">
						<label>Hasta</label>
						<div>
							<ReactDatePicker
								selected={until}
								onChange={this.handleUntilChange}
								closeOnScroll={true}
								minDate={new Date(2024, 1, 1)}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="field">
						<label>Estatus</label>
						<Dropdown
							options={statusOptions}
							value={statusDefaultOption}
							onChange={this.handleStatusChange}
							placeholder={statusDefaultOption.label}
						/>
					</div>
				</div>
				<div className="controls">
					<div>
						<PageControls
							currentPage={page + 1}
							totalPages={totals.pages}
							onPageChange={this.handlePageChange}
							onFirst={this.handleFirst}
							onLast={this.handleLast}
							onPrev={this.handlePrev}
							onNext={this.handleNext}
						/>
						<div>
							<div className="button" onClick={this.handleClearFilters}>
								Restablecer Filtros
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	renderLoader = () => {
		const { isLoading } = this.props;
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	};
	renderTableHeaders = () => {
		return (
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Nombre</th>
					<th>Correo</th>
					<th>Mensaje</th>
					<th>Tipo</th>
					<th>Categoría</th>
					<th>Estatus</th>
					<th>Última Modificación</th>
					<th>Fecha Atención</th>
					<th>Atendido por</th>
				</tr>
			</thead>
		);
	};
	renderMessages = () => {
		const cols = 10;
		const { isLoading, total, list, onClick, isOpening, message } = this.props;
		if (isOpening || message != null) {
			return null;
		}
		if (!isLoading && list != null) {
			if (list.length > 0) {
				return (
					<div className="orders-table">
						<div className="orders-table-title">
							<div>
								Mensajes: <span>{numberFormat(total)}</span>
							</div>
						</div>
						<div className="orders-table-content">
							<table cellPadding="0" cellSpacing="0" border="0">
								{this.renderTableHeaders()}
								<tbody>
									{list.map((el, i) => {
										return (
											<ContactMessageItem
												key={i}
												index={i}
												item={el}
												onClick={onClick}
											/>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan={cols}>&nbsp;</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				);
			}
			return (
				<div className="orders-table">
					<div className="orders-table-title">
						<div>
							Mensajes: <span>{numberFormat(total)}</span>
						</div>
					</div>
					<div className="orders-table-content">
						<table cellPadding="0" cellSpacing="0" border="0">
							{this.renderTableHeaders()}
							<tbody>
								<tr>
									<td colSpan={cols}>No hay mensajes que mostrar.</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={cols}>&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
		return null;
	};
	renderMessageDetails = () => {
		const {
			message,
			isOpening,
			onClose,
			isEditing,
			onEdit,
			onSave,
			onSetErrorMsg,
			errorMsg,
			isSaving,
		} = this.props;
		if (isOpening || message != null) {
			return (
				<ContactMessageDetails
					message={message}
					isLoading={isOpening}
					onClose={onClose}
					onEdit={onEdit}
					onSave={onSave}
					onSetErrorMsg={onSetErrorMsg}
					isEditing={isEditing}
					isSaving={isSaving}
					errorMsg={errorMsg}
				/>
			);
		}
		return null;
	};
	render = () => {
		const { isLoading, activeTabItem } = this.props;
		if (isLoading) {
			return <div>{this.renderLoader()}</div>;
		}
		if (activeTabItem != null && activeTabItem.tab === MAIN_TABS.Messages) {
			return (
				<section className="sales">
					<div>
						<h1>Mensajes de Contacto</h1>
					</div>
					{this.renderFilters()}
					{this.renderMessages()}
					{this.renderMessageDetails()}
				</section>
			);
		}
		return null;
	};
}
