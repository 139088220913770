import { connect } from "react-redux";
import {
	setBlockedProductAdd,
	toggleBlockedProductModalAdd,
} from "features/products/blockedProductsSlice";
import {
	clearSearchProducts,
	fetchSearchProducts,
} from "features/products/searchProductsSlice";

import SearchProducts from "./SearchProducts";
import { setStockAddSelectedItem, toggleStockAddModal } from "features/products/stockAdd";

const mapStateToProps = (state) => {
	const { isLoading, list } = state.searchProducts;
	const { tabList, activeTab } = state.tabs;
	return {
		isLoading,
		list,
		activeTabItem: tabList != null ? tabList[activeTab] : null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onSearch: (data) => {
			const list = data.split(",");
			dispatch(fetchSearchProducts(list));
		},
		onClear: () => {
			dispatch(clearSearchProducts());
		},
		onBlock: (item) => {
			dispatch(setBlockedProductAdd({ selectedAdd: item, source: "search" }));
			dispatch(toggleBlockedProductModalAdd({ isOpenAdd: false }));
		},
		onAddStock: (item) => {
			dispatch(setStockAddSelectedItem({ selectedItem: item, source: "search"}));
			dispatch(toggleStockAddModal({ isOpen: false }));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchProducts);
