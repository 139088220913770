import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../login/loginSlice";

const hrOrderDelivery = createSlice({
	name: "hrOrderDelivery",
	initialState: {
		order: null,
		isLoading: false,
		errorMsg: null,
		success: null,
	},
	reducers: {
		requestHrOrderDelivery(state) {
			state.isLoading = true;
		},
		receiveHrOrderDelivery(state, action) {
			state.isLoading = false;
			state.order = action.payload.order;
		},
		receiveHrOrderDeliveryDownload(state) {
			state.isLoading = false;
		},
		setHrOrderDeliveryErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		setHrOrderDeliverySuccess(state, action) {
			state.success = action.payload.success;
		},
		clearHrOrderDelivery(state) {
			state.order = null;
			state.errorMsg = null;
		},
	},
});

export const {
	requestHrOrderDelivery,
	receiveHrOrderDelivery,
	receiveHrOrderDeliveryDownload,
	setHrOrderDeliveryErrorMsg,
	setHrOrderDeliverySuccess,
	setHrOrderDeliveryEmployee,
	clearHrOrderDelivery,
} = hrOrderDelivery.actions;

export const fetchHrOrderDelivery = (data) => async (dispatch) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "GET",
			headers: headers,
		};
		dispatch(requestHrOrderDelivery());
		let responseStatus = 0;
		const queryString = "?id=" + data.orderNumber + "&numempleado=" + data.employeeNumber;
		const methodUrl = "/api/Manager/PedidosRH/Entrega" + queryString;
		return fetch(BASE_API_URL + methodUrl, options).then((response) => {
			responseStatus = response.status;
			return response.ok
				? response.json().then((json) => {
						dispatch(receiveHrOrderDelivery({ order: json }));
						dispatch(setHrOrderDeliveryErrorMsg({ errorMsg: null }));
				  })
				: response
						.text()
						.then((error) => Promise.reject(error))
						.catch((error) => {
							// TODO: fetch error actions
							// If 401 Unauthorized login failed
							if (responseStatus === 401) {
								dispatch(runUnauthorizedActions());
							}
							// If 404 Not Found
							if (responseStatus === 404) {
								dispatch(
									setHrOrderDeliveryErrorMsg({
										errorMsg: {
											search:
												"No se pudo encotrar el pedido o no está disponible para entrega.",
										},
									})
								);
							}
							// If 400 Bad Request
							if (responseStatus === 400) {
								const json = JSON.parse(error);
								dispatch(
									setHrOrderDeliveryErrorMsg({
										errorMsg: { employee: json.Message },
									})
								);
							}
							dispatch(receiveHrOrderDelivery({ order: null }));
						});
		});
	});
};

export const fetchHrOrderDeliveryDownload = (data) => async (dispatch, getState) => {
	sessionService.loadSession().then((session) => {
		const headers = {
			Authorization: "bearer " + session.loginData.access_token,
			"Content-Type": "application/json",
		};
		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				Pedido: data.Pedido,
				NumEmpleado: data.NumEmpleado.substring(1),
			}),
		};
		dispatch(requestHrOrderDelivery());
		let responseStatus = 0;
		const methodUrl = "/api/Manager/PedidosRH/Entrega";
		return fetch(BASE_API_URL + methodUrl, options)
			.then((response) => {
				responseStatus = response.status;
				if (response.ok) {
					return response.text();
				} else {
					return Promise.reject(
						"No se pudo registrar la entrega, verifique que los datos sean correctos."
					);
				}
			})
			.then((text) => {
				const link = document.createElement("a");
				const url =
					"data:application/octec-stream;charset=utf-8;base64," + text;
				link.href = url;
				link.download = "EntregaPedidoContiShop-" + data.Pedido + ".pdf";
				link.click();
				dispatch(receiveHrOrderDeliveryDownload());
				dispatch(clearHrOrderDelivery());
				dispatch(
					setHrOrderDeliverySuccess({ success: { order: data.Pedido } })
				);
			})
			.catch((error) => {
				// TODO: fetch error actions
				// If 401 Unauthorized login failed
				if (responseStatus === 401) {
					dispatch(runUnauthorizedActions());
				}
				// If 400 Bad Request
				if (responseStatus === 400) {
					dispatch(setHrOrderDeliveryErrorMsg({ errorMsg: { error } }));
				}
			});
	});
};

export default hrOrderDelivery.reducer;
