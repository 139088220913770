import { connect } from "react-redux";
import {
	setFromDate,
	setOrderStatus,
	setPayment,
	setShip,
	setUntilDate,
	setUserType,
	clearSalesOrdersFilters,
	fetchSalesOrders,
	setValid,
	setLocationId,
	setPage,
	setStateId,
	setDealerLocationId,
	fetchSalesOrder,
	setContiPartnerId,
} from "features/sales/salesOrdersSlice";
import SalesOrders from "./SalesOrders";
import { fetchAddOrderTab } from "features/tabs/tabsSlice";

const mapStateToProps = (state) => {
	const { tabList, activeTab } = state.tabs;
	const locations = state.locations.list;
	const dealerLocations = state.dealerLocations.list;
	const partnerList = state.contiPartnerList.list;
	const states = state.states.list;
	const {
		list,
		page,
		rows,
		orderStatus,
		valid,
		fromDate,
		untilDate,
		payment,
		ship,
		userType,
		employee,
		locationId,
		contiPartnerId,
		email,
		stateId,
		dealerLocationId,
		isLoading,
	} = state.salesOrders;
	return {
		activeTabItem: tabList != null ? tabList[activeTab] : null,
		list,
		page,
		rows,
		orderStatus,
		valid,
		fromDate,
		untilDate,
		payment,
		ship,
		userType,
		employee,
		locationId,
		email,
		stateId,
		dealerLocationId,
		contiPartnerId,
		isLoading,
		locations,
		dealerLocations,
		states,
		partnerList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOpenOrder: (order) => {
			if (Number(order) > 2000) {
				dispatch(fetchAddOrderTab(order));
				dispatch(fetchSalesOrder(order));
			}
		},
		onFromDate: (fromDate) => {
			dispatch(setFromDate({ fromDate: fromDate.toISOString() }));
			dispatch(fetchSalesOrders());
		},
		onUntilDate: (untilDate) => {
			dispatch(setUntilDate({ untilDate: untilDate.toISOString() }));
			dispatch(fetchSalesOrders());
		},
		onOrderStatus: (orderStatus) => {
			if (orderStatus === 1) {
				dispatch(setValid({ valid: 1 }));
			} else {
				dispatch(setOrderStatus({ orderStatus }));
				dispatch(setValid({ valid: 0 }));
			}
			dispatch(fetchSalesOrders());
		},
		onUserType: (userType) => {
			dispatch(setUserType({ userType }));
			dispatch(fetchSalesOrders());
		},
		onPayment: (payment) => {
			dispatch(setPayment({ payment }));
			dispatch(fetchSalesOrders());
		},
		onShip: (ship) => {
			dispatch(setShip({ ship }));
			dispatch(fetchSalesOrders());
		},
		onLocation: (locationId) => {
			dispatch(setLocationId({ locationId }));
			dispatch(fetchSalesOrders());
		},
		onState: (stateId) => {
			dispatch(setStateId({ stateId }));
			dispatch(fetchSalesOrders());
		},
		onDealerLocation: (dealerLocationId) => {
			dispatch(setDealerLocationId({ dealerLocationId }));
			dispatch(fetchSalesOrders());
		},
		onContiPartner: (contiPartnerId) => {
			dispatch(setContiPartnerId({contiPartnerId}));
			dispatch(fetchSalesOrders());
		},
		onClearFilters: () => {
			dispatch(clearSalesOrdersFilters());
			dispatch(fetchSalesOrders());
		},
		onPage: (page) => {
			dispatch(setPage({ page }));
			dispatch(fetchSalesOrders());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrders);
