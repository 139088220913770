import React from "react";
import { format, parseISO } from "date-fns";
import { currencyFormat } from "features/functions";

export default class SalesOrdersItem extends React.Component {
	handleClick = () => {
		const { item } = this.props;
		this.props.onClick(item.Pedido);
	};
	render = () => {
		const { item } = this.props;
		return (
			<tr onClick={this.handleClick}>
				<td>{item.Pedido}</td>
				<td>{item.Unidades}</td>
				<td>{format(parseISO(item.Fecha), "dd/MMM/yyyy hh:mm aaaa")}</td>
				<td>{item.Estatus}</td>
				<td>{item.PedidoSap}</td>
				<td>{item.Delivery}</td>
				<td>{item.FechaDelivery != null ? format(parseISO(item.FechaDelivery), "dd/MMM/yyyy"): ""}</td>
				<td>{item.ArriboSucursal != null ? format(parseISO(item.ArriboSucursal), "dd/MMM/yyyy"): ""}</td>
				<td>{item.EntregaSucursal != null ? format(parseISO(item.EntregaSucursal), "dd/MMM/yyyy"): ""}</td>
				<td>{currencyFormat(item.Importe)}</td>
				<td>{currencyFormat(item.Impuestos)}</td>
				<td>{currencyFormat(item.Envio)}</td>
				<td>{currencyFormat(item.Total)}</td>
				<td>{item.MetodoPago}</td>
				<td>{item.MetodoEnvio}</td>
				<td>{item.DireccionEnvio.Nombre}</td>
				<td>{item.Cliente.TipoUsuario}</td>
				<td>{item.Cliente.NombreCompleto}</td>
				<td>{item.Cliente.Correo}</td>
				<td>{item.Cliente.NumEmpleado}</td>
				<td>{item.DireccionEnvio.Ciudad}</td>
				<td>{item.DireccionEnvio.Estado}</td>
				<td>{item.Localidad}</td>
				<td>{item.ContiPartner}</td>
			</tr>
		);
	};
}
