import { connect } from 'react-redux'
import {setErrorMessage, 
	fetchLogin,
	setLoginFormReset
} from '../../features/login/loginSlice'
import LoginForm from './LoginForm'

const mapStateToProps = state => {
    const { 
        isLoading,
        errorMsg,
        resetForm,
    } = state.login
    const { authenticated } = state.session
    return {
        isLoading,
        errorMsg,
        resetForm,
        authenticated,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClick: (data) => {
					dispatch(setLoginFormReset(false))
            dispatch(fetchLogin(data.username, data.password))
        },
        onSetError: (errorMsg) => {
            dispatch(setErrorMessage({errorMsg}))
        },
        onClearError: () => {
            dispatch(setErrorMessage({errorMsg: null}))
				},
				onLoad: () => {
					dispatch(setLoginFormReset({resetForm: false}))
				}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm)