import React, { Component } from "react";
import { format, parseISO } from "date-fns";
import {
	CONTACT_MESSAGE_TYPE as cmt,
	CONTACT_MESSAGE_CATEGORY as cmc,
	CONTACT_MESSAGE_STATUS as cms,
} from "features/constants";

class SupportMessageItem extends Component {
	handleClick = () => {
		const { item, onClick } = this.props;
		onClick(item.id);
	};
	render() {
		const { item } = this.props;
		return (
			<tr onClick={this.handleClick}>
				<td>{format(parseISO(item.date), "dd/MMM/yyyy hh:mm aaaa")}</td>
				<td>{item.name}</td>
				<td>{item.email}</td>
				<td className="left">
					{item.message.substring(0, 60)}
					{item.message.length > 60 ? "..." : ""}
				</td>
				<td>{cmt.properties[item.type].name}</td>
				<td>{cmc.properties[item.category].name}</td>
				<td>{cms.properties[item.status].name}</td>
				<td>
					{format(parseISO(item.updatedAt), "dd/MMM/yyyy hh:mm aaaa")}
				</td>
				<td>
					{item.closeDate != null
						? format(parseISO(item.closeDate), "dd/MMM/yyyy hh:mm aaaa")
						: ""}
				</td>
				<td>{item.closedBy ?? ""}</td>
			</tr>
		);
	}
}

export default SupportMessageItem;
